import React from "react";
import axios from "axios";
import { Redirect } from "react-router-dom";
// import logo from "./logo.svg";

import GlobalStyle from "../../../../styles/global";
import Header from "../../../../components/Header";
import Loading from "../../../../components/Loading";
import Nav from "../../components/NavAdd";

import Snackbars from "../../../../components/Snackbars";
import AddAlert from "@material-ui/icons/AddAlert";

import { Container } from "./styles";

class Module extends React.Component {
  INITIAL_STATE = {
    name: "",
    cnpj: "",
  };

  constructor(props) {
    super(props);

    this.state = {
      ...this.INITIAL_STATE,
      loading: false,
      redirect: null,
      snack: {
        open: false,
        message: "",
        color: "success",
      },
    };
  }

  send() {
    this.setState({
      loading: true,
      open: false,
    });
    axios
      .post(`${process.env.REACT_APP_API_URL}/companies`, this.state)
      .then((resp) => {
        // console.log(resp);
        this.setState({
          ...this.INITIAL_STATE,
          redirect: `/companies/${resp.data.id}/settings`,
          loading: false,
          user: resp.data,
          snack: {
            ...this.state.snack,
            open: true,
            message: "Empresa cadastrada com sucesso.",
            color: "success",
          },
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          loading: false,
          snack: {
            ...this.state.snack,
            open: true,
            message:
              err.response &&
              err.response.data &&
              err.response.data.message &&
              err.response.data.message !== ""
                ? err.response.data.message
                : "Erro inesperado",
            color: "danger",
          },
        });
      });
    // console.log(this.state);
  }

  renderForm() {
    return (
      <form>
        <label>Nome</label>
        <input
          autoFocus={true}
          className="form-control"
          value={this.state.name}
          onChange={(event) => this.setState({ name: event.target.value })}
          placeholder="Nome"
          type="text"
        />
        <label>CNPJ</label>
        <input
          className="form-control"
          value={this.state.cnpj}
          onChange={(event) => this.setState({ cnpj: event.target.value })}
          placeholder="CNPJ"
          type="text"
        />
        <button
          onClick={this.send.bind(this)}
          type="button"
          className="btn btn-primary btn-sm"
        >
          Salvar
        </button>
      </form>
    );
  }

  render() {
    if (this.state.redirect && this.state.redirect !== "") {
      return <Redirect to={this.state.redirect} />;
    }
    return (
      <>
        <Header />
        <Nav />
        <Container>
          <Snackbars
            place="tr"
            color={this.state.snack.color}
            icon={AddAlert}
            message={this.state.snack.message}
            open={this.state.snack.open}
            closeNotification={() =>
              this.setState({ snack: { ...this.state.snack, open: false } })
            }
            close
          />
          {this.state.loading ? <Loading /> : this.renderForm()}
        </Container>
        <GlobalStyle />
      </>
    );
  }
}

export default Module;
