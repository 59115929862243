import React from "react";
import { Link } from "react-router-dom";

import { Container } from "./styles";

function Header(props) {
  // console.log(props);
  return (
    <Container>
      <ul className="nav nav-tabs">
        {/* <li className="nav-item">
          <Link
            to={`/companies/${props.company.id}/overview`}
            className={
              props.path === "" || props.path === "overview"
                ? "nav-link active"
                : "nav-link"
            }
          >
            Visão geral
          </Link>
        </li> */}
        {/* <li className="nav-item">
          <Link
            to={`/companies/${props.company.id}/role-categories`}
            className={
              props.path === "role-categories" ? "nav-link active" : "nav-link"
            }
          >
            Funções
          </Link>
        </li> */}
        {/* <li className="nav-item">
          <Link
            to={`/companies/${props.company.id}/roles`}
            className={props.path === "roles" ? "nav-link active" : "nav-link"}
          >
            Cargos
          </Link>
        </li> */}
        {/* <li className="nav-item">
          <Link
            to={`/companies/${props.company.id}/departments`}
            className={
              props.path === "departments" ? "nav-link active" : "nav-link"
            }
          >
            Departamentos
          </Link>
        </li> */}
        {/* <li className="nav-item">
          <Link
            to={`/companies/${props.company.id}/staffs`}
            className={props.path === "staffs" ? "nav-link active" : "nav-link"}
          >
            Colaboradores
          </Link>
        </li> */}
        <li className="nav-item">
          <Link
            to={`/account`}
            className={
              props.path === undefined || props.path === "" || props.path === "account"
                ? "nav-link active"
                : "nav-link"
            }
          >
            Dados pessoais
          </Link>
        </li>
        <li className="nav-item">
          <Link
            to={`/account/bank-accounts`}
            className={props.path === "bank-accounts" ? "nav-link active" : "nav-link"}
          >
            Contas bancárias
          </Link>
        </li>
        {/* <li className="nav-item">
          <Link
            to={`/companies/${props.company.id}/access`}
            className={props.path === "access" ? "nav-link active" : "nav-link"}
          >
            Acessos
          </Link>
        </li> */}
        {/* <li className="nav-item">
          <Link
            to={`/companies/${props.company.id}/settings`}
            className={
              props.path === "settings" ||
              props.path === "role-categories" ||
              props.path === "roles" ||
              props.path === "departments" ||
              props.path === "cost-centers" ||
              props.path === "staffs"
                ? "nav-link active"
                : "nav-link"
            }
          >
            Configurações
          </Link>
        </li> */}
      </ul>
    </Container>
  );
}

export default Header;
