import React from "react";
import { withRouter } from "react-router-dom";
// import axios from "axios";
// import logo from "./logo.svg";

import GlobalStyle from "../../styles/global";
import Header from "../../components/Header";
import Nav from "./components/NavDetails";
import Menu from "./components/Menu";

import Snackbars from "../../components/Snackbars";
import AddAlert from "@material-ui/icons/AddAlert";

import Account from "./pages/Account";
import BankAccount from "./pages/BankAccounts";

class Module extends React.Component {
  constructor(props) {
    super(props);
    const dataUser = JSON.parse(localStorage.getItem(process.env.REACT_APP_USER_KEY));

    this.state = {
      user: dataUser.user,
      loading: false,
      snack: {
        open: false,
        message: "",
        color: "success",
      },
    };

    // console.log(props);
  }

  setSnack(snack) {
    this.setState({ snack });
  }

  componentDidMount() {
    // this.getCompany();
  }

  // getCompany() {
  //   const that = this;
  //   that.setState({
  //     loading: true,
  //   });
  //   axios
  //     .get(`${process.env.REACT_APP_API_URL}/companies/${that.state.id}`)
  //     .then((resp) => {
  //       // console.log(resp);
  //       that.setState({
  //         loading: false,
  //         company: resp.data,
  //       });
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //       that.setState({
  //         loading: false,
  //         snack: {
  //           ...that.state.snack,
  //           open: true,
  //           message:
  //             err.response &&
  //             err.response.data &&
  //             err.response.data.message &&
  //             err.response.data.message !== ""
  //               ? err.response.data.message
  //               : "Erro inesperado",
  //           color: "danger",
  //         },
  //       });
  //     });
  // }

  renderContent() {
    // if (!this.state.company || !this.state.company.name || this.state.company.name === "") {
    //   return null;
    // }
    /*
    import CompanyOverview from "../modules/companies/pages/Overview";
    import CompanyRoleCategories from "../modules/companies/pages/RoleCategories";
    import CompanyRoles from "../modules/companies/pages/Roles";
    import CompanyDepartments from "../modules/companies/pages/Departments";
    import CompanyStaffs from "../modules/companies/pages/Staffs";
    import CompanyAccess from "../modules/companies/pages/Access";
    import CompanyResources from "../modules/companies/pages/Resources";
    import CompanySettings from "../modules/companies/pages/Settings";
    */
    const page = this.props.match.params.page;
    const props = {
      user: this.state.user,
      // getCompany: this.getCompany.bind(this),
      setSnack: this.setSnack.bind(this),
    };
    return (
      <>
        <Menu path={page} />
        {page === undefined || page === "" ? <Account {...props} /> : null}
        {page === "bank-accounts" ? <BankAccount {...props} /> : null}
      </>
    );
  }

  render() {
    return (
      <>
        <Snackbars
          place="tr"
          color={this.state.snack.color}
          icon={AddAlert}
          message={this.state.snack.message}
          open={this.state.snack.open}
          closeNotification={() =>
            this.setState({
              snack: { ...this.state.snack, open: false },
            })
          }
          close
        />
        <Header />
        <Nav loading={this.state.loading} />
        {this.state.loading === false ? this.renderContent() : null}
        <GlobalStyle />
      </>
    );
  }
}

export default withRouter(Module);
