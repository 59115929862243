import styled from "styled-components";
// import { Colors } from "../../../../themes";

export const Container = styled.div`
  /* padding: 20px; */
  width: 100%;
  max-width: 35rem;
  margin: auto;
  padding-top: 40px;
`;
