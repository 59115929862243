import styled from "styled-components";
import { Colors } from "../../themes";

export const Container = styled.div`
  height: 50px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 49;
  background: white;
  display: -webkit-flex;
  padding: 0 15px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);

  display: flex;
  align-items: center;
  justify-content: space-between;
  &::after {
    background: linear-gradient(
      to right,
      ${Colors.greenDark},
      ${Colors.greenLight},
      ${Colors.pinkDark},
      ${Colors.pinkLight},
      ${Colors.yellowDark},
      ${Colors.yellowLight},
      ${Colors.blueDark},
      ${Colors.blueLight}
    );
    content: "";
    width: 100%;
    position: absolute;
    top: 50px;
    height: 3px;
    left: 0;
  }
  h1 {
    color: ${Colors.dark};
    width: 350px;
  }
`;

export const User = styled.div`
  display: flex;
  width: 50px;
  /* align-content: flex-end; */
  justify-content: flex-end;
`;

export const Avatar = styled.img`
  position: relative;
  width: 30px;
  height: 30px;
  z-index: 2;
  vertical-align: middle;
  border: 0;
  max-width: 100%;
  cursor: pointer;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  overflow: hidden;
  margin-right: 5px;
`;

export const DropdownHeader = styled.div`
  height: auto;
  display: block;
  line-height: normal;
  text-align: center;
  color: #3f3f44;
  padding: 20px 10px 20px;
  span {
    text-decoration: none;
    font-size: 13px;
    display: block;
    color: #96a3b6;
    width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
`;

export const BigAvatar = styled.img`
  position: relative;
  width: 48px;
  height: 48px;
  z-index: 2;
  vertical-align: middle;
  border: 0;
  max-width: 100%;
  cursor: pointer;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  overflow: hidden;
  margin-bottom: 10px;
`;
