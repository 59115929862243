import React from "react";
// import logo from "./logo.svg";
import App from "./App";
import vales from "../../../../assets/img/apps/vales.png";
// import reembolso from "../../../../assets/img/apps/reembolso.png";

function Module(props) {
  // console.log(props);
  return (
    <div className="container-fluid">
      <div className="row">
        <App company={props.company} image={vales} title="Vales e reembolsos" path="vales" />
      </div>
    </div>
  );
}

export default Module;
