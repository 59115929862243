import styled from "styled-components";
import { Colors } from "../../../../themes";

export const Container = styled.div``;

export const Styles = styled.div`
  /* padding: 1rem;
  margin: 0 20px;
  table {
    border-spacing: 0;
    border: 1px solid black;
    width: 100%;
    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }
    th,
    td {
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid black;
      border-right: 1px solid black;
      :last-child {
        border-right: 0;
      }
    }
  }
  .pagination {
    padding: 0.5rem;
  } */
`;

export const Th = styled.th`
  border: none !important;
  border-bottom: 2px solid ${Colors.primaryMedium} !important;
  /* width: 100%; */
  padding-left: 30px !important;
  &.actions {
    width: 300px !important;
    /* display: flex;
    flex-direction: column;
    align-items: flex-end;
    border: none !important;
    */
    padding-right: 30px !important;
    text-align: right;
  }
`;

export const Td = styled.td`
  /* width: 100%; */
  border: none !important;
  border-bottom: 1px solid ${Colors.primaryMedium} !important;
  padding-left: 30px !important;
  vertical-align: middle;
  line-height: 42px;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  &.actions {
    width: 300px !important;
    text-align: right;
    padding-right: 30px !important;
  }
`;
