import React from "react";
import { Link } from "react-router-dom";

function index(props) {
  return (
    <div
      className="nav flex-column nav-pills"
      id="v-pills-tab"
      aria-orientation="vertical"
    >
      <Link
        to={`/companies/${props.company.id}/settings`}
        className={props.path === "settings" ? "nav-link active" : "nav-link"}
      >
        Geral
      </Link>
      <Link
        to={`/companies/${props.company.id}/role-categories`}
        className={
          props.path === "role-categories" ? "nav-link active" : "nav-link"
        }
      >
        Funções
      </Link>
      <Link
        to={`/companies/${props.company.id}/roles`}
        className={props.path === "roles" ? "nav-link active" : "nav-link"}
      >
        Cargos
      </Link>
      <Link
        to={`/companies/${props.company.id}/departments`}
        className={
          props.path === "departments" ? "nav-link active" : "nav-link"
        }
      >
        Departamentos
      </Link>
      <Link
        to={`/companies/${props.company.id}/cost-centers`}
        className={
          props.path === "cost-centers" ? "nav-link active" : "nav-link"
        }
      >
        Centros de custo
      </Link>
      <Link
        to={`/companies/${props.company.id}/staffs`}
        className={props.path === "staffs" ? "nav-link active" : "nav-link"}
      >
        Colaboradores
      </Link>
    </div>
  );
}

export default index;
