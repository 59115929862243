import React from "react";

import { Link } from "react-router-dom";

import Nav from "../../../../components/Nav";
import Loading from "../../../../components/Loading";
import {
  FaUserCircle,
  // FaAngleRight
} from "react-icons/fa";
import { BsChevronRight } from "react-icons/bs";
import { FcOk, FcInfo, FcHighPriority } from "react-icons/fc";

import {
  Container,
  HCenter,
  Left,
  IconContainer,
  LoadingContainer,
  LeftText,
} from "./styles";

function Header(props) {
  // console.log(props);
  return (
    <Nav>
      {props.loading ? (
        <LoadingContainer>
          <Loading />
        </LoadingContainer>
      ) : (
        <>
          <Container>
            <Link to="/companies">
              <FaUserCircle />
              <span>Pessoal</span>
            </Link>
            <HCenter>
              <BsChevronRight />
              <Left>
                <Link to={`/companies/${props.company.id}`}>
                  <IconContainer>
                    {props.company.frozen === true ? (
                      <FcHighPriority />
                    ) : props.company.maintenance === true ? (
                      <FcInfo />
                    ) : (
                      <FcOk />
                    )}
                  </IconContainer>
                  {props.company.name}
                </Link>
              </Left>
            </HCenter>
            <HCenter>
              <BsChevronRight />
              <LeftText>Vales</LeftText>
            </HCenter>
          </Container>
        </>
      )}
    </Nav>
  );
}

export default Header;
