import styled from "styled-components";

export const Container = styled.div`
  border-bottom: #d6d6d6 1px solid;
  padding: 10px;
  * {
    text-decoration: none;
    color: #000 !important;
    &:hover {
      text-decoration: none;
      color: #000 !important;
    }
  }
`;

export const Icon = styled.img`
  width: 100px;
  height: 100px;
  border-radius: 20px;
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  h3 {
    font-size: 20px;
  }
`;
