import React from "react";
import { Link } from "react-router-dom";
import axios from "axios";

import { Container } from "./styles";

class Header extends React.Component {
  constructor(props) {
    super(props);
    const userData = JSON.parse(localStorage.getItem(process.env.REACT_APP_USER_KEY));
    const { user } = userData;
    // console.log(user);
    // console.log(props);
    this.state = {
      approvals: [],
      user,
      owner:
        this.props &&
        this.props.company &&
        this.props.company.Owner &&
        this.props.company.owner_id === user.id
          ? true
          : false,
      admin: false,
    };
  }

  componentDidMount() {
    this.getAdmmins();
  }

  getAdmmins() {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/company/${this.props.company.id}/app/vales/users/${this.state.user.id}`
      )
      .then((resp) =>
        this.setState({
          admin: true,
        })
      );
  }

  render() {
    // console.log(this.state);
    return (
      <Container>
        <ul className="nav nav-tabs">
          <li className="nav-item">
            <Link
              to={`${this.props.base_url}/my-requests`}
              className={
                this.props.path === "my-requests" ||
                this.props.path === "approvals" ||
                this.props.path === "requests" ||
                this.props.path === "scheduled" ||
                this.props.path === "paid" ||
                this.props.path === "done"
                  ? "nav-link active"
                  : "nav-link"
              }
            >
              Requisições
            </Link>
          </li>
          {this.state.owner || this.state.admin ? (
            <li className="nav-item">
              <Link
                to={`${this.props.base_url}/access`}
                className={this.props.path === "access" ? "nav-link active" : "nav-link"}
              >
                Acessos
              </Link>
            </li>
          ) : null}
          {this.state.owner || this.state.admin ? (
            <li className="nav-item">
              <Link
                to={`${this.props.base_url}/jurisdictions`}
                className={
                  this.props.path === "settings" ||
                  this.props.path === "statuses" ||
                  this.props.path === "expense-categories" ||
                  this.props.path === "jurisdictions" ||
                  this.props.path === "departments" ||
                  this.props.path === "staffs"
                    ? "nav-link active"
                    : "nav-link"
                }
              >
                Configurações
              </Link>
            </li>
          ) : null}
        </ul>
      </Container>
    );
  }
}

export default Header;
