import React, { Component } from "react";
import { withRouter, Link, Redirect } from "react-router-dom";
import CreateGlobalStyle, {
  Container,
  Logo,
  Content,
  Panel,
  // Overlay,
} from "../styles";
import axios from "axios";
// import Button from "@material-ui/core/Button";
// import Snackbar from "@material-ui/core/Snackbar";
// import MuiAlert from "@material-ui/lab/Alert";
// import { makeStyles } from "@material-ui/core/styles";

import Snackbars from "../../../../components/SnackbarContent";
import AddAlert from "@material-ui/icons/AddAlert";

// import { FaUser, FaLock } from "react-icons/fa";
// import { MdPhoneIphone } from "react-icons/md";
import { AiOutlineMail, AiOutlineLock } from "react-icons/ai";

import logo from "../../../../assets/img/logo.png";

class index extends Component {
  constructor(props) {
    super(props);
    // console.log("props: ", this.props);

    // const pathname = "/company/6/app/vales/requests";
    // console.log(btoa(pathname));

    const dataUser = JSON.parse(
      localStorage.getItem(process.env.REACT_APP_USER_KEY)
    );

    this.state = {
      email: "",
      password: "",
      loading: false,
      snack: {
        open: false,
        message: "",
        color: "success",
      },
      user: dataUser ? dataUser.user : null,
    };
  }

  login() {
    const that = this;
    this.setState({ loading: true });
    axios
      .post(`${process.env.REACT_APP_API_URL}/auth`, this.state)
      .then((resp) => {
        // var user = resp.data;

        // var token = `Bearer ${resp.data.token}`;
        // // console.log(token);
        // axios.defaults.headers.common["Accept"] = "application/json";
        // axios.defaults.headers.common["Content-Type"] = "application/json";
        // axios.defaults.headers.common["Authorization"] = token;
        let user = { ...resp.data };
        // delete user.token;

        var newData = { user, token: resp.data.token };
        // var newData = { ...resp.data, user: resp.data };

        // console.log(newData);
        localStorage.setItem(
          process.env.REACT_APP_USER_KEY,
          JSON.stringify(newData)
        );
        that.setState({
          loading: false,
          email: "",
          password: "",
          user: resp.data,
          //   snack: {
          //     ...this.state.snack,
          //     open: true,
          //     message:
          //       response.data &&
          //       response.data.message &&
          //       response.data.message !== ""
          //         ? response.data.message
          //         : "Erro inesperado",
          //     color: "success",
          //   },
        });

        // console.log(response);
      })
      .catch((err) => {
        this.setState({
          loading: false,
          snack: {
            ...this.state.snack,
            open: true,
            message:
              err.response &&
              err.response.data &&
              err.response.data.message &&
              err.response.data.message !== ""
                ? err.response.data.message
                : "Erro inesperado",
            color: "danger",
          },
        });
        // console.log(err.response);
      });
  }

  renderForm() {
    return (
      <form>
        <div className="form-group">
          <label>E-mail</label>
          <div className="input-icon icon-email">
            <AiOutlineMail />
          </div>
          <input
            autoFocus={true}
            className="form-control"
            id="email"
            name="email"
            value={this.state.email}
            onChange={(event) => this.setState({ email: event.target.value })}
            placeholder="E-mail"
            type="email"
          />
        </div>
        <div className="form-group">
          <label>Senha</label>
          <div className="input-icon icon-password">
            <AiOutlineLock />
          </div>
          <input
            value={this.state.password}
            onChange={(event) =>
              this.setState({ password: event.target.value })
            }
            autoComplete="off"
            className="form-control password"
            id="password"
            name="password"
            placeholder="Senha"
            type="password"
          />
        </div>
        {this.state.loading ? (
          <button
            className="btn btn-primary btn-lg btn-block"
            type="button"
            value="Log In"
            onClick={() => {}}
            disabled={true}
          >
            <div
              className="spinner-border spinner-border-sm text-light"
              role="status"
            >
              <span className="sr-only">Carregando...</span>
            </div>{" "}
            Entrar
          </button>
        ) : (
          <a
            className="btn btn-primary btn-lg btn-block"
            onClick={this.login.bind(this)}
            href="#this"
          >
            Entrar
          </a>
        )}
      </form>
    );
  }

  render() {
    // console.log(this.state);
    // console.log(this.props);
    if (this.state.user && this.state.user.token) {
      let pathname = "/";
      if (
        this.props.match &&
        this.props.match.params &&
        this.props.match.params.path &&
        this.props.match.params.path !== ""
      ) {
        pathname = atob(this.props.match.params.path);
      }
      return (
        <Redirect
          to={{
            pathname,
          }}
        />
      );
    }
    return (
      <>
        {/* <Overlay /> */}
        <Container>
          {/* <Alert severity="error">This is an error message!</Alert>
          <Alert severity="warning">This is a warning message!</Alert>
          <Alert severity="info">This is an information message!</Alert>
          <Alert severity="success">This is a success message!</Alert> */}
          <Snackbars
            place="tr"
            color={this.state.snack.color}
            icon={AddAlert}
            message={this.state.snack.message}
            open={this.state.snack.open}
            closeNotification={() =>
              this.setState({ snack: { ...this.state.snack, open: false } })
            }
            close
          />

          <Logo>
            <a href="http://hubera.com.br">
              <img src={logo} alt="Hubera" />
            </a>
          </Logo>
          <Content>
            <Panel>
              <h2>Logar na sua conta</h2>
              {this.renderForm()}
              <div className="panel-footer">
                Novo no Hubera? &nbsp;
                <Link to="/signup">
                  <span>Cadastrar</span>
                </Link>
              </div>
            </Panel>
            <Link className="link-external" to="/recovery">
              Esqueceu a sua senha?
            </Link>
          </Content>
        </Container>
        <CreateGlobalStyle />
      </>
    );
  }
}

export default withRouter(index);
