import React from "react";
import axios from "axios";

import { Styles, Th } from "./styles";
import List from "./List";
import Expenses from "./Expenses";
import Loading from "../../../../components/Loading";
import Submenu from "../../components/RequestsSubmenu";

class Module extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      data: [],
      model: {
        id: null,
        justification: "",
      },
    };
  }

  componentDidMount() {
    this.get();
  }

  approve(item) {
    // console.log("approve: ", this.state);
    // return false;
    const that = this;
    const data = {
      ...item,
      approve: true,
    };
    that.setState({
      loading: true,
    });
    axios
      .put(
        `${process.env.REACT_APP_API_URL}/company/${that.props.company.id}/app/vales/requests/${data.id}/decision`,
        data
      )
      .then((resp) => {
        // console.log(resp);
        that.get();
        const snack = {
          open: true,
          message: "Vale aprovado com sucesso",
          color: "success",
        };
        that.props.setSnack(snack);
        that.setState({
          loading: false,
          model: {
            id: null,
            justification: "",
            showExpenses: false,
          },
        });
      })
      .catch((err) => {
        console.log(err);
        // loading: false,
        const snack = {
          ...that.state.snack,
          open: true,
          message:
            err.response &&
            err.response.data &&
            err.response.data.message &&
            err.response.data.message !== ""
              ? err.response.data.message
              : "Erro inesperado",
          color: "danger",
        };
        that.props.setSnack(snack);
        that.setState({
          loading: false,
          model: {
            id: null,
            justification: "",
          },
        });
      });
  }

  reject(item) {
    // console.log("reject: ", item);
    const { id } = item;
    this.setState({
      model: {
        ...this.state.model,
        id,
      },
    });
  }

  confirmReject(item) {
    // console.log("confirmReject: ", this.state);
    // console.log("confirmReject: ", item);
    // return false;
    const that = this;
    let data = {
      ...this.state.model,
      approve: false,
    };

    if (item && item.id) {
      data = {
        ...item,
        approve: false,
      };
    }
    that.setState({
      loading: true,
    });
    axios
      .put(
        `${process.env.REACT_APP_API_URL}/company/${that.props.company.id}/app/vales/requests/${data.id}/decision`,
        data
      )
      .then((resp) => {
        // console.log(resp);
        that.get();
        const snack = {
          open: true,
          message: "Vale rejeitado com sucesso",
          color: "success",
        };
        that.props.setSnack(snack);
        that.setState({
          loading: false,
          showExpenses: false,
          model: {
            id: null,
            justification: "",
          },
        });
      })
      .catch((err) => {
        console.log(err);
        // loading: false,
        const snack = {
          ...that.state.snack,
          open: true,
          message:
            err.response &&
            err.response.data &&
            err.response.data.message &&
            err.response.data.message !== ""
              ? err.response.data.message
              : "Erro inesperado",
          color: "danger",
        };
        that.props.setSnack(snack);
        that.setState({
          loading: false,
          model: {
            id: null,
            justification: "",
          },
        });
      });
  }

  get() {
    const that = this;
    that.setState({
      loading: true,
    });
    // let { page, qtd_per_page, sorted, order, filtered } = that.state;
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/company/${that.props.company.id}/app/vales/approve-requests`
      )
      .then((resp) => {
        // console.log(resp);
        that.setState({
          loading: false,
          data: resp.data.result,
        });
      })
      .catch((err) => {
        console.log(err);
        // loading: false,
        const snack = {
          ...that.state.snack,
          open: true,
          message:
            err.response &&
            err.response.data &&
            err.response.data.message &&
            err.response.data.message !== ""
              ? err.response.data.message
              : "Erro inesperado",
          color: "danger",
        };
        that.props.setSnack(snack);
      });
  }

  showExpenseModal(model) {
    // console.log("showExpenseModal", model);
    this.setState({
      model,
      showExpenses: true,
    });
  }

  renderTable() {
    return (
      <>
        {this.state.loading ? (
          <Loading />
        ) : this.state.data.length === 0 ? (
          <table className="table table-hover">
            <thead>
              <tr>
                <Th scope="col">Nenhum registro encontrado</Th>
                <Th className="actions" scope="col"></Th>
              </tr>
            </thead>
          </table>
        ) : (
          <div className="row">
            <List
              data={this.state.data}
              approve={this.approve.bind(this)}
              reject={this.reject.bind(this)}
              showExpenseModal={this.showExpenseModal.bind(this)}
            />
          </div>
        )}
      </>
    );
  }

  renderForm() {
    return (
      <form>
        <div className="form-group">
          <label className="col-form-label">Justificativa:</label>
          <textarea
            value={this.state.model.justification}
            rows={4}
            onChange={(event) =>
              this.setState({
                model: {
                  ...this.state.model,
                  justification: event.target.value,
                },
              })
            }
            type="text"
            className="form-control"
            id="recipient-justification"
          />
        </div>
      </form>
    );
  }

  renderModalReject() {
    return (
      <div
        className="modal fade"
        id="staticBackdropReject"
        data-backdrop="static"
        data-keyboard="false"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="staticBackdropLabel">
                {` `}
              </h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              Tem certeza que deseja rejeitar esse vale?
              {this.renderForm()}
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-dismiss="modal">
                Não
              </button>
              <button
                onClick={() => this.confirmReject()}
                type="button"
                className="btn btn-danger"
                data-dismiss="modal"
              >
                Sim
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <>
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-2">
              <Submenu path="approvals" company={this.props.company} />
            </div>
            <div className="col-sm-10">
              {this.state.showExpenses ? (
                <Expenses
                  request={this.state.model}
                  company={this.props.company}
                  setSnack={this.props.setSnack}
                  approve={this.approve.bind(this)}
                  reject={this.reject.bind(this)}
                  confirmReject={this.confirmReject.bind(this)}
                  back={() => {
                    this.setState({ showExpenses: false });
                    this.get();
                  }}
                />
              ) : (
                <>
                  <div>
                    <Styles>{this.renderTable()}</Styles>
                  </div>
                  {this.renderModalReject()}
                </>
              )}
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Module;
