import React from "react";
import { Link } from "react-router-dom";
import axios from "axios";

import { Container } from "./styles";

class Header extends React.Component {
  constructor(props) {
    super(props);
    const userData = JSON.parse(localStorage.getItem(process.env.REACT_APP_USER_KEY));
    const { user } = userData;
    // console.log(user);
    // console.log(props);
    this.state = {
      approvals: [],
      user,
      owner:
        this.props &&
        this.props.company &&
        this.props.company.Owner &&
        this.props.company.owner_id === user.id
          ? true
          : false,
      admin: false,
      page: 1,
      qtd_per_page: 20,
      sorted: "",
      order: "asc",
      filtered: "W10=",
      pages: 0,
    };
  }

  componentDidMount() {
    this.getAdmmins();
  }

  getAdmmins() {
    let { page, qtd_per_page, sorted, order, filtered } = this.state;
    filtered = btoa(JSON.stringify([{ id: "profile", value: "admin" }]));
    // console.log(filtered);
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/company/${this.props.company.id}/staffs?page=${page}&qtd_per_page=${qtd_per_page}&sorted=${sorted}&order=${order}&filtered=${filtered}`
      )
      .then((resp) => {
        // console.log(resp);
        resp.data.result.map((admin) => {
          if (admin.user_id == this.state.user.id) {
            this.setState({
              admin: true,
            });
          }
        });
      });
  }

  render() {
    // console.log(this.state);
    return (
      <Container>
        <ul className="nav nav-tabs">
          <li className="nav-item">
            <Link
              to={`/companies/${this.props.company.id}/apps`}
              className={this.props.path === "apps" ? "nav-link active" : "nav-link"}
            >
              Aplicativos
            </Link>
          </li>
          {this.state.owner || this.state.admin ? (
            <li className="nav-item">
              <Link
                to={`/companies/${this.props.company.id}/access`}
                className={this.props.path === "access" ? "nav-link active" : "nav-link"}
              >
                Acessos
              </Link>
            </li>
          ) : null}
          {this.state.owner || this.state.admin ? (
            <li className="nav-item">
              <Link
                to={`/companies/${this.props.company.id}/settings`}
                className={
                  this.props.path === "settings" ||
                  this.props.path === "role-categories" ||
                  this.props.path === "roles" ||
                  this.props.path === "departments" ||
                  this.props.path === "cost-centers" ||
                  this.props.path === "staffs"
                    ? "nav-link active"
                    : "nav-link"
                }
              >
                Configurações
              </Link>
            </li>
          ) : null}
        </ul>
      </Container>
    );
  }
}

export default Header;
