import React from "react";

import { Link } from "react-router-dom";

import Nav from "../../../../components/Nav";
import Loading from "../../../../components/Loading";
import {
  FaUserCircle,
  // FaAngleRight
} from "react-icons/fa";
// import { BsChevronRight } from "react-icons/bs";
// import { FcOk, FcInfo, FcHighPriority } from "react-icons/fc";

import { Container, LoadingContainer } from "./styles";

function Header(props) {
  // console.log(props);
  return (
    <Nav>
      {props.loading ? (
        <LoadingContainer>
          <Loading />
        </LoadingContainer>
      ) : (
        <>
          <Container>
            <Link to="/account">
              <FaUserCircle />
              <span>Gerenciar conta</span>
            </Link>
          </Container>

          {/* <div className="dropdown">
            <button
              className="btn btn-outline-primary btn-sm dropdown-toggle"
              type="button"
              id="dropdownMoreMenuButton"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              MAIS CONFIGURAÇÕES
            </button>
            <div
              className="dropdown-menu dropdown-menu-right"
              aria-labelledby="dropdownMoreMenuButton"
            >
              <Link
                to={`/companies/${props.company.id}/role-categories`}
                className={
                  props.path === "role-categories"
                    ? "dropdown-item active"
                    : "dropdown-item"
                }
              >
                Funções
              </Link>
              <Link
                to={`/companies/${props.company.id}/roles`}
                className={
                  props.path === "roles"
                    ? "dropdown-item active"
                    : "dropdown-item"
                }
              >
                Cargos
              </Link>
              <Link
                to={`/companies/${props.company.id}/departments`}
                className={
                  props.path === "departments"
                    ? "dropdown-item active"
                    : "dropdown-item"
                }
              >
                Departamentos
              </Link>
              <Link
                to={`/companies/${props.company.id}/staffs`}
                className={
                  props.path === "staffs"
                    ? "dropdown-item active"
                    : "dropdown-item"
                }
              >
                Colaboradores
              </Link>
            </div>
          </div> */}
        </>
      )}
    </Nav>
  );
}

export default Header;
