import React from "react";

import Nav from "../../../../components/Nav";
import { Container } from "./styles";

function Header() {
  return (
    <Nav>
      <Container>Adicionar empresa</Container>
    </Nav>
  );
}

export default Header;
