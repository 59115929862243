import React from "react";
import axios from "axios";

import { Styles, Th } from "./styles";
import List from "./List";
import Loading from "../../../../components/Loading";
import Submenu from "../../components/ConfigSubmenu";

class Module extends React.Component {
  // const data = React.useMemo(() => makeData(115), []);
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      data: [],
      departments: [],
      role_categories: [],
      page: 1,
      qtd_per_page: 20,
      sorted: "",
      order: "asc",
      filtered: "W10=",
      pages: 0,
      model: {
        id: null,
        description: "",
        department_id: "",
        role_category_id: "",
      },
    };
    // console.log(props);
  }

  componentDidMount() {
    this.get();
  }

  save() {
    const that = this;
    const data = this.state.model;
    if (data.id && data.id !== 0) {
      axios
        .put(
          `${process.env.REACT_APP_API_URL}/company/${that.props.company.id}/app/vales/expense-categories/${data.id}`,
          data
        )
        .then((resp) => {
          // console.log(resp);
          that.get();
          const snack = {
            open: true,
            message: "Despesa editada com sucesso",
            color: "success",
          };
          that.props.setSnack(snack);
          that.setState({
            loading: false,
            model: {
              id: null,
              description: "",
              department_id: "",
              role_category_id: "",
            },
          });
        })
        .catch((err) => {
          console.log(err);
          // loading: false,
          const snack = {
            ...that.state.snack,
            open: true,
            message:
              err.response &&
              err.response.data &&
              err.response.data.message &&
              err.response.data.message !== ""
                ? err.response.data.message
                : "Erro inesperado",
            color: "danger",
          };
          that.props.setSnack(snack);
        });
    } else {
      axios
        .post(
          `${process.env.REACT_APP_API_URL}/company/${that.props.company.id}/app/vales/expense-categories`,
          data
        )
        .then((resp) => {
          // console.log(resp);
          that.get();
          const snack = {
            open: true,
            message: "Despesa cadastrada com sucesso",
            color: "success",
          };
          that.props.setSnack(snack);
          that.setState({
            loading: false,
            model: {
              id: null,
              description: "",
              department_id: "",
              role_category_id: "",
            },
          });
        })
        .catch((err) => {
          console.log(err);
          // loading: false,
          const snack = {
            ...that.state.snack,
            open: true,
            message:
              err.response &&
              err.response.data &&
              err.response.data.message &&
              err.response.data.message !== ""
                ? err.response.data.message
                : "Erro inesperado",
            color: "danger",
          };
          that.props.setSnack(snack);
        });
    }
  }

  show(item) {
    const that = this;
    // console.log("show");
    // console.log(item);
    const { id, description, department_id, role_category_id } = item;
    that.setState({
      model: {
        id,
        description,
        department_id,
        role_category_id,
      },
    });
  }

  remove(item) {
    const { id, description } = item;
    this.setState({
      model: {
        id,
        description,
      },
    });
  }

  destroy() {
    const that = this;
    const data = this.state.model;
    if (data.id && data.id !== 0) {
      axios
        .delete(
          `${process.env.REACT_APP_API_URL}/company/${that.props.company.id}/app/vales/expense-categories/${data.id}`
        )
        .then((resp) => {
          // console.log(resp);
          that.get();
          const snack = {
            open: true,
            message: "Despesa removida com sucesso",
            color: "success",
          };
          that.props.setSnack(snack);
          that.setState({
            loading: false,
            model: {
              id: null,
              description: "",
              department_id: "",
              role_category_id: "",
            },
          });
        })
        .catch((err) => {
          console.log(err);
          // loading: false,
          const snack = {
            ...that.state.snack,
            open: true,
            message:
              err.response &&
              err.response.data &&
              err.response.data.message &&
              err.response.data.message !== ""
                ? err.response.data.message
                : "Erro inesperado",
            color: "danger",
          };
          that.props.setSnack(snack);
        });
    }
  }

  get() {
    const that = this;
    that.setState({
      loading: true,
    });
    let { page, qtd_per_page, sorted, order, filtered } = that.state;
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/company/${that.props.company.id}/app/vales/expense-categories?page=${page}&qtd_per_page=${qtd_per_page}&sorted=${sorted}&order=${order}&filtered=${filtered}`
      )
      .then((resp) => {
        // console.log(resp);
        that.setState({
          loading: false,
          data: resp.data.result,
          // qtd_per_page: resp.data.limit,
          // pages: resp.data.pages,
          qtd_per_page: resp.data.result.length,
          pages: 1,
        });
      })
      .catch((err) => {
        console.log(err);
        // loading: false,
        const snack = {
          ...that.state.snack,
          open: true,
          message:
            err.response &&
            err.response.data &&
            err.response.data.message &&
            err.response.data.message !== ""
              ? err.response.data.message
              : "Erro inesperado",
          color: "danger",
        };
        that.props.setSnack(snack);
      });
  }

  nextPage() {
    const that = this;
    let page = that.state.page + 1;
    if (page > this.state.pages) {
      page = this.state.pages;
    }
    if (page !== this.state.page) {
      that.setState({ page }, () => {
        setTimeout(() => that.get(), 10);
      });
    }
  }

  previousPage() {
    const that = this;
    let page = that.state.page - 1;
    if (page < 1) {
      page = 1;
    }
    if (page !== this.state.page) {
      that.setState({ page }, () => {
        setTimeout(() => that.get(), 10);
      });
    }
  }

  renderTable() {
    return (
      <table className="table table-hover">
        <thead>
          <tr>
            {this.state.data.length > 0 ? (
              <>
                <Th scope="col">Descrição</Th>
              </>
            ) : (
              <Th scope="col">Nenhum registro encontrado</Th>
            )}
            <Th className="actions" scope="col">
              <button
                // onClick={() => this.add()}
                className="btn btn-outline-primary btn-sm"
                data-toggle="modal"
                data-target="#staticBackdrop"
              >
                Adicionar despesa
              </button>
            </Th>
          </tr>
        </thead>
        <tbody>
          {this.state.loading ? (
            <tr>
              <td colSpan={2}>
                <Loading />
              </td>
            </tr>
          ) : (
            <List
              data={this.state.data}
              show={this.show.bind(this)}
              remove={this.remove.bind(this)}
            />
          )}
        </tbody>
      </table>
    );
  }

  renderPagination() {
    // console.log(this.state);
    const that = this;
    if (that.state.page === 1 && that.state.page === that.state.pages) {
      return null;
    }
    let pages = [];
    for (let i = 1; i <= this.state.pages; i++) {
      pages.push(i);
    }
    return (
      <div className="container">
        <div className="row">
          <div className="col-sm">
            <button
              onClick={that.previousPage.bind(this)}
              className="btn btn-light btn-md btn-block"
              disabled={that.state.page === 1}
            >
              Anterior
            </button>
          </div>
          <div className="col-sm">
            <div className="form-group">
              <select
                className="form-control"
                value={that.state.page}
                onChange={(event) =>
                  that.setState({ page: event.target.value }, () => {
                    setTimeout(() => that.get(), 10);
                  })
                }
              >
                {pages.map((actualPage) => (
                  <option key={actualPage} value={actualPage}>
                    Página {actualPage}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="col-sm">
            <div className="form-group">
              <select
                className="form-control"
                value={that.state.qtd_per_page}
                onChange={(event) =>
                  that.setState(
                    { qtd_per_page: event.target.value, page: 1 },
                    () => {
                      setTimeout(() => that.get(), 10);
                    }
                  )
                }
              >
                {[5, 10, 20, 30, 40, 50].map((pageSize) => (
                  <option key={pageSize} value={pageSize}>
                    Mostrar {pageSize} linhas
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="col-sm">
            <button
              onClick={that.nextPage.bind(this)}
              className="btn btn-light btn-md btn-block"
              disabled={that.state.page === that.state.pages}
            >
              Próxima
            </button>
          </div>
        </div>
      </div>
    );
  }

  renderForm() {
    return (
      <form>
        <div className="form-group">
          <label className="col-form-label">Descrição:</label>
          <input
            value={this.state.model.description}
            onChange={(event) =>
              this.setState({
                model: {
                  ...this.state.model,
                  description: event.target.value,
                },
              })
            }
            type="text"
            className="form-control"
            id="recipient-description"
          />
        </div>
      </form>
    );
  }

  renderModal() {
    return (
      <div
        className="modal fade"
        id="staticBackdrop"
        data-backdrop="static"
        data-keyboard="false"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="staticBackdropLabel">
                {this.state.model.id && this.state.model.id !== 0
                  ? "Editar despesa"
                  : "Adicionar nova despesa"}
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">{this.renderForm()}</div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                Cancelar
              </button>
              <button
                onClick={() => this.save()}
                type="button"
                className="btn btn-primary"
                data-dismiss="modal"
              >
                Salvar
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderModalRemove() {
    return (
      <div
        className="modal fade"
        id="staticBackdropRemove"
        data-backdrop="static"
        data-keyboard="false"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="staticBackdropLabel">
                {` `}
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              {`Tem certeza que deseja remover (${this.state.model.description})?`}
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                Cancelar
              </button>
              <button
                onClick={() => this.destroy()}
                type="button"
                className="btn btn-danger"
                data-dismiss="modal"
              >
                Confirmar
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <>
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-2">
              <Submenu path="expense-categories" company={this.props.company} />
            </div>
            <div className="col-sm-10">
              <div>
                <Styles>
                  {this.renderTable()}
                  {this.state.data.length > 0 ? this.renderPagination() : null}
                </Styles>
              </div>
              {this.renderModal()}
              {this.renderModalRemove()}
            </div>
          </div>
        </div>
      </>
      // <>
      //   <div>
      //     <Styles>
      //       {this.renderTable()}
      //       {this.state.data.length > 0 ? this.renderPagination() : null}
      //     </Styles>
      //   </div>
      //   {this.renderModal()}
      //   {this.renderModalRemove()}
      // </>
    );
  }
}

export default Module;
