import React, { Component } from "react";
import axios from "axios";
import moment from "moment";
import Datetime from "react-datetime";

import { Th } from "../styles";
import Loading from "../../../../../components/Loading";
import List from "./List";

// import { BsCheck, BsX } from "react-icons/bs";
import { BiArrowBack } from "react-icons/bi";

require("moment/locale/pt-br");
const model = {
  id: null,
  date: "",
  amount: "",
  category_id: "",
  note: "",
  fornecedor: "",
  cpf_cnpj: "",
};

class index extends Component {
  constructor(props) {
    super(props);
    const { request } = props;
    // console.log(request);
    this.state = {
      request,
      loading: true,
      data: [],
      categories: [],
      model,
      amount: request.amount + request.amount_refund,
      accountability_justification: request.accountability_justification
        ? request.accountability_justification
        : "",
      amount_refund: "",
      amount_used: "",
    };
  }

  componentDidMount() {
    this.get();
    this.getCategories();
  }

  get() {
    const that = this;
    that.setState({
      loading: true,
    });
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/company/${that.props.company.id}/app/vales/requests/${that.props.request.id}/expenses`
      )
      .then((resp) => {
        // console.log(resp);

        that.setState({
          loading: false,
          data: resp.data.result,
        });
      })
      .catch((err) => {
        console.log(err);
        // loading: false,
        const snack = {
          ...that.state.snack,
          open: true,
          message:
            err.response &&
            err.response.data &&
            err.response.data.message &&
            err.response.data.message !== ""
              ? err.response.data.message
              : "Erro inesperado",
          color: "danger",
        };
        that.props.setSnack(snack);
      });
  }

  getCategories() {
    const that = this;
    that.setState({
      loading: true,
    });
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/company/${that.props.company.id}/app/vales/expense-categories`
      )
      .then((resp) => {
        // console.log(resp);
        that.setState({
          loading: false,
          categories: resp.data.result,
          // qtd_per_page: resp.data.result.length,
          // pages: 1,
        });
      })
      .catch((err) => {
        console.log(err);
        // loading: false,
        const snack = {
          ...that.state.snack,
          open: true,
          message:
            err.response &&
            err.response.data &&
            err.response.data.message &&
            err.response.data.message !== ""
              ? err.response.data.message
              : "Erro inesperado",
          color: "danger",
        };
        that.props.setSnack(snack);
      });
  }

  save() {
    const that = this;
    let data = this.state.model;
    that.setState({
      loading: true,
    });
    // data.payment_date = this.state.model.payment_date._d;
    // console.log(data);
    if (data.id && data.id !== 0) {
      axios
        .put(
          `${process.env.REACT_APP_API_URL}/company/${that.props.company.id}/app/vales/requests/${that.props.request.id}/expenses/${data.id}`,
          data
        )
        .then((resp) => {
          // console.log(resp);
          that.get();
          const snack = {
            open: true,
            message: "Despesa atualizada com sucesso",
            color: "success",
          };
          that.props.setSnack(snack);
          that.setState({
            loading: false,
            model,
          });
        })
        .catch((err) => {
          console.log(err);
          // loading: false,
          const snack = {
            ...that.state.snack,
            open: true,
            message:
              err.response &&
              err.response.data &&
              err.response.data.message &&
              err.response.data.message !== ""
                ? err.response.data.message
                : "Erro inesperado",
            color: "danger",
          };
          that.props.setSnack(snack);
        });
    } else {
      axios
        .post(
          `${process.env.REACT_APP_API_URL}/company/${that.props.company.id}/app/vales/requests/${that.props.request.id}/expenses`,
          data
        )
        .then((resp) => {
          // console.log(resp);
          that.get();
          const snack = {
            open: true,
            message: "Despesa adicionada com sucesso",
            color: "success",
          };
          that.props.setSnack(snack);
          that.setState({
            loading: false,
            model,
          });
        })
        .catch((err) => {
          console.log(err);
          // loading: false,
          const snack = {
            ...that.state.snack,
            open: true,
            message:
              err.response &&
              err.response.data &&
              err.response.data.message &&
              err.response.data.message !== ""
                ? err.response.data.message
                : "Erro inesperado",
            color: "danger",
          };
          that.props.setSnack(snack);
        });
    }
  }

  destroy() {
    const that = this;
    const data = this.state.model;
    that.setState({
      loading: true,
    });
    if (data.id && data.id !== 0) {
      axios
        .delete(
          `${process.env.REACT_APP_API_URL}/company/${that.props.company.id}/app/vales/requests/${that.props.request.id}/expenses/${data.id}`
        )
        .then((resp) => {
          // console.log(resp);
          that.get();
          const snack = {
            open: true,
            message: "Despesa removido com sucesso",
            color: "success",
          };
          that.props.setSnack(snack);
          that.setState({
            loading: false,
            model,
          });
        })
        .catch((err) => {
          console.log(err);
          // loading: false,
          const snack = {
            ...that.state.snack,
            open: true,
            message:
              err.response &&
              err.response.data &&
              err.response.data.message &&
              err.response.data.message !== ""
                ? err.response.data.message
                : "Erro inesperado",
            color: "danger",
          };
          that.props.setSnack(snack);
        });
    }
  }

  updateFile(file, item) {
    const that = this;
    const data = item;
    that.setState({
      loading: true,
    });
    // console.log(data);
    axios
      .put(
        `${process.env.REACT_APP_API_URL}/company/${that.props.company.id}/app/vales/requests/${that.props.request.id}/expenses/${data.id}/files/${file.id}`,
        {}
      )
      .then((resp) => {
        // console.log(resp);
        that.get();
        const snack = {
          open: true,
          message: "Texto atualizado com sucesso",
          color: "success",
        };
        that.props.setSnack(snack);
        that.setState({
          loading: false,
          model,
        });
      })
      .catch((err) => {
        console.log(err);
        // loading: false,
        const snack = {
          ...that.state.snack,
          open: true,
          message:
            err.response &&
            err.response.data &&
            err.response.data.message &&
            err.response.data.message !== ""
              ? err.response.data.message
              : "Erro inesperado",
          color: "danger",
        };
        that.props.setSnack(snack);
      });
  }

  removeFile(file, item) {
    const that = this;
    const data = item;
    that.setState({
      loading: true,
    });
    // console.log(data);
    axios
      .delete(
        `${process.env.REACT_APP_API_URL}/company/${that.props.company.id}/app/vales/requests/${that.props.request.id}/expenses/${data.id}/files/${file.id}`,
        {}
      )
      .then((resp) => {
        // console.log(resp);
        that.get();
        const snack = {
          open: true,
          message: "Comprovante excluído com sucesso",
          color: "success",
        };
        that.props.setSnack(snack);
        that.setState({
          loading: false,
          model,
        });
      })
      .catch((err) => {
        console.log(err);
        // loading: false,
        const snack = {
          ...that.state.snack,
          open: true,
          message:
            err.response &&
            err.response.data &&
            err.response.data.message &&
            err.response.data.message !== ""
              ? err.response.data.message
              : "Erro inesperado",
          color: "danger",
        };
        that.props.setSnack(snack);
      });
  }

  show(item) {
    // console.log(item);
    let model = {
      ...item,
      date: moment(item.date).format("L"),
    };
    // console.log(model);
    this.setState({
      model,
    });
  }

  imageSelectedHandler(e) {
    e.preventDefault();
    const that = this;
    let data = this.state.model;

    let reader = new FileReader();
    let fileBgApp = e.target.files[0];
    // console.log(e.target.files[0]);

    if (fileBgApp.type !== "image/png" && fileBgApp.type !== "image/jpeg") {
      const snack = {
        open: true,
        message: "Imagem com formato inválido",
        color: "danger",
      };
      this.props.setSnack(snack);
    } else {
      reader.onloadend = () => {
        that.setState({
          loading: true,
        });
        // console.log(reader.result);
        // this.setState({
        //   // fileBgApp: fileBgApp,
        //   app_bgPreviewUrl: reader.result,
        // });
        axios
          .post(
            `${process.env.REACT_APP_API_URL}/company/${that.props.company.id}/app/vales/requests/${that.props.request.id}/expenses/${data.id}/files`,
            { image: reader.result }
          )
          .then((resp) => {
            // console.log(resp);
            that.get();
            const snack = {
              open: true,
              message: "Comprovante adicionado com sucesso",
              color: "success",
            };
            that.props.setSnack(snack);
            that.setState({
              loading: false,
              model,
            });
          })
          .catch((err) => {
            console.log(err);
            // loading: false,
            const snack = {
              ...that.state.snack,
              open: true,
              message:
                err.response &&
                err.response.data &&
                err.response.data.message &&
                err.response.data.message !== ""
                  ? err.response.data.message
                  : "Erro inesperado",
              color: "danger",
            };
            that.props.setSnack(snack);
          });
      };
      reader.readAsDataURL(fileBgApp);
    }
  }

  finalizesAccountability() {
    const that = this;
    const data = this.state.request;
    // console.log(this.state.data);
    if (this.state.data.length === 0) {
      const snack = {
        open: true,
        message: "Favor declarar as despesas",
        color: "danger",
      };
      that.props.setSnack(snack);
      return false;
    }

    let total_despesas = this.state.data
      .map((expense) => expense.amount)
      .reduce((accumulator, currentValue) => {
        return accumulator + currentValue;
      });

    if (this.state.request.amount_used !== total_despesas) {
      const snack = {
        open: true,
        message: "Valor informado difere dos valores declarados nas despesas",
        color: "danger",
      };
      that.props.setSnack(snack);
      return false;
    }
    // console.log("total_despesas: ", total_despesas);

    let todos_fornecedores_declarados = true;
    this.state.data.map((expense) => {
      if (todos_fornecedores_declarados && (!expense.fornecedor || expense.fornecedor === "")) {
        todos_fornecedores_declarados = false;
      }
      return expense;
    });
    if (!todos_fornecedores_declarados) {
      const snack = {
        open: true,
        message: "Favor preecher todos os fornecedores nas despesas declaradas",
        color: "danger",
      };
      that.props.setSnack(snack);
      return false;
    }

    let todos_cnpj_cpf_declarados = true;
    this.state.data.map((expense) => {
      if (todos_cnpj_cpf_declarados && (!expense.cpf_cnpj || expense.cpf_cnpj === "")) {
        todos_cnpj_cpf_declarados = false;
      }
      return expense;
    });
    if (!todos_cnpj_cpf_declarados) {
      const snack = {
        open: true,
        message: "Favor preecher todos os CNPJ/CPF nas despesas declaradas",
        color: "danger",
      };
      that.props.setSnack(snack);
      return false;
    }

    // console.log("finalizesAccountability");
    // return true;
    if (data.id && data.id !== 0) {
      axios
        .put(
          `${process.env.REACT_APP_API_URL}/company/${data.company_id}/app/vales/requests/${data.id}/accountability-completed`
        )
        .then((resp) => {
          // console.log(resp);
          that.get();
          const snack = {
            open: true,
            message: "Prestação de contas finalizda com sucesso",
            color: "success",
          };
          that.props.setSnack(snack);
          that.setState({
            loading: false,
            model,
          });
        })
        .catch((err) => {
          console.log(err);
          // loading: false,
          const snack = {
            ...that.state.snack,
            open: true,
            message:
              err.response &&
              err.response.data &&
              err.response.data.message &&
              err.response.data.message !== ""
                ? err.response.data.message
                : "Erro inesperado",
            color: "danger",
          };
          that.props.setSnack(snack);
        });
    }
  }

  saveAccountability() {
    const that = this;
    const data = this.state.request;
    // console.log(data);
    // return true;
    if (data.id && data.id !== 0) {
      axios
        .put(
          `${process.env.REACT_APP_API_URL}/company/${data.company_id}/app/vales/requests/${data.id}`,
          data
        )
        .then((resp) => {
          // console.log(resp);
          that.get();
          const snack = {
            open: true,
            message: "Prestação de contas atualizada com sucesso",
            color: "success",
          };
          that.props.setSnack(snack);
          that.setState({
            loading: false,
            // model,
          });
        })
        .catch((err) => {
          console.log(err);
          // loading: false,
          const snack = {
            ...that.state.snack,
            open: true,
            message:
              err.response &&
              err.response.data &&
              err.response.data.message &&
              err.response.data.message !== ""
                ? err.response.data.message
                : "Erro inesperado",
            color: "danger",
          };
          that.props.setSnack(snack);
        });
    }
  }

  setAmountUsed(event) {
    // console.log(this.state);
    let value = event.target.value;
    value = value.replace(/\D/g, "");
    value = parseFloat(value / 100);
    // console.log(value);

    const amount_refund = value - parseFloat(this.state.request.amount);
    // console.log(amount_refund);

    this.setState({
      request: {
        ...this.state.request,
        amount_used: value,
        amount_refund,
      },
      // amount_used: value.toFixed(2).toString().split(".").join(","),
      // amount_refund: (amount_refund < 0 ? amount_refund * -1 : amount_refund)
      //   .toFixed(2)
      //   .toString()
      //   .split(".")
      //   .join(","),
    });
  }

  renderAccountabilityForm() {
    // console.log(this.state);
    return (
      <form>
        <div className="form-group">
          <label className="col-form-label">Justificativa:</label>
          <textarea
            className="form-control"
            onChange={(event) =>
              this.setState({
                request: {
                  ...this.state.request,
                  accountability_justification: event.target.value,
                },
              })
            }
            value={this.state.request.accountability_justification}
            rows="3"
            disabled={true}
          >
            {this.state.request.accountability_justification}
          </textarea>
        </div>
        <div className="row">
          <div className="form-group col-md-4">
            <label>Valor</label>
            <input
              className="form-control"
              value={
                this.state.request.amount
                  ? this.state.request.amount.toFixed(2).toString().split(".").join(",")
                  : "0,00"
              }
              disabled={true}
            />
          </div>
          <div className="form-group col-md-4">
            <label>Utilizado</label>
            <input
              className="form-control"
              value={
                this.state.request.amount_used
                  ? this.state.request.amount_used.toFixed(2).toString().split(".").join(",")
                  : ""
              }
              onChange={this.setAmountUsed.bind(this)}
              disabled={true}
            />
          </div>
          <div className="form-group col-md-4">
            <label>{this.state.request.amount_refund < 0 ? "Devolução" : "Reembolso"}</label>
            <input
              className="form-control"
              value={
                this.state.request.amount_refund
                  ? this.state.request.amount_refund.toFixed(2).toString().split(".").join(",")
                  : ""
              }
              disabled={true}
            />
          </div>
          <div className="card-body card-body-actions">
            <div
              className="btn-group"
              style={{
                width: "20%",
              }}
              role="group"
            >
              <button
                onClick={() => this.props.back()}
                type="button"
                className="btn btn-secondary btn-sm"
              >
                <BiArrowBack
                  style={{
                    fontSize: 25,
                  }}
                />
                <br />
                Voltar
              </button>
            </div>
          </div>
        </div>
      </form>
    );
  }

  renderForm() {
    const today = Datetime.moment();
    return (
      <form>
        <div className="form-group">
          <label className="col-form-label">Categoria:</label>
          <select
            className="form-control"
            value={this.state.model.category_id}
            onChange={(event) =>
              this.setState({
                model: {
                  ...this.state.model,
                  category_id: event.target.value,
                },
              })
            }
          >
            <option key={0} value={""}>
              Selecione
            </option>
            {this.state.categories.map((role_category) => (
              <option key={role_category.id} value={role_category.id}>
                {role_category.description}
              </option>
            ))}
          </select>
        </div>
        <div className="form-group">
          <label className="col-form-label">Data:</label>
          <Datetime
            timeFormat={false}
            closeOnSelect={true}
            isValidDate={(current) => {
              return current.isBefore(today);
            }}
            onChange={(event) => {
              // console.log(event);
              // console.log(
              //   event.locale("en").format("L").split("/").join("-")
              // );
              this.setState({
                model: {
                  ...this.state.model,
                  date: event._d,
                },
              });
            }}
            value={this.state.model.date}
          />
        </div>
        <div className="form-group">
          <label className="col-form-label">Valor:</label>
          <input
            value={this.state.model.amount}
            onChange={(event) =>
              this.setState({
                model: {
                  ...this.state.model,
                  amount: event.target.value,
                },
              })
            }
            type="text"
            className="form-control"
          />
        </div>

        <div className="form-group">
          <label>Observação</label>
          <textarea
            className="form-control"
            onChange={(event) =>
              this.setState({
                model: {
                  ...this.state.model,
                  note: event.target.value,
                },
              })
            }
            value={this.state.model.note}
            rows="3"
          >
            {this.state.model.note}
          </textarea>
        </div>
      </form>
    );
  }

  renderAddModal() {
    return (
      <div
        className="modal fade"
        id="addExpenseModal"
        data-backdrop="static"
        data-keyboard="false"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="addExpenseModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="addExpenseModalLabel">
                {this.state.model.id && this.state.model.id !== 0
                  ? "Editar despesa"
                  : "Adicionar nova despesa"}
              </h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">{this.renderForm()}</div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-dismiss="modal">
                Cancelar
              </button>
              <button
                onClick={() => this.save()}
                type="button"
                className="btn btn-primary"
                data-dismiss="modal"
              >
                Salvar
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderFornecedorForm() {
    let sentences = [];
    if (this.state.model.AppValesExpenseFiles && this.state.model.AppValesExpenseFiles.length > 0) {
      this.state.model.AppValesExpenseFiles.map((file) =>
        file.text.map((sentence) => sentences.push(sentence))
      );
    }
    return (
      <form>
        <div className="form-group">
          <label className="col-form-label">Valor:</label>
          <input
            value={this.state.model.fornecedor}
            onChange={(event) =>
              this.setState({
                model: {
                  ...this.state.model,
                  fornecedor: event.target.value,
                },
              })
            }
            type="text"
            className="form-control"
          />
        </div>
        <div className="form-group">
          <label className="col-form-label">Sentença:</label>
          <select
            className="form-control"
            onChange={(event) =>
              this.setState({
                model: {
                  ...this.state.model,
                  fornecedor: event.target.value,
                },
              })
            }
          >
            <option key={0} value={""}>
              Selecione
            </option>
            {sentences
              .filter((sentence) => sentence.length > 10)
              .map((sentence, index) => (
                <option key={index}>{sentence}</option>
              ))}
          </select>
        </div>
      </form>
    );
  }

  renderAddFornecedorModal() {
    return (
      <div
        className="modal fade"
        id="addFornecedorExpenseModal"
        data-backdrop="static"
        data-keyboard="false"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="addExpenseModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="addExpenseModalLabel">
                {this.state.model.id && this.state.model.id !== 0
                  ? "Editar fornecedor"
                  : "Adicionar nova fornecedor"}
              </h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">{this.renderFornecedorForm()}</div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-dismiss="modal">
                Cancelar
              </button>
              <button
                onClick={() => this.save()}
                type="button"
                className="btn btn-primary"
                data-dismiss="modal"
              >
                Salvar
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  setCpfCnpj(event) {
    // console.log("setCpfCnpj: ", event.target.value);
    let value = event.target.value;
    value = value.replace(/\D/g, "");
    if (value.length <= "00000000000".length) {
      // value = value.replace(/(\d{3})(\d)/, "$1.$2"); //Coloca um ponto entre o terceiro e o quarto dígitos
      // value = value.replace(/(\d{3})(\d)/, "$1.$2"); //Coloca um ponto entre o terceiro e o quarto dígitos
      // //de novo (para o segundo bloco de números)
      // value = value.replace(/(\d{3})(\d{1,2})$/, "$1-$2");
      value = value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
    } else if (value.length === "00000000000000".length) {
      value = value.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5");
    } else {
      value = value
        .substring(0, "00000000000000".length)
        .replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5");
    }
    this.setState({
      model: {
        ...this.state.model,
        cpf_cnpj: value,
      },
    });
  }

  renderCpfCnpjForm() {
    // console.log(this.state.model);
    let sentences = [];
    if (this.state.model.AppValesExpenseFiles && this.state.model.AppValesExpenseFiles.length > 0) {
      this.state.model.AppValesExpenseFiles.map((file) =>
        file.text.map((sentence) => sentences.push(sentence))
      );
    }
    return (
      <form>
        <div className="form-group">
          <label className="col-form-label">Valor:</label>
          <input
            value={this.state.model.cpf_cnpj}
            onChange={this.setCpfCnpj.bind(this)}
            type="text"
            className="form-control"
          />
        </div>
        <div className="form-group">
          <label className="col-form-label">Sentença:</label>
          <select className="form-control" onChange={this.setCpfCnpj.bind(this)}>
            <option key={0} value={""}>
              Selecione
            </option>
            {sentences.map((sentence, index) => (
              <option key={index}>{sentence}</option>
            ))}
          </select>
        </div>
      </form>
    );
  }

  renderAddCpfCnpjModal() {
    return (
      <div
        className="modal fade"
        id="addCpfCnpjExpenseModal"
        data-backdrop="static"
        data-keyboard="false"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="addExpenseModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="addExpenseModalLabel">
                {this.state.model.id && this.state.model.id !== 0
                  ? "Editar CPF/CNPJ"
                  : "Adicionar nova CPF/CNPJ"}
              </h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">{this.renderCpfCnpjForm()}</div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-dismiss="modal">
                Cancelar
              </button>
              <button
                onClick={() => this.save()}
                type="button"
                className="btn btn-primary"
                data-dismiss="modal"
              >
                Salvar
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderTable() {
    return (
      <>
        <table className="table table-hover">
          <thead>
            <tr>
              {this.state.data.length === 0 && !this.state.loading ? (
                <Th scope="col">Nenhuma despesa encontrada</Th>
              ) : (
                <Th scope="col">Despesas</Th>
              )}
              <Th className="actions" scope="col">
                {/* <button
                  // onClick={() => this.add()}
                  className="btn btn-outline-primary btn-sm"
                  data-toggle="modal"
                  data-target="#addExpenseModal"
                >
                  Adicionar despesa
                </button> */}
              </Th>
            </tr>
          </thead>
        </table>
        {this.state.loading ? (
          <Loading />
        ) : (
          <div className="row">
            <List
              data={this.state.data}
              company={this.props.company}
              request={this.props.request}
              show={this.show.bind(this)}
              imageSelectedHandler={this.imageSelectedHandler.bind(this)}
              updateFile={this.updateFile.bind(this)}
              removeFile={this.removeFile.bind(this)}
            />
          </div>
        )}
      </>
    );
  }

  renderModalRemove() {
    return (
      <div
        className="modal fade"
        id="removeExpenseModal"
        data-backdrop="static"
        data-keyboard="false"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="staticBackdropLabel">
                {` `}
              </h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">{`Tem certeza que deseja remover esta despesa?`}</div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-dismiss="modal">
                Cancelar
              </button>
              <button
                onClick={() => this.destroy()}
                type="button"
                className="btn btn-danger"
                data-dismiss="modal"
              >
                Confirmar
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderRejectForm() {
    return (
      <form>
        <div className="form-group">
          <label className="col-form-label">Justificativa:</label>
          <textarea
            value={this.state.request.justification ? this.state.request.justification : ""}
            rows={4}
            onChange={(event) =>
              this.setState({
                request: {
                  ...this.state.request,
                  justification: event.target.value,
                },
              })
            }
            type="text"
            className="form-control"
            id="recipient-justification"
          />
        </div>
      </form>
    );
  }

  renderModalReject() {
    return (
      <div
        className="modal fade"
        id="staticBackdropReject"
        data-backdrop="static"
        data-keyboard="false"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="staticBackdropLabel">
                {` `}
              </h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              Tem certeza que deseja rejeitar esse vale?
              {this.renderRejectForm()}
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-dismiss="modal">
                Não
              </button>
              <button
                onClick={() => this.props.confirmReject(this.state.request)}
                type="button"
                className="btn btn-danger"
                data-dismiss="modal"
              >
                Sim
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div>
        {this.renderAccountabilityForm()}
        {this.renderTable()}
        {this.renderAddModal()}
        {this.renderModalRemove()}
        {this.renderAddFornecedorModal()}
        {this.renderAddCpfCnpjModal()}
        {this.renderModalReject()}
      </div>
    );
  }
}

export default index;
