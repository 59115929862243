import React from "react";
import axios from "axios";

import { Styles, Th } from "./styles";
import List from "./List";
import Expenses from "./Expenses";
import Loading from "../../../../components/Loading";
import Submenu from "../../components/RequestsSubmenu";

import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepButton from "@material-ui/core/StepButton";

const model = {
  id: null,
  description: "",
  // department_id: "",
  // role_category_id: "",
  amount: "",
  amount_used: "",
  amount_refund: "",
  accountability_justification: "",
  bank_checking_account: "",
  refund_process_only: "0",
  payment_method: "",
  cost_center_id: "",
};
class Module extends React.Component {
  // const data = React.useMemo(() => makeData(115), []);
  constructor(props) {
    super(props);
    const userData = JSON.parse(localStorage.getItem(process.env.REACT_APP_USER_KEY));
    const { user } = userData;
    this.state = {
      loading: true,
      showExpenses: false,
      data: [],
      cost_centers: [],
      cost_center: null,
      bank_accounts: [],
      page: 1,
      qtd_per_page: 6,
      sorted: "",
      order: "asc",
      filtered: "W10=",
      pages: 0,

      amount: "",
      amount_used: "",
      amount_refund: "",
      model,
      user,
    };
    // console.log(props);
  }

  componentDidMount() {
    this.get();
    this.getCostCenters();
    this.getBankAccounts();
    this.getMyCostCenter();
  }

  resetAddForm() {
    // console.log(this.state);
    this.setState({
      amount: "",
      amount_used: "",
      amount_refund: "",
      model: {
        ...model,
        cost_center_id: this.state.cost_center ? this.state.cost_center.cost_center_id : "",
      },
    });
  }

  get() {
    const that = this;
    that.setState({
      loading: true,
    });
    let { page, qtd_per_page, sorted, order, filtered } = that.state;
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/company/${that.props.company.id}/app/vales/my-requests?page=${page}&qtd_per_page=${qtd_per_page}&sorted=${sorted}&order=${order}&filtered=${filtered}`
      )
      .then((resp) => {
        // console.log(resp);
        that.setState({
          loading: false,
          data: resp.data.result,
          qtd_per_page: resp.data.limit,
          pages: resp.data.pages,
          // qtd_per_page: resp.data.result.length,
          // pages: 1,
        });
      })
      .catch((err) => {
        console.log(err);
        // loading: false,
        const snack = {
          ...that.state.snack,
          open: true,
          message:
            err.response &&
            err.response.data &&
            err.response.data.message &&
            err.response.data.message !== ""
              ? err.response.data.message
              : "Erro inesperado",
          color: "danger",
        };
        that.props.setSnack(snack);
      });
  }

  getMyCostCenter() {
    const that = this;
    that.setState({
      loading: true,
    });
    // let { page, qtd_per_page, sorted, order, filtered } = that.state;
    axios
      .get(`${process.env.REACT_APP_API_URL}/company/${that.props.company.id}/my-cost-center`)
      .then((resp) => {
        // console.log(resp);
        that.setState({
          loading: false,
          cost_center: resp.data,
          model: {
            ...this.state.model,
            cost_center_id: resp.data.cost_center_id,
          },
        });
      })
      .catch((err) => {
        console.log(err);
        // loading: false,
        const snack = {
          ...that.state.snack,
          open: true,
          message:
            err.response &&
            err.response.data &&
            err.response.data.message &&
            err.response.data.message !== ""
              ? err.response.data.message
              : "Erro inesperado",
          color: "danger",
        };
        that.props.setSnack(snack);
      });
  }

  getCostCenters() {
    const that = this;
    that.setState({
      loading: true,
    });
    axios
      .get(`${process.env.REACT_APP_API_URL}/company/${this.props.company.id}/cost-centers-select`)
      .then((resp) => {
        // console.log(resp);
        that.setState({
          loading: false,
          cost_centers: resp.data.result,
        });
      })
      .catch((err) => {
        console.log(err);
        // loading: false,
        const snack = {
          ...that.state.snack,
          open: true,
          message:
            err.response &&
            err.response.data &&
            err.response.data.message &&
            err.response.data.message !== ""
              ? err.response.data.message
              : "Erro inesperado",
          color: "danger",
        };
        that.props.setSnack(snack);
      });
  }

  getBankAccounts() {
    const that = this;
    that.setState({
      loading: true,
    });
    axios
      .get(`${process.env.REACT_APP_API_URL}/user/${this.state.user.id}/bank-accounts`)
      .then((resp) => {
        // console.log(resp);
        that.setState({
          loading: false,
          bank_accounts: resp.data.result,
        });
      })
      .catch((err) => {
        console.log(err);
        // loading: false,
        const snack = {
          ...that.state.snack,
          open: true,
          message:
            err.response &&
            err.response.data &&
            err.response.data.message &&
            err.response.data.message !== ""
              ? err.response.data.message
              : "Erro inesperado",
          color: "danger",
        };
        that.props.setSnack(snack);
      });
  }

  save() {
    const that = this;
    const data = this.state.model;
    if (data.id && data.id !== 0) {
      axios
        .put(
          `${process.env.REACT_APP_API_URL}/company/${that.props.company.id}/app/vales/requests/${data.id}`,
          data
        )
        .then((resp) => {
          // console.log(resp);
          that.get();
          const snack = {
            open: true,
            message: "Requisição editada com sucesso",
            color: "success",
          };
          that.props.setSnack(snack);
          that.setState({
            loading: false,
            model,
          });
        })
        .catch((err) => {
          console.log(err);
          // loading: false,
          const snack = {
            ...that.state.snack,
            open: true,
            message:
              err.response &&
              err.response.data &&
              err.response.data.message &&
              err.response.data.message !== ""
                ? err.response.data.message
                : "Erro inesperado",
            color: "danger",
          };
          that.props.setSnack(snack);
        });
    } else {
      axios
        .post(
          `${process.env.REACT_APP_API_URL}/company/${that.props.company.id}/app/vales/requests`,
          data
        )
        .then((resp) => {
          // console.log(resp);
          console.log("resp.data: ", resp.data);
          that.get();
          const snack = {
            open: true,
            message: "Requisição cadastrada com sucesso",
            color: "success",
          };
          that.props.setSnack(snack);
          if (resp.data.refund_process_only === "1" || resp.data.refund_process_only === true) {
            this.setState({ model });
            that.setState({
              loading: false,
              model: resp.data,
              showExpenses: true,
            });
          } else {
            that.setState({
              loading: false,
              model,
            });
          }
        })
        .catch((err) => {
          console.log(err);
          // loading: false,
          const snack = {
            ...that.state.snack,
            open: true,
            message:
              err.response &&
              err.response.data &&
              err.response.data.message &&
              err.response.data.message !== ""
                ? err.response.data.message
                : "Erro inesperado",
            color: "danger",
          };
          that.props.setSnack(snack);
        });
    }
  }

  set(model) {
    this.setState({
      model,
      amount_used: model.amount_used
        ? model.amount_used.toFixed(2).toString().split(".").join(",")
        : "",
      amount_refund: model.amount_refund
        ? model.amount_refund.toFixed(2).toString().split(".").join(",")
        : "",
    });
  }

  showExpenseModal(model) {
    // this.set(model);
    this.setState({ model, showExpenses: true });
  }

  show(model) {
    this.set(model);
  }

  remove(model) {
    this.set(model);
  }

  contestPaymentItem(model) {
    this.set(model);
  }

  destroy() {
    const that = this;
    const data = this.state.model;
    if (data.id && data.id !== 0) {
      axios
        .delete(
          `${process.env.REACT_APP_API_URL}/company/${that.props.company.id}/app/vales/requests/${data.id}`
          // `${process.env.REACT_APP_API_URL}/company/${that.props.company.id}/app/vales/requests/${data.id}`
        )
        .then((resp) => {
          // console.log(resp);
          that.get();
          const snack = {
            open: true,
            message: "Requisição removido com sucesso",
            color: "success",
          };
          that.props.setSnack(snack);
          that.setState({
            loading: false,
            model,
          });
        })
        .catch((err) => {
          console.log(err);
          // loading: false,
          const snack = {
            ...that.state.snack,
            open: true,
            message:
              err.response &&
              err.response.data &&
              err.response.data.message &&
              err.response.data.message !== ""
                ? err.response.data.message
                : "Erro inesperado",
            color: "danger",
          };
          that.props.setSnack(snack);
        });
    }
  }

  contestPayment(item) {
    const that = this;
    const data = this.state.model;
    if (data.id && data.id !== 0) {
      axios
        .put(
          `${process.env.REACT_APP_API_URL}/company/${that.props.company.id}/app/vales/requests/${data.id}/contest-payment`
        )
        .then((resp) => {
          // console.log(resp);
          that.get();
          const snack = {
            open: true,
            message: "Pagamento contestado com sucesso!",
            color: "success",
          };
          that.props.setSnack(snack);
          that.setState({
            loading: false,
            model,
          });
        })
        .catch((err) => {
          console.log(err);
          // loading: false,
          const snack = {
            ...that.state.snack,
            open: true,
            message:
              err.response &&
              err.response.data &&
              err.response.data.message &&
              err.response.data.message !== ""
                ? err.response.data.message
                : "Erro inesperado",
            color: "danger",
          };
          that.props.setSnack(snack);
        });
    }
  }

  confirmPaymentItem(item) {
    const that = this;
    const data = item;
    if (data.id && data.id !== 0) {
      axios
        .put(
          `${process.env.REACT_APP_API_URL}/company/${that.props.company.id}/app/vales/requests/${data.id}/confirm-payment`
        )
        .then((resp) => {
          // console.log(resp);
          that.get();
          const snack = {
            open: true,
            message: "Pagamento confirmado com sucesso",
            color: "success",
          };
          that.props.setSnack(snack);
          that.setState({
            loading: false,
            model,
          });
        })
        .catch((err) => {
          console.log(err);
          // loading: false,
          const snack = {
            ...that.state.snack,
            open: true,
            message:
              err.response &&
              err.response.data &&
              err.response.data.message &&
              err.response.data.message !== ""
                ? err.response.data.message
                : "Erro inesperado",
            color: "danger",
          };
          that.props.setSnack(snack);
        });
    }
  }

  confirmUse(item) {
    const that = this;
    const data = item;
    if (data.id && data.id !== 0) {
      axios
        .put(
          `${process.env.REACT_APP_API_URL}/company/${that.props.company.id}/app/vales/requests/${data.id}/accountability-confirm-use`
        )
        .then((resp) => {
          // console.log(resp);
          that.get();
          const snack = {
            open: true,
            message: "Uso confirmado com sucesso",
            color: "success",
          };
          that.props.setSnack(snack);
          that.setState({
            loading: false,
            model,
          });
        })
        .catch((err) => {
          console.log(err);
          // loading: false,
          const snack = {
            ...that.state.snack,
            open: true,
            message:
              err.response &&
              err.response.data &&
              err.response.data.message &&
              err.response.data.message !== ""
                ? err.response.data.message
                : "Erro inesperado",
            color: "danger",
          };
          that.props.setSnack(snack);
        });
    }
  }

  confirmNotUsed(item) {
    const that = this;
    const data = item;
    if (data.id && data.id !== 0) {
      axios
        .put(
          `${process.env.REACT_APP_API_URL}/company/${that.props.company.id}/app/vales/requests/${data.id}/accountability-confirm-not-used`
        )
        .then((resp) => {
          // console.log(resp);
          that.get();
          const snack = {
            open: true,
            message: "Não utilização confirmada com sucesso",
            color: "success",
          };
          that.props.setSnack(snack);
          that.setState({
            loading: false,
            model,
          });
        })
        .catch((err) => {
          console.log(err);
          // loading: false,
          const snack = {
            ...that.state.snack,
            open: true,
            message:
              err.response &&
              err.response.data &&
              err.response.data.message &&
              err.response.data.message !== ""
                ? err.response.data.message
                : "Erro inesperado",
            color: "danger",
          };
          that.props.setSnack(snack);
        });
    }
  }

  finalizesAccountability() {
    const that = this;
    const data = this.state.model;
    if (data.id && data.id !== 0) {
      axios
        .put(
          `${process.env.REACT_APP_API_URL}/company/${that.props.company.id}/app/vales/requests/${data.id}/accountability-completed`
        )
        .then((resp) => {
          // console.log(resp);
          that.get();
          const snack = {
            open: true,
            message: "Prestação de contas finalizda com sucesso",
            color: "success",
          };
          that.props.setSnack(snack);
          that.setState({
            loading: false,
            model,
          });
        })
        .catch((err) => {
          console.log(err);
          // loading: false,
          const snack = {
            ...that.state.snack,
            open: true,
            message:
              err.response &&
              err.response.data &&
              err.response.data.message &&
              err.response.data.message !== ""
                ? err.response.data.message
                : "Erro inesperado",
            color: "danger",
          };
          that.props.setSnack(snack);
        });
    }
  }

  // destroy() {
  //   const that = this;
  //   const data = this.state.model;
  //   if (data.id && data.id !== 0) {
  //     axios
  //       .delete(
  //         `${process.env.REACT_APP_API_URL}/company/${that.props.company.id}/app/vales/requests/${data.id}`
  //       )
  //       .then((resp) => {
  //         // console.log(resp);
  //         that.get();
  //         const snack = {
  //           open: true,
  //           message: "Prestação de contas finalizda com sucesso",
  //           color: "success",
  //         };
  //         that.props.setSnack(snack);
  //         that.setState({
  //           loading: false,
  //           model,
  //         });
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //         // loading: false,
  //         const snack = {
  //           ...that.state.snack,
  //           open: true,
  //           message:
  //             err.response &&
  //             err.response.data &&
  //             err.response.data.message &&
  //             err.response.data.message !== ""
  //               ? err.response.data.message
  //               : "Erro inesperado",
  //           color: "danger",
  //         };
  //         that.props.setSnack(snack);
  //       });
  //   }
  // }

  nextPage() {
    const that = this;
    let page = that.state.page + 1;
    if (page > this.state.pages) {
      page = this.state.pages;
    }
    if (page !== this.state.page) {
      that.setState({ page }, () => {
        setTimeout(() => that.get(), 10);
      });
    }
  }

  previousPage() {
    const that = this;
    let page = that.state.page - 1;
    if (page < 1) {
      page = 1;
    }
    if (page !== this.state.page) {
      that.setState({ page }, () => {
        setTimeout(() => that.get(), 10);
      });
    }
  }

  setAmountUsed(event) {
    let value = event.target.value;
    value = value.replace(/\D/g, "");
    value = parseFloat(value / 100);

    const amount_refund = value - parseFloat(this.state.model.amount);

    this.setState({
      model: {
        ...this.state.model,
        amount_used: value,
        amount_refund,
      },
      amount_used: value.toFixed(2).toString().split(".").join(","),
      amount_refund: (amount_refund < 0 ? amount_refund * -1 : amount_refund)
        .toFixed(2)
        .toString()
        .split(".")
        .join(","),
    });
  }

  setAmount(event) {
    let value = event.target.value;
    value = value.replace(/\D/g, "");
    value = parseFloat(value / 100);

    this.setState({
      model: {
        ...this.state.model,
        amount: value,
      },
      amount: value.toFixed(2).toString().split(".").join(","),
    });
  }

  renderTable() {
    return (
      <>
        <table className="table table-hover">
          <thead>
            <tr>
              {this.state.data.length > 0 ? (
                <>
                  <Th scope="col"></Th>
                </>
              ) : (
                <Th scope="col">Nenhum registro encontrado</Th>
              )}
              <Th className="actions" scope="col">
                <button
                  onClick={() => this.resetAddForm()}
                  className="btn btn-primary btn-sm"
                  data-toggle="modal"
                  data-target="#staticBackdrop"
                >
                  Adicionar requisição
                </button>
              </Th>
            </tr>
          </thead>
        </table>
        {this.state.loading ? (
          <Loading />
        ) : (
          <div className="row">
            <List
              data={this.state.data}
              show={this.show.bind(this)}
              showExpenseModal={this.showExpenseModal.bind(this)}
              remove={this.remove.bind(this)}
              confirmPaymentItem={this.confirmPaymentItem.bind(this)}
              contestPaymentItem={this.contestPaymentItem.bind(this)}
              confirmUse={this.confirmUse.bind(this)}
              confirmNotUsed={this.confirmNotUsed.bind(this)}
              set={this.set.bind(this)}
            />
          </div>
        )}
      </>
    );
  }

  renderPagination() {
    // console.log(this.state);
    const that = this;
    if (that.state.page === 1 && that.state.page === that.state.pages) {
      return null;
    }
    let pages = [];
    for (let i = 1; i <= this.state.pages; i++) {
      pages.push(i);
    }
    return (
      <div className="container">
        <div className="row">
          <div className="col-sm">
            <button
              onClick={that.previousPage.bind(this)}
              className="btn btn-light btn-md btn-block"
              disabled={that.state.page === 1}
            >
              Anterior
            </button>
          </div>
          <div className="col-sm">
            <div className="form-group">
              <select
                className="form-control"
                value={that.state.page}
                onChange={(event) =>
                  that.setState({ page: event.target.value }, () => {
                    setTimeout(() => that.get(), 10);
                  })
                }
              >
                {pages.map((actualPage) => (
                  <option key={actualPage} value={actualPage}>
                    Página {actualPage}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="col-sm">
            <div className="form-group">
              <select
                className="form-control"
                value={that.state.qtd_per_page}
                onChange={(event) =>
                  that.setState({ qtd_per_page: event.target.value, page: 1 }, () => {
                    setTimeout(() => that.get(), 10);
                  })
                }
              >
                {[3, 6, 9, 30, 60, 90].map((pageSize) => (
                  <option key={pageSize} value={pageSize}>
                    Mostrar {pageSize} linhas
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="col-sm">
            <button
              onClick={that.nextPage.bind(this)}
              className="btn btn-light btn-md btn-block"
              disabled={that.state.page === that.state.pages}
            >
              Próxima
            </button>
          </div>
        </div>
      </div>
    );
  }

  renderJurisdiction() {
    const cost_center =
      this.state.cost_centers.filter(
        (cost_center) => parseInt(cost_center.id) === parseInt(this.state.model.cost_center_id)
      )[0] || null;
    let level1_staff_name = "";
    let level2_staff_name = "";
    let level1_limit = 0;
    let level1_limit_formated = "";
    if (cost_center) {
      if (this.state.model.payment_method === "money") {
        level1_staff_name =
          cost_center &&
          cost_center.AppValesApprovingMomeyLevel1 &&
          cost_center.AppValesApprovingMomeyLevel1.User &&
          cost_center.AppValesApprovingMomeyLevel1.User.name !== ""
            ? cost_center.AppValesApprovingMomeyLevel1.User.name
            : "";
        level2_staff_name =
          cost_center &&
          cost_center.AppValesApprovingMomeyLevel2 &&
          cost_center.AppValesApprovingMomeyLevel2.User &&
          cost_center.AppValesApprovingMomeyLevel2.User.name !== ""
            ? cost_center.AppValesApprovingMomeyLevel2.User.name
            : "";
        level1_limit =
          cost_center && cost_center.app_vales_money_level1_limit
            ? parseFloat(cost_center.app_vales_money_level1_limit)
            : 0;
        // level1_limit_formated = level1_limit.toLocaleString('pt-BR', {
        //   style: 'currency',
        //   currency: 'BRL',
        //   minimumFractionDigits: 2,
        // });
        // if (Platform.OS === 'android') {
        //   // only android needs polyfill
        //   level1_limit_formated = new Intl.NumberFormat('en-BR', {
        //     style: 'currency',
        //     currency: 'BRL',
        //   }).format(level1_limit);
        // }
      }
      if (this.state.model.payment_method === "bank") {
        level1_staff_name =
          cost_center &&
          cost_center.AppValesApprovingBankLevel1 &&
          cost_center.AppValesApprovingBankLevel1.User &&
          cost_center.AppValesApprovingBankLevel1.User.name !== ""
            ? cost_center.AppValesApprovingBankLevel1.User.name
            : "";
        level2_staff_name =
          cost_center &&
          cost_center.AppValesApprovingBankLevel2 &&
          cost_center.AppValesApprovingBankLevel2.User &&
          cost_center.AppValesApprovingBankLevel2.User.name !== ""
            ? cost_center.AppValesApprovingBankLevel2.User.name
            : "";
        level1_limit =
          cost_center && cost_center.app_vales_bank_level1_limit
            ? parseFloat(cost_center.app_vales_bank_level1_limit)
            : 0;
      }
      if (this.state.model.payment_method === "card") {
        level1_staff_name =
          cost_center &&
          cost_center.AppValesApprovingCardLevel1 &&
          cost_center.AppValesApprovingCardLevel1.User &&
          cost_center.AppValesApprovingCardLevel1.User.name !== ""
            ? cost_center.AppValesApprovingCardLevel1.User.name
            : "";
        level2_staff_name =
          cost_center &&
          cost_center.AppValesApprovingCardLevel2 &&
          cost_center.AppValesApprovingCardLevel2.User &&
          cost_center.AppValesApprovingCardLevel2.User.name !== ""
            ? cost_center.AppValesApprovingCardLevel2.User.name
            : "";
        level1_limit =
          cost_center && cost_center.app_vales_card_level1_limit
            ? parseFloat(cost_center.app_vales_card_level1_limit)
            : 0;
      }
      level1_limit_formated = level1_limit.toFixed(2).toString().split(".").join(",");
    }
    return (
      <>
        {level1_staff_name !== "" ? (
          <div className="alert alert-warning" role="alert">
            {`O responsável pela aprovação deste centro de custo é ${level1_staff_name}`}
          </div>
        ) : null}
        {level1_limit > 0 && this.state.model.amount > level1_limit ? (
          <div className="alert alert-warning" role="alert">
            {`Valores acima de R$ ${level1_limit_formated} será necessária a aprovação do ${level2_staff_name}.`}
          </div>
        ) : null}
      </>
    );
  }

  resetForm() {
    // console.log(this.state);
    this.setState({
      model: {
        ...model,
        cost_center_id: this.state.cost_center ? this.state.cost_center.cost_center_id : "",
        amount: "",
        bank_checking_account: "",
      },
    });
  }

  renderForm() {
    // console.log(this.state);
    return (
      <form>
        {this.renderJurisdiction()}
        <div className="row">
          <div className="form-group col-md-6">
            <label className="col-form-label">Requisição de:</label>
            <select
              className="form-control"
              value={this.state.model.refund_process_only}
              onChange={(event) =>
                this.setState({
                  model: {
                    ...this.state.model,
                    refund_process_only: event.target.value,
                    payment_method: "",
                  },
                })
              }
            >
              <option value="0">Vale</option>
              <option value="1">Reembolso</option>
            </select>
          </div>
          <div className="form-group col-md-6">
            <label className="col-form-label">Valor:</label>
            <input
              value={this.state.amount}
              onChange={this.setAmount.bind(this)}
              type="text"
              className="form-control"
            />
          </div>
        </div>
        <div className="form-group">
          <label>Descrição</label>
          <textarea
            className="form-control"
            onChange={(event) =>
              this.setState({
                model: {
                  ...this.state.model,
                  description: event.target.value,
                },
              })
            }
            value={this.state.model.description}
            rows="3"
          >
            {this.state.model.description}
          </textarea>
        </div>
        <div className="row">
          <div
            className={`form-group col-md-${
              this.state.model.payment_method === "bank" ? "4" : "6"
            }`}
          >
            <label className="col-form-label">Forma de recebimento:</label>
            <select
              className="form-control"
              value={this.state.model.payment_method}
              onChange={(event) =>
                this.setState({
                  model: {
                    ...this.state.model,
                    payment_method: event.target.value,
                  },
                })
              }
            >
              <option key={0} value={""}>
                Selecione
              </option>
              <option value={"money"}>Espécie</option>
              <option value={"bank"}>Depósito bancário</option>
              {this.state.model.refund_process_only === "0" ? (
                <option value={"card"}>Cartão</option>
              ) : null}
            </select>
          </div>
          <div
            className={`form-group col-md-${
              this.state.model.payment_method === "bank" ? "4" : "6"
            }`}
          >
            <label className="col-form-label">Centro de custo:</label>
            <select
              className="form-control"
              value={this.state.model.cost_center_id}
              onChange={(event) =>
                this.setState({
                  model: {
                    ...this.state.model,
                    cost_center_id: event.target.value,
                  },
                })
              }
            >
              <option key={0} value={""}>
                Selecione
              </option>
              {this.state.cost_centers.map((cost_center) => (
                <option key={cost_center.id} value={cost_center.id}>
                  {cost_center.description}
                </option>
              ))}
            </select>
          </div>
          {this.state.model.payment_method === "bank" ? (
            <div className={`form-group col-md-4`}>
              <label className="col-form-label">Conta bancária:</label>
              <select
                className="form-control"
                value={this.state.model.bank_checking_account}
                onChange={(event) =>
                  this.setState({
                    model: {
                      ...this.state.model,
                      bank_checking_account: event.target.value,
                    },
                  })
                }
              >
                <option key={0} value={""}>
                  Selecione
                </option>
                {this.state.bank_accounts.map((bank_account) => {
                  const text = `${bank_account.bank} ${bank_account.agency}/${
                    bank_account.account
                  }${bank_account.digit && bank_account.digit !== "" ? "-" : ""}${
                    bank_account.digit
                  }`;
                  return (
                    <option key={bank_account.id} value={text}>
                      {text}
                    </option>
                  );
                })}
              </select>
            </div>
          ) : null}
        </div>
      </form>
    );
  }

  renderModal() {
    return (
      <div
        className="modal fade"
        id="staticBackdrop"
        data-backdrop="static"
        data-keyboard="false"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="staticBackdropLabel">
                {this.state.model.id && this.state.model.id !== 0
                  ? "Editar requisição"
                  : "Adicionar nova requisição"}
              </h5>
              <button
                type="button"
                className="close"
                onClick={() => this.resetAddForm()}
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">{this.renderForm()}</div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
                onClick={() => this.resetForm()}
              >
                Cancelar
              </button>
              <button
                onClick={() => this.save()}
                type="button"
                className="btn btn-primary"
                data-dismiss="modal"
              >
                Salvar
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderStepsModal() {
    let steps = [];
    let activeStep = 0;
    if (this.state.model && this.state.model.state && this.state.model.state.steps) {
      steps = this.state.model.state.steps;
      activeStep = this.state.model.state.activeStep;
    }
    return (
      <div
        className="modal fade"
        id="stepModal"
        data-backdrop="static"
        data-keyboard="false"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="stepModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="stepModalLabel">
                {" "}
              </h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <Stepper nonLinear activeStep={activeStep} orientation="vertical">
                {steps.map((label, index) => (
                  <Step key={label}>
                    <StepButton>{label}</StepButton>
                  </Step>
                ))}
              </Stepper>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderAccountabilityForm() {
    // console.log(this.state);
    return (
      <form>
        <div className="form-group">
          <label className="col-form-label">Justificativa:</label>
          <textarea
            className="form-control"
            onChange={(event) =>
              this.setState({
                model: {
                  ...this.state.model,
                  accountability_justification: event.target.value,
                },
              })
            }
            value={this.state.model.accountability_justification}
            rows="3"
          >
            {this.state.model.accountability_justification}
          </textarea>
        </div>
        <div className="row">
          <div className="form-group col-md-4">
            <label>Valor</label>
            <input
              className="form-control"
              value={
                this.state.model.amount
                  ? this.state.model.amount.toFixed(2).toString().split(".").join(",")
                  : "0,00"
              }
              disabled={true}
            />
          </div>
          <div className="form-group col-md-4">
            <label>Utilizado</label>
            <input
              className="form-control"
              value={this.state.amount_used}
              onChange={this.setAmountUsed.bind(this)}
            />
          </div>
          <div className="form-group col-md-4">
            <label>{this.state.model.amount_refund < 0 ? "Devolução" : "Reembolso"}</label>
            <input className="form-control" value={this.state.amount_refund} disabled={true} />
          </div>
        </div>
      </form>
    );
  }

  renderAccountabilityModal() {
    return (
      <div
        className="modal fade"
        id="AccountabilityModal"
        data-backdrop="static"
        data-keyboard="false"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="AccountabilityModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Prestação de contas</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">{this.renderAccountabilityForm()}</div>
            <div className="modal-footer">
              <button
                onClick={() => this.save()}
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                Salvar
              </button>
              <button
                onClick={() => this.finalizesAccountability()}
                type="button"
                className="btn btn-primary"
                data-dismiss="modal"
              >
                Finalizar
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderModalRemove() {
    return (
      <div
        className="modal fade"
        id="staticBackdropRemove"
        data-backdrop="static"
        data-keyboard="false"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="staticBackdropLabel">
                {` `}
              </h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">{`Tem certeza que deseja remover (${this.state.model.description})?`}</div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-dismiss="modal">
                Cancelar
              </button>
              <button
                onClick={() => this.destroy()}
                type="button"
                className="btn btn-danger"
                data-dismiss="modal"
              >
                Confirmar
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderModalContest() {
    return (
      <div
        className="modal fade"
        id="ModalContestPayment"
        data-backdrop="static"
        data-keyboard="false"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="staticBackdropLabel">
                {` `}
              </h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">{`Tem certeza que deseja contestar o pagamento?`}</div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-dismiss="modal">
                Cancelar
              </button>
              <button
                onClick={() => this.contestPayment()}
                type="button"
                className="btn btn-danger"
                data-dismiss="modal"
              >
                Confirmar
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderRemoveModal() {
    return (
      <div
        className="modal fade"
        id="removeModal"
        data-backdrop="static"
        data-keyboard="false"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="staticBackdropLabel">
                Excluir Vale/Reembolso
              </h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">{`Tem certeza que deseja excluir esta requisição?`}</div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-dismiss="modal">
                Não
              </button>
              <button
                onClick={() => this.destroy()}
                type="button"
                className="btn btn-danger"
                data-dismiss="modal"
              >
                Sim
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <>
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-2">
              <Submenu path="my-requests" company={this.props.company} />
            </div>
            <div className="col-sm-10">
              {this.state.showExpenses ? (
                <Expenses
                  request={this.state.model}
                  company={this.props.company}
                  setSnack={this.props.setSnack}
                  back={() => {
                    this.setState({ showExpenses: false });
                    this.get();
                  }}
                />
              ) : (
                <>
                  <div>
                    <Styles>
                      {this.renderTable()}
                      {this.state.data.length > 0 ? this.renderPagination() : null}
                    </Styles>
                  </div>
                  {this.renderModal()}
                  {this.renderModalRemove()}
                  {this.renderModalContest()}
                  {this.renderAccountabilityModal()}
                  {this.renderStepsModal()}
                  {this.renderRemoveModal()}
                </>
              )}
            </div>
          </div>
        </div>
      </>
      // <>
      //   <div>
      //     <Styles>
      //       {this.renderTable()}
      //       {this.state.data.length > 0 ? this.renderPagination() : null}
      //     </Styles>
      //   </div>
      //   {this.renderModal()}
      //   {this.renderModalRemove()}
      // </>
    );
  }
}

export default Module;
