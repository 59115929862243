import React from "react";
// import { useTable, usePagination } from "react-table";
// import BTable from "react-bootstrap/Table";
// import GlobalStyle from "../../../../../styles/global";
import { Td } from "../styles";
// import "bootstrap/dist/css/bootstrap.min.css";
import {
  // MdError,
  // MdCheckCircle,
  MdClose,
  // MdModeEdit,
} from "react-icons/md";

export default function List(props) {
  // console.log(props);
  const { data, remove, owner } = props;
  // Use the state and functions returned from useTable to build your UI

  // Render the UI for your table
  // console.log();
  return (
    <>
      {[owner, ...data].map((item) => {
        // console.log(item);
        // console.log(item);
        let owner = false;
        let id = Math.random() * 10000000;
        let name = "";
        let role = "";
        if (item.name) {
          owner = true;
          name = item.name;
          role = "Proprietário";
        } else {
          name = item.User && item.User.name ? item.User.name : "";
          role = "Administrador";
        }
        return (
          <tr key={id}>
            <Td>{name}</Td>
            <Td>{role}</Td>
            <Td className="actions">
              {owner ? (
                " "
              ) : (
                <button
                  type="button"
                  className="btn btn-link btn-link-danger"
                  onClick={() => remove(item)}
                  data-toggle="modal"
                  data-target="#staticBackdropRemove"
                >
                  <MdClose />
                </button>
              )}
            </Td>
          </tr>
        );
      })}
    </>
  );
}
