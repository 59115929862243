import styled from "styled-components";
import { Colors } from "../../../../themes";

export const Container = styled.div`
  position: relative;
  margin-left: 0;
  padding: 12px 0px 10px;
  height: 47px;
  margin-bottom: 20px;
  /* margin-top: 2px; */
  /* border-bottom: 1px solid ${Colors.grayLigth}; */
  justify-content: center;
  flex-direction: column;
  display: flex;
  background: white;
  color: ${Colors.primary};
  font-size: 15px;
  ul {
    display: flex;
    flex-direction: row;
    /* margin: 0px 20px; */
  }
  li:first-child{
    margin-left: 40px;
  }
  a {
    border: none !important;
    display: flex;
    align-items: center;
    height: 47px;
    text-decoration: none;
    color: ${Colors.grayMedium};
    padding: 11px 15px;
    font-size: 14px;
    &.active {
      height: 46px !important;
      padding-bottom: 9px !important;
      color: ${Colors.primary} !important;
      border-bottom: 1px solid ${Colors.primary} !important;
    }
  }
`;
