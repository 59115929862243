import React, { Component } from "react";
import { Link } from "react-router-dom";
import CreateGlobalStyle, {
  Container,
  Logo,
  Content,
  Panel,
  // Overlay,
} from "../styles";
import axios from "axios";
// import Button from "@material-ui/core/Button";
// import Snackbar from "@material-ui/core/Snackbar";
// import MuiAlert from "@material-ui/lab/Alert";
// import { makeStyles } from "@material-ui/core/styles";

import Snackbars from "../../../../components/SnackbarContent";
import AddAlert from "@material-ui/icons/AddAlert";

// import { FaUser, FaLock } from "react-icons/fa";
import { MdPhoneIphone } from "react-icons/md";
import { AiOutlineMail, AiOutlineUser } from "react-icons/ai";

import logo from "../../../../assets/img/logo.png";

class index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
      tel: "",
      loading: false,
      snack: {
        open: false,
        message: "",
        color: "success",
      },
    };
  }

  signup() {
    // console.log(this.state);
    this.setState({ loading: true });

    axios
      .post(`${process.env.REACT_APP_API_URL}/signup`, this.state)
      .then((response) => {
        // this.setState({ loading: false });
        this.setState({
          loading: false,
          name: "",
          email: "",
          tel: "",
          snack: {
            ...this.state.snack,
            open: true,
            message: response.data && response.data.message && response.data.message !== "" ? response.data.message : "Erro inesperado",
            color: "success",
          },
        });
        // console.log(response);
      })
      .catch((err) => {
        this.setState({
          loading: false,
          snack: {
            ...this.state.snack,
            open: true,
            message: err.response && err.response.data && err.response.data.message && err.response.data.message !== "" ? err.response.data.message : "Erro inesperado",
            color: "danger",
          },
        });
        // console.log(err.response);
      });
  }

  renderForm() {
    return (
      <form>
        <div className="form-group">
          <label>Nome</label>
          <div className="input-icon icon-email">
            <AiOutlineUser />
          </div>
          <input
            autoFocus={true}
            className="form-control"
            id="name"
            name="name"
            value={this.state.name}
            onChange={(event) => this.setState({ name: event.target.value })}
            placeholder="Nome"
            type="text"
          />
        </div>
        <div className="form-group">
          <label>E-mail</label>
          <div className="input-icon icon-email">
            <AiOutlineMail />
          </div>
          <input className="form-control" id="email" name="email" value={this.state.email} onChange={(event) => this.setState({ email: event.target.value })} placeholder="E-mail" type="email" />
        </div>
        <div className="form-group">
          <label>Celular</label>
          <div className="input-icon icon-email">
            <MdPhoneIphone />
          </div>
          <input className="form-control" id="tel" name="tel" value={this.state.tel} onChange={(event) => this.setState({ tel: event.target.value })} placeholder="Celular" type="text" />
        </div>
        {this.state.loading ? (
          <button className="btn btn-primary btn-lg btn-block" type="button" value="Log In" onClick={() => {}} disabled={true}>
            <div className="spinner-border spinner-border-sm text-light" role="status">
              <span className="sr-only">Carregando...</span>
            </div>{" "}
            Entrar
          </button>
        ) : (
          <a className="btn btn-primary btn-lg btn-block" onClick={this.signup.bind(this)} href="#this">
            Entrar
          </a>
        )}
      </form>
    );
  }

  render() {
    // console.log(this.state);
    return (
      <>
        {/* <Overlay /> */}
        <Container>
          {/* <Alert severity="error">This is an error message!</Alert>
          <Alert severity="warning">This is a warning message!</Alert>
          <Alert severity="info">This is an information message!</Alert>
          <Alert severity="success">This is a success message!</Alert> */}
          <Snackbars
            place="tr"
            color={this.state.snack.color}
            icon={AddAlert}
            message={this.state.snack.message}
            open={this.state.snack.open}
            closeNotification={() => this.setState({ snack: { ...this.state.snack, open: false } })}
            close
          />

          <Logo>
            <a href="http://hubera.com.br">
              <img src={logo} alt="Hubera" />
            </a>
          </Logo>
          <Content>
            <Panel>
              <h2>Criar sua conta</h2>
              {this.renderForm()}
              <div className="panel-footer">
                Já tem uma conta? &nbsp;
                <Link to="/login">
                  <span>Logar</span>
                </Link>
              </div>
            </Panel>
          </Content>
        </Container>
        <CreateGlobalStyle />
      </>
    );
  }
}

export default index;
