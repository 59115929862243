import styled from "styled-components";
import { Colors } from "../../../../themes";

export const MaintenanceIcon = styled.a`
  font-size: 40px;
  color: ${Colors.primary} !important;
  background: transparent !important;
  cursor: pointer;
  border: none !important;
  /* &:visited {
    color: ${Colors.primary} !important;
    background: transparent !important;
    border: none !important;
  }
  &:hover {
    color: ${Colors.primary} !important;
    background: transparent !important;
    border: none !important;
  }
  &:active {
    color: ${Colors.primary} !important;
    background: transparent !important;
    border: none !important;
  } */
`;
