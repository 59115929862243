import React from "react";
// import axios from "axios";

// import { Container } from './styles';
import pdfImage from "../../../../../assets/img/pdf.png";

function List(props) {
  // console.log(props);
  const { file } = props;
  const isPdf = file.url.indexOf(".pdf") !== -1;
  return (
    <div className="card card-request">
      {isPdf ? (
        <a href={file.url} target="_blank">
          <img src={pdfImage} className="card-img-top" alt="Comprovante" />
        </a>
      ) : (
        <a href="#this" data-toggle="modal" data-target={`#staticBackdrop_${props.file.id}`}>
          <img src={file.url} className="card-img-top" alt="Comprovante" />
        </a>
      )}

      <div
        className="modal fade"
        id={`staticBackdrop_${props.file.id}`}
        data-backdrop="static"
        data-keyboard="false"
        tabIndex="-1"
        aria-labelledby={`staticBackdropLabel_${props.file.id}`}
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <img src={file.url} className="img-fluid" alt="Comprovante" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default List;
