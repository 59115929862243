import styled from "styled-components";
import { Colors } from "../../themes";

export const Container = styled.div`
  position: relative;
  margin-left: 0;
  padding: 12px 40px 10px;
  min-height: 63px;
  margin-top: 2px;
  border-bottom: 1px solid ${Colors.primaryMedium};
  justify-content: center;
  flex-direction: column;
  display: flex;
  background: ${Colors.primaryLigth};
  color: ${Colors.primary};
  font-size: 15px;
`;

export const Nav = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
