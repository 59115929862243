import styled from "styled-components";
import { Colors } from "../../../themes";
import { createGlobalStyle } from "styled-components";

import bg from "../../../assets/img/bg.jpg";

export default createGlobalStyle`

  *{
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
  }

  html, body, #root {
    height: 100%;
  }

  body {
    font: 14px 'Roboto', sans-serif;
    color: #333;
    -webkit-font-smoothing: antaliesed !important;
    /* background: linear-gradient(to bottom,${Colors.primary},${Colors.grayMedium}); */
    background: ${Colors.primary} url(${bg}) no-repeat;
    background-size: (calc(100%) + 100) (calc(100%) + 100);
    
  }

  ul {
    list-style: none;
  }

  label{
    width: 100%;
    display: flex;
    margin-bottom: 10px;
    font-size: 13px;
    /* font-weight: 400; */
    font-size: 12px;
    font-weight: bold;
  }
  .form-group {
      position: relative;
      margin-bottom: 20px;
  }
  .form-control {
    box-shadow: inset 0 1px 2px rgba(203,203,210,0.4);
    padding: 11px 12px;
    padding-right: 8px;
    padding-left: 50px;
    display: block;
    width: 100%;
    font-size: 14px;
    line-height: 1.42857;
    color: #3f3f44;
    background-color: #fff;
    background-image: none;
    border: 1px solid #cbcbd2;
    border-radius: 4px;
    transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
  }
    .input-icon {
      position: absolute;
      bottom: 13px;
      padding-top: 0px;
      left: 12px;
      width: 18px;
      height: 18px;
      text-align: center;
      font-size: 18px;
  }
  .input-icon::after {
    content: '';
    position: absolute;
    right: -11px;
    top: -10px;
    bottom: -10px;
    width: 1px;
    opacity: .5;
    background-color: rgba(212,212,212,0);
    background-image: linear-gradient(to top,rgba(212,212,212,0) 0,#d4d4d4 30%,#d4d4d4 70%,rgba(212,212,212,0) 100%);
  }
  .btn {
    font-weight: bold;
    border-radius: 5px;
    border: none;
    padding-left: 18px;
    padding-right: 18px;
    display: block;
    width: 100%;
    padding-left: 0;
    padding-right: 0;
    padding: 10px 16px;
    font-size: 18px;
    line-height: 1.33;
    border-radius: 6px;
    color: white;
    /* background-color: ${Colors.primary}; */
    background-color: white;
    /* background: linear-gradient(left bottom,${Colors.primary},${Colors.grayMedium}); */
    background-image: -webkit-linear-gradient(left bottom,${Colors.primary75} 0, ${Colors.primary} 100%);

  }
  a.panel-footer {
    text-decoration: none;
  }
  .panel-footer {
      padding: 20px;
      display: block;
      font-size: 17px;
      color: ${Colors.grayMedium};
      font-weight: 400;
      &:hover{
        color: ${Colors.grayMedium};

      }
  }
  .panel-footer {
      background-color: #f5f5f5;
      border-top: 1px solid #ddd;
      border-bottom-right-radius: 8px;
      border-bottom-left-radius: 8px;
  }
  a.panel-footer>span {
    color: ${Colors.primary};
    text-decoration: underline;
  }
  .panel-footer a>span {
    color: ${Colors.primary};
    text-decoration: underline;
  }
  .link-external{
    color: white;
    background: 'transparent';
    margin-bottom: 20px;
    &:hover{
    color: white;
    /* text-decoration: none; */

    }
  }
`;

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background: rgba(0, 0, 0, 0.5); */
  min-height: 100%;
  z-index: 1;
`;

export const Container = styled.div`
  position: relative;
  text-align: center;
  padding-top: 60px;
  padding-bottom: 90px;
  z-index: 100;
`;

export const Logo = styled.h1`
  text-align: center;
  margin: 0 0 20px;
  img {
    height: 140px;
  }
`;

export const Content = styled.div`
  color: #fff;
  font-size: 14px;
  font-weight: 400;
`;

export const Panel = styled.div`
  color: ${Colors.primary};
  max-width: 430px;
  margin: 0 auto 20px;
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid transparent;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
  box-sizing: border-box;
  text-align: center;
  h2 {
    margin: 40px 20px 0;
    line-height: 1.5;
    font-size: 24px;
    font-weight: 200;
  }
  form {
    padding: 40px;
  }
`;
