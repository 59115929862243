import React from "react";

import { Link } from "react-router-dom";

import Nav from "../../../../components/Nav";
import { FaUserCircle } from "react-icons/fa";
import { Container } from "./styles";

function Header() {
  return (
    <Nav>
      <Container>
        <Link to="/companies">
          <FaUserCircle />
          <span>Pessoal</span>
        </Link>
      </Container>
      <Link to="/add-company" className="btn btn-outline-primary btn-sm">
        Adicionar
      </Link>
    </Nav>
  );
}

export default Header;
