import React from "react";

class Module extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
        <div>Visão geral</div>
      </>
    );
  }
}

export default Module;
