import React from "react";
import { Link } from "react-router-dom";

function index(props) {
  // console.log(props);
  return (
    <div className="nav flex-column nav-pills" id="v-pills-tab" aria-orientation="vertical">
      {/* <Link
        to={`/company/${props.company.id}/app/vales/settings`}
        className={props.path === "settings" ? "nav-link active" : "nav-link"}
      >
        Geral
      </Link> */}
      <Link
        to={`/company/${props.company.id}/app/vales/jurisdictions`}
        className={props.path === "jurisdictions" ? "nav-link active" : "nav-link"}
      >
        Alçadas
      </Link>
      <Link
        to={`/company/${props.company.id}/app/vales/statuses`}
        className={props.path === "statuses" ? "nav-link active" : "nav-link"}
      >
        Status
      </Link>
      <Link
        to={`/company/${props.company.id}/app/vales/expense-categories`}
        className={props.path === "expense-categories" ? "nav-link active" : "nav-link"}
      >
        Despesas
      </Link>
    </div>
  );
}

export default index;
