import React from "react";
import moment from "moment";
// import { useTable, usePagination } from "react-table";
// import BTable from "react-bootstrap/Table";
// import GlobalStyle from "../../../../../styles/global";
// import { Td } from "../styles";
// import "bootstrap/dist/css/bootstrap.min.css";

import { BsListCheck, BsCheckCircle, BsCircle, BsCalendar, BsXCircle } from "react-icons/bs";
import { FaRegMoneyBillAlt } from "react-icons/fa";
import { RiBankLine } from "react-icons/ri";
import { GrMoney, GrCreditCard } from "react-icons/gr";
// import { GiPayMoney } from "react-icons/gi";
import { CgCheckR, CgCloseR } from "react-icons/cg";
import { AiOutlineLike } from "react-icons/ai";
import { Colors } from "../../../../../themes";

// import clsx from "clsx";
// import { makeStyles, withStyles } from "@material-ui/core/styles";
// import Stepper from "@material-ui/core/Stepper";
// import Step from "@material-ui/core/Step";
// import StepLabel from "@material-ui/core/StepLabel";
// import Check from "@material-ui/icons/Check";

// import { makeStyles } from "@material-ui/core/styles";
import MobileStepper from "@material-ui/core/MobileStepper";

require("moment/locale/pt-br");
class List extends React.Component {
  renderAction() {
    // {item.paid === true &&
    //   item.payment_confirmated === false &&
    //   item.payment_contested === false ? (
    //   ) : null}
    //   {item.paid === true &&
    //   item.payment_confirmated === true &&
    //   item.used === false &&
    //   item.not_used === false ? (
    //   ) : null}
    //   {item.paid === true &&
    //   item.payment_confirmated === true &&
    //   item.used === true &&
    //   item.accountability_completed === false &&
    //   item.not_used === false ? (
    //   ) : null}
    //   {item.refund_process_only === true && item.accountability_completed === false ? (
    //   ) : null}
  }

  renderActionRefundWaitingAccountability(item) {
    return (
      <div className="card-body card-body-actions">
        <div
          className="btn-group"
          style={{
            width: "100%",
            fontSize: "10px",
          }}
          role="group"
        >
          {/* <button
          onClick={() => this.props.set(item)}
          data-toggle="modal"
          data-target="#AccountabilityModal"
          type="button"
          className="btn btn-secondary btn-sm"
          style={{
            paddingTop: "10px",
            paddingBottom: "10px",
            fontSize: "12px",
          }}
        >
          <AiOutlineLike
            style={{
              fontSize: 20,
              color: "#fff",
            }}
          />
          <br />
          Prestação de contas
        </button> */}
          <button
            onClick={() => this.props.showExpenseModal(item)}
            type="button"
            className="btn btn-secondary btn-sm"
            style={{
              paddingTop: "10px",
              paddingBottom: "10px",
              fontSize: "12px",
            }}
          >
            {/* <GiPayMoney
            style={{
              fontSize: 20,
              color: "#fff",
            }}
          /> */}
            <AiOutlineLike
              style={{
                fontSize: 20,
                color: "#fff",
              }}
            />
            <br />
            Prestação de contas
          </button>
        </div>
      </div>
    );
  }

  renderActionVoucherPaid(item) {
    return (
      <div className="card-body card-body-actions">
        <div
          className="btn-group"
          style={{
            width: "100%",
            fontSize: "10px",
          }}
          role="group"
        >
          <button
            onClick={() => this.props.contestPaymentItem(item)}
            data-toggle="modal"
            data-target="#ModalContestPayment"
            type="button"
            className="btn btn-danger btn-sm"
            style={{
              paddingTop: "10px",
              paddingBottom: "10px",
              fontSize: "12px",
            }}
          >
            <CgCloseR
              style={{
                fontSize: 20,
              }}
            />
            <br />
            Contestar
          </button>
          <button
            onClick={() => this.props.confirmPaymentItem(item)}
            type="button"
            className="btn btn-success btn-sm"
            style={{
              paddingTop: "10px",
              paddingBottom: "10px",
              fontSize: "12px",
            }}
          >
            <CgCheckR
              style={{
                fontSize: 20,
              }}
            />
            <br />
            Confirmar
          </button>
        </div>
      </div>
    );
  }

  renderActionVoucherPaymentConfirmated(item) {
    return (
      <div className="card-body card-body-actions">
        <div
          className="btn-group"
          style={{
            width: "100%",
            fontSize: "10px",
          }}
          role="group"
        >
          <button
            onClick={() => this.props.confirmNotUsed(item)}
            type="button"
            className="btn btn-danger btn-sm"
            style={{
              paddingTop: "10px",
              paddingBottom: "10px",
              fontSize: "12px",
            }}
          >
            <CgCloseR
              style={{
                fontSize: 20,
              }}
            />
            <br />
            Não utilizado
          </button>
          <button
            onClick={() => this.props.confirmUse(item)}
            type="button"
            className="btn btn-success btn-sm"
            style={{
              paddingTop: "10px",
              paddingBottom: "10px",
              fontSize: "12px",
            }}
          >
            <CgCheckR
              style={{
                fontSize: 20,
              }}
            />
            <br />
            Utilizado
          </button>
        </div>
      </div>
    );
  }
  renderCancel(item) {
    return (
      <div className="card-body card-body-actions">
        <div
          className="btn-group"
          style={{
            width: "100%",
            fontSize: "10px",
          }}
          role="group"
        >
          <button
            onClick={() => this.props.remove(item)}
            data-toggle="modal"
            data-target="#removeModal"
            type="button"
            className="btn btn-danger btn-sm"
            style={{
              paddingTop: "10px",
              paddingBottom: "10px",
              fontSize: "12px",
            }}
          >
            <CgCloseR
              style={{
                fontSize: 20,
              }}
            />
            <br />
            Cancelar
          </button>
        </div>
      </div>
    );
  }

  renderActionVoucherUsed(item) {
    return (
      <div className="card-body card-body-actions">
        <div
          className="btn-group"
          style={{
            width: "100%",
            fontSize: "10px",
          }}
          role="group"
        >
          {/* <button
              onClick={() => this.props.set(item)}
              data-toggle="modal"
              data-target="#AccountabilityModal"
              type="button"
              className="btn btn-secondary btn-sm"
              style={{
                paddingTop: "10px",
                paddingBottom: "10px",
                fontSize: "12px",
              }}
            >
              <AiOutlineLike
                style={{
                  fontSize: 20,
                  color: "#fff",
                }}
              />
              <br />
              Prestação de contas
            </button> */}
          <button
            onClick={() => this.props.showExpenseModal(item)}
            type="button"
            className="btn btn-secondary btn-sm"
            style={{
              paddingTop: "10px",
              paddingBottom: "10px",
              fontSize: "12px",
            }}
          >
            {/* <GiPayMoney
                style={{
                  fontSize: 20,
                  color: "#fff",
                }}
              /> */}
            <AiOutlineLike
              style={{
                fontSize: 20,
                color: "#fff",
              }}
            />
            <br />
            Prestação de contas
          </button>
        </div>
      </div>
    );
  }

  renderActions(item) {
    if (item.state.stage === "refund_waiting_accountability") {
      return this.renderActionRefundWaitingAccountability(item);
    }
    if (item.state.stage === "voucher_paid" || item.state.stage === "refund_paid") {
      return this.renderActionVoucherPaid(item);
    }
    if (item.state.stage === "voucher_payment_confirmated") {
      return this.renderActionVoucherPaymentConfirmated(item);
    }
    if (item.state.stage === "voucher_used") {
      return this.renderActionVoucherUsed(item);
    }
    if (item.state.activeStep === 0) {
      return this.renderCancel(item);
    }
  }

  render() {
    const { data } = this.props;
    return (
      <>
        {data.map((item) => {
          // console.log(item);
          // console.log("item.state.activeStep: ", item.state.activeStep);

          const { level1_staff_name, level2_staff_name } = item.approvers;
          let refund_process_only = item.refund_process_only;

          let level1_confirmed = item.level1_confirmed;
          let level1_denied = item.level1_denied;
          let level2_confirmed = item.level2_confirmed;
          let level2_denied = item.level2_denied;

          let accountability_level1_confirmed = item.accountability_level1_confirmed;
          let accountability_level1_denied = item.accountability_level1_denied;
          let accountability_level2_confirmed = item.accountability_level2_confirmed;
          let accountability_level2_denied = item.accountability_level2_denied;
          let justification =
            item.justification && item.justification !== "" ? item.justification : "";

          const { steps, activeStep } = item.state;
          const ulStepDefaultStyle = {
            display: "inline",
            float: "left",
            width: "8px",
            height: "8px",
            margin: "0 2px",
            marginTop: "4px",
            borderRadius: "50%",
            backgroundColor: "rgba(0, 0, 0, 0.26)",
          };
          const ulStepActiveStyle = {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            float: "left",
            width: "15px",
            height: "15px",
            margin: "0 2px",
            borderRadius: "50%",
            // paddingTop: "2px",
            backgroundColor: "#318AD0",
            fontSize: "10px",
          };
          const ulStepDoneStyle = {
            display: "inline",
            float: "left",
            width: "8px",
            height: "8px",
            margin: "0 2px",
            marginTop: "4px",
            borderRadius: "50%",
            backgroundColor: "#4BAF4F",
          };
          let actualStep = item.status;

          return (
            <div key={item.id} className="card col-sm-4">
              <div className="card-body">
                <a
                  href="#this"
                  onClick={() => this.props.set(item)}
                  data-toggle="modal"
                  data-target="#stepModal"
                  style={
                    {
                      // display: "inline",
                    }
                  }
                >
                  <ul>
                    {steps.map((step, index) => {
                      // console.log(step);
                      if (activeStep === index) {
                        actualStep = step;
                      }
                      let ulStepStyle = ulStepDefaultStyle;
                      if (activeStep === index) {
                        ulStepStyle = ulStepActiveStyle;
                      }
                      if (activeStep > index) {
                        ulStepStyle = ulStepDoneStyle;
                      }
                      return (
                        <li key={step} style={ulStepStyle}>
                          <div style={{ color: "#fff", width: "100%", textAlign: "center" }}>
                            {activeStep === index ? index + 1 : ""}
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                  {/* <MobileStepper
                    variant="dots"
                    steps={steps.length}
                    position="static"
                    activeStep={activeStep}
                    style={{
                      background: "transparent",
                    }}
                  /> */}
                </a>
                <br />
                <br />
                {actualStep}
              </div>
              <li className="list-group-item"></li>
              <div className="card-body">
                <h5 className="card-title">
                  {item && item.amount_refund && item.amount_refund > 0 ? "Reembolso" : "Vale"}
                  {": "}
                  {item.id}
                </h5>
                <p className="card-text">{item.description}</p>
              </div>
              <ul className="list-group list-group-flush">
                {/* <li className="list-group-item">
                  {item && item.amount && item.amount > 0 ? (
                    <span className="badge badge-secondary">Vale</span>
                  ) : null}{" "}
                  {item && item.amount_refund && item.amount_refund > 0 ? (
                    <span className="badge badge-secondary">Reembolso</span>
                  ) : null}
                </li> */}

                {item.payment_method &&
                item.payment_method === "money" &&
                item.refund_payment_method === "" ? (
                  <li className="list-group-item">
                    <FaRegMoneyBillAlt
                      style={{
                        fontSize: 17,
                        marginRight: 5,
                        width: 20,
                      }}
                    />
                    A receber em espécie
                  </li>
                ) : null}

                {item.payment_method &&
                item.payment_method === "bank" &&
                item.refund_payment_method === "" ? (
                  <li className="list-group-item">
                    <RiBankLine
                      style={{
                        fontSize: 17,
                        marginRight: 5,
                        width: 20,
                      }}
                    />
                    A receber via depósito bancário
                  </li>
                ) : null}

                {item.payment_method &&
                item.payment_method === "card" &&
                item.refund_payment_method === "" ? (
                  <li className="list-group-item">
                    <GrCreditCard
                      style={{
                        fontSize: 17,
                        marginRight: 5,
                        width: 20,
                      }}
                    />
                    A receber via cartão
                  </li>
                ) : null}

                {item.refund_payment_method && item.refund_payment_method === "money" ? (
                  <li className="list-group-item">
                    <FaRegMoneyBillAlt
                      style={{
                        fontSize: 17,
                        marginRight: 5,
                        width: 20,
                      }}
                    />
                    A receber em espécie
                  </li>
                ) : null}

                {item.refund_payment_method && item.refund_payment_method === "bank" ? (
                  <li className="list-group-item">
                    <RiBankLine
                      style={{
                        fontSize: 17,
                        marginRight: 5,
                        width: 20,
                      }}
                    />
                    A receber via depósito bancário
                  </li>
                ) : null}

                {item.refund_payment_method && item.refund_payment_method === "card" ? (
                  <li className="list-group-item">
                    <GrCreditCard
                      style={{
                        fontSize: 17,
                        marginRight: 5,
                        width: 20,
                      }}
                    />
                    A receber via cartão
                  </li>
                ) : null}

                <li className="list-group-item">
                  <BsListCheck
                    style={{
                      fontSize: 20,
                      marginRight: 5,
                      width: 20,
                    }}
                  />{" "}
                  {level2_staff_name && level2_staff_name !== "" ? "Aprovadores" : "Aprovador"}
                  <br />
                  <br />
                  {(level1_confirmed === false &&
                    level1_denied === false &&
                    refund_process_only === false) ||
                  (accountability_level1_confirmed === false &&
                    accountability_level1_denied === false &&
                    refund_process_only === true) ? (
                    <BsCircle
                      style={{
                        fontSize: 17,
                        marginRight: 5,
                        width: 20,
                      }}
                    />
                  ) : null}
                  {(level1_confirmed === true && refund_process_only === false) ||
                  (accountability_level1_confirmed === true && refund_process_only === true) ? (
                    <BsCheckCircle
                      style={{
                        fontSize: 20,
                        marginRight: 5,
                        width: 20,
                        color: Colors.greenDark,
                      }}
                    />
                  ) : null}
                  {(level1_denied === true && refund_process_only === false) ||
                  (accountability_level1_denied === true && refund_process_only === true) ? (
                    <BsXCircle
                      style={{
                        fontSize: 17,
                        marginRight: 5,
                        width: 20,
                        color: Colors.redDark,
                      }}
                    />
                  ) : null}{" "}
                  {level1_staff_name}
                  {level2_staff_name && level2_staff_name !== "" ? (
                    <>
                      <br />
                      <br />
                      {(level2_confirmed === false &&
                        level2_denied === false &&
                        refund_process_only === false) ||
                      (accountability_level2_confirmed === false &&
                        accountability_level2_denied === false &&
                        refund_process_only === true) ? (
                        <BsCircle
                          style={{
                            fontSize: 17,
                            marginRight: 5,
                            width: 20,
                          }}
                        />
                      ) : null}
                      {(level2_confirmed === true && refund_process_only === false) ||
                      (accountability_level2_confirmed === true && refund_process_only === true) ? (
                        <BsCheckCircle
                          style={{
                            fontSize: 20,
                            marginRight: 5,
                            width: 20,
                            color: Colors.greenDark,
                          }}
                        />
                      ) : null}
                      {(level2_denied === true && refund_process_only === false) ||
                      (accountability_level2_denied === true && refund_process_only === true) ? (
                        <BsXCircle
                          style={{
                            fontSize: 17,
                            marginRight: 5,
                            width: 20,
                            color: Colors.redDark,
                          }}
                        />
                      ) : null}{" "}
                      {level2_staff_name}
                    </>
                  ) : null}
                  {(level1_denied === true || level2_denied === true) && justification !== "" ? (
                    <>
                      <br />
                      <br />
                      {justification}
                    </>
                  ) : null}
                </li>
                <li className="list-group-item">
                  <BsCalendar
                    style={{
                      fontSize: 17,
                      marginRight: 5,
                      width: 20,
                    }}
                  />
                  Andamentos
                  <br />
                  <br />
                  <p>Solicitado em {moment(item.createdAt).format("LLL")}</p>
                  {item.payment_date && item.payment_date !== "" ? (
                    <p>Agendado para {moment(item.payment_date).format("LL")}</p>
                  ) : null}
                  {item.paid_at && item.paid_at !== "" ? (
                    <p>Pago em {moment(item.paid_at).format("LLL")}</p>
                  ) : null}
                  {item.done === true ? (
                    <p>Finalizado em {moment(item.updatedAt).format("LLL")}</p>
                  ) : null}
                </li>
                <li className="list-group-item">
                  <GrMoney
                    style={{
                      fontSize: 17,
                      marginRight: 5,
                      width: 20,
                    }}
                  />{" "}
                  Valores
                  {item && item.amount > 0 ? (
                    <>
                      <br />
                      <br />
                    </>
                  ) : null}
                  {item && item.amount > 0
                    ? `Solicitado: R$ ${item.amount.toFixed(2).toString().split(".").join(",")}`
                    : ""}
                  {item && item.amount_used > 0 ? (
                    <>
                      <br />
                      <br />
                    </>
                  ) : null}
                  {item && item.amount_used > 0
                    ? `Utilizado: R$ ${item.amount_used.toFixed(2).toString().split(".").join(",")}`
                    : ""}
                  {item && item.amount_refund > 0 ? (
                    <>
                      <br />
                      <br />
                    </>
                  ) : null}
                  {item && item.amount_refund > 0
                    ? `${item.amount_refund > 0 ? "Reembolso" : "Devolver"}: R$ ${item.amount_refund
                        .toFixed(2)
                        .toString()
                        .split(".")
                        .join(",")}`
                    : ""}
                </li>
              </ul>
              {this.renderActions(item)}
            </div>
          );
        })}
      </>
    );
  }
  // console.log(props);
}

export default List;
