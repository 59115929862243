import styled from "styled-components";
import { Colors } from "../../../../themes";

export const Container = styled.div`
  /* padding: 20px; */
  padding: 20px 0;
  ul {
  }
  a {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 40px;
    flex: 1 1 auto;
    min-width: 0;
    min-height: 0;
    flex-direction: row;
    display: flex;
    border-bottom: 1px solid ${Colors.primaryMedium};
    font-size: 15px;
    text-decoration: none;
    color: ${Colors.grayDark};
    &:hover {
      background-color: ${Colors.primaryLigth};
    }
  }
`;

export const IconContainer = styled.div`
  color: #f00;
  font-size: 30px;
  display: flex;
  width: 40px;
  justify-content: center;
`;

export const IconStarContainer = styled.div`
  color: ${Colors.yellowLight};
  font-size: 30px;
  display: flex;
  width: 40px;
  justify-content: center;
`;

export const Left = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const Right = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const NoCompaniesMessage = styled.h2`
  font-size: 20px;
  width: 100%;
  text-align: center;
`;
