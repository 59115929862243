import React from "react";
import moment from "moment";

import { BsCalendar, BsCheck, BsX } from "react-icons/bs";
import { GrMoney, GrCreditCard, GrUser } from "react-icons/gr";
import { FaRegMoneyBillAlt } from "react-icons/fa";
import { RiBankLine } from "react-icons/ri";

require("moment/locale/pt-br");
export default function List(props) {
  // console.log(props);
  const { data, approve, reject, showExpenseModal } = props;
  return (
    <>
      {data.map((item) => {
        return (
          <div key={item.id} className="card col-sm-4">
            <div className="card-body">
              <h5 className="card-title">
                {item && item.amount_refund && item.amount_refund > 0 ? "Reembolso" : "Vale"}
                {": "}
                {item.id}
              </h5>
              <p className="card-text">{item.description}</p>
            </div>
            <ul className="list-group list-group-flush">
              {/* <li className="list-group-item">
                {item && item.amount && item.amount > 0 ? (
                  <span className="badge badge-secondary">Vale</span>
                ) : null}{" "}
                {item && item.amount_refund && item.amount_refund > 0 ? (
                  <span className="badge badge-secondary">Reembolso</span>
                ) : null}
              </li> */}

              <li className="list-group-item">
                <GrUser
                  style={{
                    fontSize: 17,
                    marginRight: 5,
                    width: 20,
                  }}
                />
                {item.user_name}
              </li>
              {item.payment_method &&
              item.payment_method === "money" &&
              item.refund_payment_method === "" ? (
                <li className="list-group-item">
                  <FaRegMoneyBillAlt
                    style={{
                      fontSize: 17,
                      marginRight: 5,
                      width: 20,
                    }}
                  />
                  A receber em espécie
                </li>
              ) : null}

              {item.payment_method &&
              item.payment_method === "bank" &&
              item.refund_payment_method === "" ? (
                <li className="list-group-item">
                  <RiBankLine
                    style={{
                      fontSize: 17,
                      marginRight: 5,
                      width: 20,
                    }}
                  />
                  A receber via depósito bancário
                </li>
              ) : null}

              {item.payment_method &&
              item.payment_method === "card" &&
              item.refund_payment_method === "" ? (
                <li className="list-group-item">
                  <GrCreditCard
                    style={{
                      fontSize: 17,
                      marginRight: 5,
                      width: 20,
                    }}
                  />
                  A receber via cartão
                </li>
              ) : null}

              {item.refund_payment_method && item.refund_payment_method === "money" ? (
                <li className="list-group-item">
                  <FaRegMoneyBillAlt
                    style={{
                      fontSize: 17,
                      marginRight: 5,
                      width: 20,
                    }}
                  />
                  A receber em espécie
                </li>
              ) : null}

              {item.refund_payment_method && item.refund_payment_method === "bank" ? (
                <li className="list-group-item">
                  <RiBankLine
                    style={{
                      fontSize: 17,
                      marginRight: 5,
                      width: 20,
                    }}
                  />
                  A receber via depósito bancário
                </li>
              ) : null}

              {item.refund_payment_method && item.refund_payment_method === "card" ? (
                <li className="list-group-item">
                  <GrCreditCard
                    style={{
                      fontSize: 17,
                      marginRight: 5,
                      width: 20,
                    }}
                  />
                  A receber via cartão
                </li>
              ) : null}
              <li className="list-group-item">
                <BsCalendar
                  style={{
                    fontSize: 17,
                    marginRight: 5,
                    width: 20,
                  }}
                />
                {moment(item.createdAt).format("LLL")}
              </li>
              <li className="list-group-item">
                <GrMoney
                  style={{
                    fontSize: 17,
                    marginRight: 5,
                    width: 20,
                  }}
                />{" "}
                Valores
                {item && item.amount > 0 ? (
                  <>
                    <br />
                    <br />
                  </>
                ) : null}
                {item && item.amount > 0
                  ? `Solicitado: R$ ${item.amount.toFixed(2).toString().split(".").join(",")}`
                  : ""}
                {item && item.amount_used > 0 ? (
                  <>
                    <br />
                    <br />
                  </>
                ) : null}
                {item && item.amount_used > 0
                  ? `Utilizado: R$ ${item.amount_used.toFixed(2).toString().split(".").join(",")}`
                  : ""}
                {item && item.amount_refund > 0 ? (
                  <>
                    <br />
                    <br />
                  </>
                ) : null}
                {item && item.amount_refund > 0
                  ? `${
                      item.amount_refund > 0 ? "Reembolso" : "Devolver"
                    }: R$ ${item.amount_refund.toFixed(2).toString().split(".").join(",")}`
                  : ""}
              </li>
              {item.accountability_completed === true ? (
                <li className="list-group-item">
                  <button
                    onClick={() => showExpenseModal(item)}
                    type="button"
                    className="btn btn-outline-primary btn-sm"
                  >
                    Despesas
                  </button>
                </li>
              ) : null}
            </ul>
            <div className="card-body card-body-actions">
              <div
                className="btn-group"
                style={{
                  width: "100%",
                }}
                role="group"
              >
                <button
                  onClick={() => reject(item)}
                  data-toggle="modal"
                  data-target="#staticBackdropReject"
                  type="button"
                  className="btn btn-danger btn-sm"
                >
                  <BsX
                    style={{
                      fontSize: 25,
                    }}
                  />
                  <br />
                  Recusar
                </button>
                <button
                  onClick={() => approve(item)}
                  type="button"
                  className="btn btn-success btn-sm"
                >
                  <BsCheck
                    style={{
                      fontSize: 20,
                    }}
                  />
                  <br />
                  Aprovar
                </button>
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
}
