export default {
  greenLight: "#62b946",
  greenDark: "#0f823d",
  redDark: "#CC0D00",
  pinkLight: "#d61c5d",
  pinkDark: "#b84996",
  yellowLight: "#fbc102",
  yellowDark: "#f78e1d",
  blueLight: "#08c0e9",
  blueDark: "#1b6df8",
  purpleHeroku: "#9e7cc1",
  blueHeroku: "#8ecdea",
  grayDark: "#323b47",
  grayMedium: "#78818d",
  grayLigth: "#ecf1f8",

  // primary: "#323b47",
  // primaryMedium: "#d2dbe7",
  primary: "#308BD1",
  primary50: "rgba(48, 139, 209, 0.5)",
  primary60: "rgba(48, 139, 209, 0.6)",
  primary75: "rgba(48, 139, 209, 0.75)",
  primaryMedium: "rgba(48, 139, 209, 0.3)",
  primaryLigth: "rgba(48, 139, 209, 0.1)",

  // primaryLigth: "#f1faff",
  // primaryMedium: "#47C9E9",
  // primaryLigth: "#68EAF8",
};
