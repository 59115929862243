import React from "react";
// import { useTable, usePagination } from "react-table";
// import BTable from "react-bootstrap/Table";
// import GlobalStyle from "../../../../../styles/global";
import { Td } from "../styles";
// import "bootstrap/dist/css/bootstrap.min.css";
import {
  // MdError,
  // MdCheckCircle,
  MdClose,
  // MdModeEdit,
  // MdAttachMoney,
} from "react-icons/md";

// import { GrMoney } from "react-icons/gr";

export default function List(props) {
  // console.log(props);
  const { data, remove } = props;
  // Use the state and functions returned from useTable to build your UI

  // Render the UI for your table
  return (
    <>
      {data.map((item) => {
        // console.log(item);
        return (
          <tr key={item.id}>
            <Td>{`${item.number} - ${item.bank}`}</Td>
            <Td>{`${item.agency}`}</Td>
            <Td>{`${item.account}${item.digit && item.digit !== "" ? "-" : ""}${item.digit}`}</Td>
            <Td className="actions">
              {/* <button
                type="button"
                className="btn btn-link"
                onClick={() => show(item)}
                data-toggle="modal"
                data-target="#staticBackdrop"
              >
                <MdModeEdit />
              </button>{" "} */}
              <button
                type="button"
                className="btn btn-link btn-link-danger"
                onClick={() => remove(item)}
                data-toggle="modal"
                data-target="#staticBackdropRemove"
              >
                <MdClose />
              </button>
            </Td>
          </tr>
        );
      })}
    </>
  );
}
