import React, { Component } from "react";
// import { Route } from "react-router";
import { Route, Redirect } from "react-router-dom";
import axios from "axios";
// import App from "../App";
import Login from "../modules/users/pages/login";
import Recovery from "../modules/users/pages/recovery";
import Signup from "../modules/users/pages/signup";

import Account from "../modules/account";

import Companies from "../modules/companies/pages/List";
import AddCompany from "../modules/companies/pages/Add";
import Company from "../modules/companies";
import AppVales from "../modules/appVales";

export default class Main extends Component {
  // constructor(props) {
  //   super(props);
  //   // console.log(process.env);

  //   this.state = {
  //     user: JSON.parse(localStorage.getItem(process.env.REACT_APP_USER_KEY)),
  //   };
  // }

  loggedOut() {
    return (
      <>
        <Route exact path="/">
          <Redirect
            to={{
              pathname: "/login",
            }}
          />
        </Route>

        <Route path="/login/:path">
          <Login />
        </Route>
        <Route exact path="/login">
          <Login />
        </Route>
        <Route exact path="/signup">
          <Signup />
        </Route>
        <Route exact path="/recovery">
          <Recovery />
        </Route>
        {/* <Route path="*">
          <Redirect
            to={{
              pathname: "/login",
            }}
          />
        </Route> */}
      </>
    );
  }

  admin() {
    return (
      <>
        <Route exact path="/">
          <Redirect
            to={{
              pathname: "/companies",
            }}
          />
        </Route>

        <Route path="/login/:path">
          <Login />
        </Route>

        <Route exact path="/account">
          <Account />
        </Route>

        <Route path="/account/:page">
          <Account />
        </Route>

        <Route exact path="/companies">
          <Companies />
        </Route>
        <Route exact path="/add-company">
          <AddCompany />
        </Route>

        <Route path="/companies/:id/:page">
          <Company />
        </Route>
        <Route exact path="/companies/:id">
          <Company />
        </Route>

        <Route path="/company/:company_id/app/vales/:page">
          <AppVales />
        </Route>
        <Route exact path="/company/:company_id/app/vales">
          <AppVales />
        </Route>
      </>
    );
  }

  render() {
    const data = JSON.parse(localStorage.getItem(process.env.REACT_APP_USER_KEY));

    if (!data || !data.user || !data.token) {
      return this.loggedOut();
    } else {
      var token = `Bearer ${data.token}`;
      // console.log(token);
      axios.defaults.headers.common["Accept"] = "application/json";
      axios.defaults.headers.common["Content-Type"] = "application/json";
      axios.defaults.headers.common["Authorization"] = token;
      return this.admin();
    }
  }
}
