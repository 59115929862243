import React from "react";
import axios from "axios";

import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";

import { Styles, Th } from "./styles";
import List from "./List";
import Loading from "../../../../components/Loading";
import Submenu from "../../components/ConfigSubmenu";

const model = {
  id: null,
  app_vales_money_level1_staff_id: "",
  app_vales_money_level1_staff_name: "",
  app_vales_money_level1_limit: "",
  app_vales_money_level2_staff_id: "",
  app_vales_money_level2_staff_name: "",
  app_vales_bank_level1_staff_id: "",
  app_vales_bank_level1_staff_name: "",
  app_vales_bank_level1_limit: "",
  app_vales_bank_level2_staff_id: "",
  app_vales_bank_level2_staff_name: "",
  app_vales_card_level1_staff_id: "",
  app_vales_card_level1_staff_name: "",
  app_vales_card_level1_limit: "",
  app_vales_card_level2_staff_id: "",
  app_vales_card_level2_staff_name: "",
};

class Module extends React.Component {
  // const data = React.useMemo(() => makeData(115), []);
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      data: [],
      staffs: [],
      page: 1,
      qtd_per_page: 20,
      sorted: "",
      order: "asc",
      filtered: "W10=",
      pages: 0,
      model,
      app_vales_money_level1_staff: null,
      app_vales_money_level2_staff: null,
      app_vales_bank_level1_staff: null,
      app_vales_bank_level2_staff: null,
      app_vales_card_level1_staff: null,
      app_vales_card_level2_staff: null,
    };
    this.setIframeContentRef = React.createRef();
    // console.log(props);
  }

  componentDidMount() {
    this.get();
    this.getStaffs();
  }

  save() {
    const that = this;
    const data = this.state.model;
    axios
      .put(
        `${process.env.REACT_APP_API_URL}/company/${that.props.company.id}/cost-centers/${data.id}`,
        data
      )
      .then((resp) => {
        // console.log(resp);
        that.get();
        const snack = {
          open: true,
          message: "Centro de custo editado com sucesso",
          color: "success",
        };
        that.props.setSnack(snack);
        that.setState({
          loading: false,
          model,
        });
      })
      .catch((err) => {
        console.log(err);
        // loading: false,
        const snack = {
          ...that.state.snack,
          open: true,
          message:
            err.response &&
            err.response.data &&
            err.response.data.message &&
            err.response.data.message !== ""
              ? err.response.data.message
              : "Erro inesperado",
          color: "danger",
        };
        that.props.setSnack(snack);
      });
  }

  show(item) {
    const that = this;
    // console.log("show");
    // console.log(item);
    // const { id, description, department_id, role_category_id } = item;
    const {
      id,
      app_vales_money_level1_staff_id,
      app_vales_money_level1_limit,
      app_vales_money_level2_staff_id,
      app_vales_bank_level1_staff_id,
      app_vales_bank_level1_limit,
      app_vales_bank_level2_staff_id,
      app_vales_card_level1_staff_id,
      app_vales_card_level1_limit,
      app_vales_card_level2_staff_id,
    } = item;

    const app_vales_money_level1_staff_name =
      this.state.staffs.length > 0 &&
      this.state.staffs.filter((staff) => staff.id === app_vales_money_level1_staff_id).length > 0
        ? this.state.staffs.filter((staff) => staff.id === app_vales_money_level1_staff_id)[0].name
        : "";
    const app_vales_money_level1_staff =
      this.state.staffs.length > 0 &&
      this.state.staffs.filter((staff) => staff.id === app_vales_money_level1_staff_id).length > 0
        ? this.state.staffs.filter((staff) => staff.id === app_vales_money_level1_staff_id)[0]
        : null;

    const app_vales_money_level2_staff_name =
      this.state.staffs.length > 0 &&
      this.state.staffs.filter((staff) => staff.id === app_vales_money_level2_staff_id).length > 0
        ? this.state.staffs.filter((staff) => staff.id === app_vales_money_level2_staff_id)[0].name
        : "";
    const app_vales_money_level2_staff =
      this.state.staffs.length > 0 &&
      this.state.staffs.filter((staff) => staff.id === app_vales_money_level2_staff_id).length > 0
        ? this.state.staffs.filter((staff) => staff.id === app_vales_money_level2_staff_id)[0]
        : null;

    const app_vales_bank_level1_staff_name =
      this.state.staffs.length > 0 &&
      this.state.staffs.filter((staff) => staff.id === app_vales_bank_level1_staff_id).length > 0
        ? this.state.staffs.filter((staff) => staff.id === app_vales_bank_level1_staff_id)[0].name
        : "";
    const app_vales_bank_level1_staff =
      this.state.staffs.length > 0 &&
      this.state.staffs.filter((staff) => staff.id === app_vales_bank_level1_staff_id).length > 0
        ? this.state.staffs.filter((staff) => staff.id === app_vales_bank_level1_staff_id)[0]
        : null;

    const app_vales_bank_level2_staff_name =
      this.state.staffs.length > 0 &&
      this.state.staffs.filter((staff) => staff.id === app_vales_bank_level2_staff_id).length > 0
        ? this.state.staffs.filter((staff) => staff.id === app_vales_bank_level2_staff_id)[0].name
        : "";
    const app_vales_bank_level2_staff =
      this.state.staffs.length > 0 &&
      this.state.staffs.filter((staff) => staff.id === app_vales_bank_level2_staff_id).length > 0
        ? this.state.staffs.filter((staff) => staff.id === app_vales_bank_level2_staff_id)[0]
        : null;

    const app_vales_card_level1_staff_name =
      this.state.staffs.length > 0 &&
      this.state.staffs.filter((staff) => staff.id === app_vales_card_level1_staff_id).length > 0
        ? this.state.staffs.filter((staff) => staff.id === app_vales_card_level1_staff_id)[0].name
        : "";
    const app_vales_card_level1_staff =
      this.state.staffs.length > 0 &&
      this.state.staffs.filter((staff) => staff.id === app_vales_card_level1_staff_id).length > 0
        ? this.state.staffs.filter((staff) => staff.id === app_vales_card_level1_staff_id)[0]
        : null;

    const app_vales_card_level2_staff_name =
      this.state.staffs.length > 0 &&
      this.state.staffs.filter((staff) => staff.id === app_vales_card_level2_staff_id).length > 0
        ? this.state.staffs.filter((staff) => staff.id === app_vales_card_level2_staff_id)[0].name
        : "";
    const app_vales_card_level2_staff =
      this.state.staffs.length > 0 &&
      this.state.staffs.filter((staff) => staff.id === app_vales_card_level2_staff_id).length > 0
        ? this.state.staffs.filter((staff) => staff.id === app_vales_card_level2_staff_id)[0]
        : null;

    that.setState({
      ...this.state,
      model: {
        id,
        app_vales_money_level1_staff_id: app_vales_money_level1_staff_id || "",
        app_vales_money_level1_staff_name,
        app_vales_money_level1_limit: app_vales_money_level1_limit || "",
        app_vales_money_level2_staff_id: app_vales_money_level2_staff_id || "",
        app_vales_money_level2_staff_name,
        app_vales_bank_level1_staff_id: app_vales_bank_level1_staff_id || "",
        app_vales_bank_level1_staff_name,
        app_vales_bank_level1_limit: app_vales_bank_level1_limit || "",
        app_vales_bank_level2_staff_id: app_vales_bank_level2_staff_id || "",
        app_vales_bank_level2_staff_name,
        app_vales_card_level1_staff_id: app_vales_card_level1_staff_id || "",
        app_vales_card_level1_staff_name,
        app_vales_card_level1_limit: app_vales_card_level1_limit || "",
        app_vales_card_level2_staff_id: app_vales_card_level2_staff_id || "",
        app_vales_card_level2_staff_name,
      },
      app_vales_money_level1_staff,
      app_vales_money_level2_staff,
      app_vales_bank_level1_staff,
      app_vales_bank_level2_staff,
      app_vales_card_level1_staff,
      app_vales_card_level2_staff,
    });
  }

  get() {
    const that = this;
    that.setState({
      loading: true,
    });
    let { page, qtd_per_page, sorted, order, filtered } = that.state;
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/company/${that.props.company.id}/cost-centers?page=${page}&qtd_per_page=${qtd_per_page}&sorted=${sorted}&order=${order}&filtered=${filtered}`
      )
      .then((resp) => {
        // console.log(resp);
        that.setState({
          loading: false,
          data: resp.data.result,
          qtd_per_page: resp.data.limit,
          pages: resp.data.pages,
        });
      })
      .catch((err) => {
        console.log(err);
        // loading: false,
        const snack = {
          ...that.state.snack,
          open: true,
          message:
            err.response &&
            err.response.data &&
            err.response.data.message &&
            err.response.data.message !== ""
              ? err.response.data.message
              : "Erro inesperado",
          color: "danger",
        };
        that.props.setSnack(snack);
      });
  }

  getStaffs() {
    const that = this;
    // that.setState({
    //   loading: true,
    // });
    axios
      .get(`${process.env.REACT_APP_API_URL}/company/${that.props.company.id}/staffs-select`)
      .then((resp) => {
        // console.log(resp);
        that.setState({
          // loading: false,
          staffs: resp.data.result,
        });
      })
      .catch((err) => {
        console.log(err);
        // loading: false,
        const snack = {
          ...that.state.snack,
          open: true,
          message:
            err.response &&
            err.response.data &&
            err.response.data.message &&
            err.response.data.message !== ""
              ? err.response.data.message
              : "Erro inesperado",
          color: "danger",
        };
        that.props.setSnack(snack);
      });
  }

  clear() {
    this.setState({
      ...this.state,
      model,
    });
  }

  nextPage() {
    const that = this;
    let page = that.state.page + 1;
    if (page > this.state.pages) {
      page = this.state.pages;
    }
    if (page !== this.state.page) {
      that.setState({ page }, () => {
        setTimeout(() => that.get(), 10);
      });
    }
  }

  previousPage() {
    const that = this;
    let page = that.state.page - 1;
    if (page < 1) {
      page = 1;
    }
    if (page !== this.state.page) {
      that.setState({ page }, () => {
        setTimeout(() => that.get(), 10);
      });
    }
  }

  renderTable() {
    return (
      <table className="table table-hover">
        <thead>
          <tr>
            {this.state.data.length > 0 ? (
              <>
                <Th scope="col">Centro de custo</Th>
              </>
            ) : (
              <Th scope="col">Nenhum registro encontrado</Th>
            )}
            <Th className="actions" scope="col"></Th>
          </tr>
        </thead>
        <tbody>
          {this.state.loading ? (
            <tr>
              <td colSpan={2}>
                <Loading />
              </td>
            </tr>
          ) : (
            <List data={this.state.data} show={this.show.bind(this)} />
          )}
        </tbody>
      </table>
    );
  }

  renderPagination() {
    // console.log(this.state);
    const that = this;
    if (that.state.page === 1 && that.state.page === that.state.pages) {
      return null;
    }
    let pages = [];
    for (let i = 1; i <= this.state.pages; i++) {
      pages.push(i);
    }
    return (
      <div className="container">
        <div className="row">
          <div className="col-sm">
            <button
              onClick={that.previousPage.bind(this)}
              className="btn btn-light btn-md btn-block"
              disabled={that.state.page === 1}
            >
              Anterior
            </button>
          </div>
          <div className="col-sm">
            <div className="form-group">
              <select
                className="form-control"
                value={that.state.page}
                onChange={(event) =>
                  that.setState({ page: event.target.value }, () => {
                    setTimeout(() => that.get(), 10);
                  })
                }
              >
                {pages.map((actualPage) => (
                  <option key={actualPage} value={actualPage}>
                    Página {actualPage}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="col-sm">
            <div className="form-group">
              <select
                className="form-control"
                value={that.state.qtd_per_page}
                onChange={(event) =>
                  that.setState({ qtd_per_page: event.target.value, page: 1 }, () => {
                    setTimeout(() => that.get(), 10);
                  })
                }
              >
                {[5, 10, 20, 30, 40, 50].map((pageSize) => (
                  <option key={pageSize} value={pageSize}>
                    Mostrar {pageSize} linhas
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="col-sm">
            <button
              onClick={that.nextPage.bind(this)}
              className="btn btn-light btn-md btn-block"
              disabled={that.state.page === that.state.pages}
            >
              Próxima
            </button>
          </div>
        </div>
      </div>
    );
  }

  // Render forms
  renderFormMoney() {
    // console.log(this.state);
    return (
      <form>
        <div className="form-group">
          <label className="col-form-label">Aprovador da primeira alçada:</label>
          {/* <select
            className="form-control"
            value={this.state.model.app_vales_money_level1_staff_id}
            onChange={(event) =>
              this.setState({
                model: {
                  ...this.state.model,
                  app_vales_money_level1_staff_id: event.target.value,
                },
              })
            }
          >
            <option key={0} value={""}>
              Selecione
            </option>
            {this.state.staffs.map((staff) => (
              <option key={staff.id} value={staff.id}>
                {staff.name}
              </option>
            ))}
          </select> */}
          <Autocomplete
            // inputValue={this.state.model.app_vales_money_level1_staff_name}
            value={this.state.app_vales_money_level1_staff}
            options={this.state.staffs}
            onChange={(event, newValue) => {
              // console.log("newValueMoney: ", newValue);
              this.setState({
                app_vales_money_level1_staff: newValue,
                model: {
                  ...this.state.model,
                  app_vales_money_level1_staff_id: newValue && newValue.id ? newValue.id : "",
                  app_vales_money_level1_staff_name: newValue && newValue.name ? newValue.name : "",
                },
              });
            }}
            // onInputChange={(event, newInputValue) => {
            //   console.log("newInputValueMoney: ", newInputValue);
            // this.setState({
            //   model: {
            //     ...this.state.model,
            //     app_vales_money_level1_staff_name: newInputValue,
            //   },
            // });
            // }}
            getOptionLabel={(option) => {
              return option.name;
            }}
            renderInput={(params) => {
              return <TextField margin="normal" variant="outlined" {...params} label="" />;
            }}
          />
        </div>
        <div className="form-group">
          <label className="col-form-label">Limite da primeira alçada:</label>
          <input
            value={
              this.state.model.app_vales_money_level1_limit
                ? this.state.model.app_vales_money_level1_limit
                    .toFixed(2)
                    .toString()
                    .split(".")
                    .join(",")
                : "0,00"
            }
            onChange={(event) => {
              const value = event.target.value.replace(/\D/g, "");
              const app_vales_money_level1_limit = value / 100;
              this.setState({
                model: {
                  ...this.state.model,
                  app_vales_money_level1_limit,
                },
              });
            }}
            type="text"
            className="form-control"
            id="recipient-app_vales_money_level1_limit"
          />
          <small className="form-text text-muted">
            Informe 0 para tornar a primeira alçada ilimitada
          </small>
        </div>
        {this.state.model.app_vales_money_level1_limit > 0 ? (
          <>
            <div className="form-group">
              <label className="col-form-label">Aprovador da segunda alçada:</label>
              {/* <select
                className="form-control"
                value={this.state.model.app_vales_money_level2_staff_id}
                onChange={(event) =>
                  this.setState({
                    model: {
                      ...this.state.model,
                      app_vales_money_level2_staff_id: event.target.value,
                    },
                  })
                }
              >
                <option key={0} value={""}>
                  Selecione
                </option>
                {this.state.staffs.map((department) => (
                  <option key={department.id} value={department.id}>
                    {department.name}
                  </option>
                ))}
              </select> */}
              <Autocomplete
                // inputValue={this.state.model.app_vales_money_level2_staff_name}
                value={this.state.app_vales_money_level2_staff}
                options={this.state.staffs}
                onChange={(event, newValue) => {
                  // console.log("newValueMoney2: ", newValue);
                  this.setState({
                    app_vales_money_level2_staff: newValue,
                    model: {
                      ...this.state.model,
                      app_vales_money_level2_staff_id: newValue && newValue.id ? newValue.id : "",
                      app_vales_money_level2_staff_name:
                        newValue && newValue.name ? newValue.name : "",
                    },
                  });
                }}
                // onInputChange={(event, newInputValue) => {
                //   console.log("newInputValueMoney2: ", newInputValue);
                //   this.setState({
                //     model: {
                //       ...this.state.model,
                //       app_vales_money_level2_staff_name: newInputValue,
                //     },
                //   });
                // }}
                getOptionLabel={(option) => {
                  return option.name;
                }}
                renderInput={(params) => {
                  return <TextField margin="normal" variant="outlined" {...params} label="" />;
                }}
              />
            </div>
          </>
        ) : null}
      </form>
    );
  }

  renderModalMoney() {
    return (
      <div
        className="modal fade"
        id="staticBackdropMoney"
        data-backdrop="static"
        data-keyboard="false"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="staticBackdropMoneyLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="staticBackdropMoneyLabel">
                Editar alçada para pagamento em dinheiro
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => this.clear()}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">{this.renderFormMoney()}</div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
                onClick={() => this.clear()}
              >
                Cancelar
              </button>
              {!this.state.model.app_vales_money_level1_staff_id ||
              (!this.state.model.app_vales_money_level2_staff_id &&
                this.state.model.app_vales_money_level1_limit > 0) ? (
                <button
                  onClick={() => {}}
                  type="button"
                  className="btn btn-primary"
                  data-dismiss="modal"
                  disabled
                >
                  Salvar
                </button>
              ) : (
                <button
                  onClick={() => this.save()}
                  type="button"
                  className="btn btn-primary"
                  data-dismiss="modal"
                >
                  Salvar
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderFormBank() {
    return (
      <form>
        <div className="form-group">
          <label className="col-form-label">Aprovador da primeira alçada:</label>
          <Autocomplete
            // inputValue={this.state.model.app_vales_bank_level1_staff_name}
            value={this.state.app_vales_bank_level1_staff}
            options={this.state.staffs}
            onChange={(event, newValue) => {
              // console.log("newValueBank: ", newValue);
              this.setState({
                app_vales_bank_level1_staff: newValue,
                model: {
                  ...this.state.model,
                  app_vales_bank_level1_staff_id: newValue && newValue.id ? newValue.id : "",
                  app_vales_bank_level1_staff_name: newValue && newValue.name ? newValue.name : "",
                },
              });
            }}
            // onInputChange={(event, newInputValue) => {
            //   console.log("newInputValueBank: ", newInputValue);
            //   this.setState({
            //     model: {
            //       ...this.state.model,
            //       app_vales_bank_level1_staff_name: newInputValue,
            //     },
            //   });
            // }}
            getOptionLabel={(option) => {
              return option.name;
            }}
            renderInput={(params) => {
              return <TextField margin="normal" variant="outlined" {...params} label="" />;
            }}
          />
        </div>
        <div className="form-group">
          <label className="col-form-label">Limite da primeira alçada:</label>
          <input
            value={
              this.state.model.app_vales_bank_level1_limit
                ? this.state.model.app_vales_bank_level1_limit
                    .toFixed(2)
                    .toString()
                    .split(".")
                    .join(",")
                : "0,00"
            }
            onChange={(event) => {
              const value = event.target.value.replace(/\D/g, "");
              const app_vales_bank_level1_limit = value / 100;
              this.setState({
                model: {
                  ...this.state.model,
                  app_vales_bank_level1_limit,
                },
              });
            }}
            type="text"
            className="form-control"
            id="recipient-app_vales_bank_level1_limit"
          />
          <small className="form-text text-muted">
            Informe 0 para tornar a primeira alçada ilimitada
          </small>
        </div>
        {this.state.model.app_vales_bank_level1_limit > 0 ? (
          <>
            <div className="form-group">
              <label className="col-form-label">Aprovador da segunda alçada:</label>
              <Autocomplete
                // inputValue={this.state.model.app_vales_bank_level2_staff_name}
                value={this.state.app_vales_bank_level2_staff}
                options={this.state.staffs}
                onChange={(event, newValue) => {
                  // console.log("newValueBankc2: ", newValue);
                  this.setState({
                    app_vales_bank_level2_staff_name: newValue,
                    model: {
                      ...this.state.model,
                      app_vales_bank_level2_staff_id: newValue && newValue.id ? newValue.id : "",
                      app_vales_bank_level2_staff_name:
                        newValue && newValue.name ? newValue.name : "",
                    },
                  });
                }}
                // onInputChange={(event, newInputValue) => {
                //   console.log("newInputValueBank2: ", newInputValue);
                //   this.setState({
                //     model: {
                //       ...this.state.model,
                //       app_vales_bank_level2_staff_name: newInputValue,
                //     },
                //   });
                // }}
                getOptionLabel={(option) => {
                  return option.name;
                }}
                renderInput={(params) => {
                  return <TextField margin="normal" variant="outlined" {...params} label="" />;
                }}
              />
            </div>
          </>
        ) : null}
      </form>
    );
    // return (
    //   <form>
    //     <div className="form-group">
    //       <label className="col-form-label">Aprovador da primeira alçada:</label>
    //       <select
    //         className="form-control"
    //         value={this.state.model.app_vales_bank_level1_staff_id}
    //         onChange={(event) =>
    //           this.setState({
    //             model: {
    //               ...this.state.model,
    //               app_vales_bank_level1_staff_id: event.target.value,
    //             },
    //           })
    //         }
    //       >
    //         <option key={0} value={""}>
    //           Selecione
    //         </option>
    //         {this.state.staffs.map((department) => (
    //           <option key={department.id} value={department.id}>
    //             {department.name}
    //           </option>
    //         ))}
    //       </select>
    //     </div>
    //     <div className="form-group">
    //       <label className="col-form-label">Limite da primeira alçada:</label>
    //       <input
    //         value={
    //           this.state.model.app_vales_bank_level1_limit
    //             ? this.state.model.app_vales_bank_level1_limit
    //                 .toFixed(2)
    //                 .toString()
    //                 .split(".")
    //                 .join(",")
    //             : "0,00"
    //         }
    //         onChange={(event) => {
    //           const value = event.target.value.replace(/\D/g, "");
    //           const app_vales_bank_level1_limit = value / 100;
    //           this.setState({
    //             model: {
    //               ...this.state.model,
    //               app_vales_bank_level1_limit,
    //             },
    //           });
    //         }}
    //         type="text"
    //         className="form-control"
    //         id="recipient-app_vales_bank_level1_limit"
    //       />
    //       <small className="form-text text-muted">
    //         Informe 0 para tornar a primeira alçada ilimitada
    //       </small>
    //     </div>
    //     {this.state.model.app_vales_bank_level1_limit > 0 ? (
    //       <>
    //         <div className="form-group">
    //           <label className="col-form-label">Aprovador da segunda alçada:</label>
    //           <select
    //             className="form-control"
    //             value={this.state.model.app_vales_bank_level2_staff_id}
    //             onChange={(event) =>
    //               this.setState({
    //                 model: {
    //                   ...this.state.model,
    //                   app_vales_bank_level2_staff_id: event.target.value,
    //                 },
    //               })
    //             }
    //           >
    //             <option key={0} value={""}>
    //               Selecione
    //             </option>
    //             {this.state.staffs.map((department) => (
    //               <option key={department.id} value={department.id}>
    //                 {department.name}
    //               </option>
    //             ))}
    //           </select>
    //         </div>
    //       </>
    //     ) : null}
    //   </form>
    // );
  }

  renderModalBank() {
    return (
      <div
        className="modal fade"
        id="staticBackdropBank"
        data-backdrop="static"
        data-keyboard="false"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="staticBackdropBankLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="staticBackdropBankLabel">
                Editar alçada para pagamento via depósito bancário
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => this.clear()}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">{this.renderFormBank()}</div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
                onClick={() => this.clear()}
              >
                Cancelar
              </button>
              {!this.state.model.app_vales_bank_level1_staff_id ||
              (!this.state.model.app_vales_bank_level2_staff_id &&
                this.state.model.app_vales_bank_level1_limit > 0) ? (
                <button
                  onClick={() => {}}
                  type="button"
                  className="btn btn-primary"
                  data-dismiss="modal"
                  disabled
                >
                  Salvar
                </button>
              ) : (
                <button
                  onClick={() => this.save()}
                  type="button"
                  className="btn btn-primary"
                  data-dismiss="modal"
                >
                  Salvar
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderFormCard() {
    return (
      <form>
        <div className="form-group">
          <label className="col-form-label">Aprovador da primeira alçada:</label>
          <Autocomplete
            // inputValue={this.state.model.app_vales_card_level1_staff_name}
            value={this.state.app_vales_card_level1_staff}
            options={this.state.staffs}
            onChange={(event, newValue) => {
              // console.log("newValue: ", newValue);
              this.setState({
                app_vales_card_level1_staff: newValue,
                model: {
                  ...this.state.model,
                  app_vales_card_level1_staff_id: newValue && newValue.id ? newValue.id : "",
                  app_vales_card_level1_staff_name: newValue && newValue.name ? newValue.name : "",
                },
              });
            }}
            // onInputChange={(event, newInputValue) => {
            //   console.log("newInputValue: ", newInputValue);
            //   this.setState({
            //     model: {
            //       ...this.state.model,
            //       app_vales_card_level1_staff_name: newInputValue,
            //     },
            //   });
            // }}
            getOptionLabel={(option) => {
              return option.name;
            }}
            renderInput={(params) => {
              return <TextField margin="normal" variant="outlined" {...params} label="" />;
            }}
          />
        </div>
        <div className="form-group">
          <label className="col-form-label">Limite da primeira alçada:</label>
          <input
            value={
              this.state.model.app_vales_card_level1_limit
                ? this.state.model.app_vales_card_level1_limit
                    .toFixed(2)
                    .toString()
                    .split(".")
                    .join(",")
                : "0,00"
            }
            onChange={(event) => {
              const value = event.target.value.replace(/\D/g, "");
              const app_vales_card_level1_limit = value / 100;
              this.setState({
                model: {
                  ...this.state.model,
                  app_vales_card_level1_limit,
                },
              });
            }}
            type="text"
            className="form-control"
            id="recipient-app_vales_card_level1_limit"
          />
          <small className="form-text text-muted">
            Informe 0 para tornar a primeira alçada ilimitada
          </small>
        </div>
        {this.state.model.app_vales_card_level1_limit > 0 ? (
          <>
            <div className="form-group">
              <label className="col-form-label">Aprovador da segunda alçada:</label>
              <Autocomplete
                // inputValue={this.state.model.app_vales_card_level2_staff_name}
                value={this.state.app_vales_card_level2_staff}
                options={this.state.staffs}
                onChange={(event, newValue) => {
                  // console.log("newValue2: ", newValue);
                  this.setState({
                    app_vales_card_level2_staff: newValue,
                    model: {
                      ...this.state.model,
                      app_vales_card_level2_staff_id: newValue && newValue.id ? newValue.id : "",
                      app_vales_card_level2_staff_name:
                        newValue && newValue.name ? newValue.name : "",
                    },
                  });
                }}
                // onInputChange={(event, newInputValue) => {
                //   console.log("newInputValue2: ", newInputValue);
                //   this.setState({
                //     model: {
                //       ...this.state.model,
                //       app_vales_card_level2_staff_name: newInputValue,
                //     },
                //   });
                // }}
                getOptionLabel={(option) => {
                  return option.name;
                }}
                renderInput={(params) => {
                  return <TextField margin="normal" variant="outlined" {...params} label="" />;
                }}
              />
            </div>
          </>
        ) : null}
      </form>
    );
    // return (
    //   <form>
    //     <div className="form-group">
    //       <label className="col-form-label">Aprovador da primeira alçada:</label>
    //       <select
    //         className="form-control"
    //         value={this.state.model.app_vales_card_level1_staff_id}
    //         onChange={(event) =>
    //           this.setState({
    //             model: {
    //               ...this.state.model,
    //               app_vales_card_level1_staff_id: event.target.value,
    //             },
    //           })
    //         }
    //       >
    //         <option key={0} value={""}>
    //           Selecione
    //         </option>
    //         {this.state.staffs.map((department) => (
    //           <option key={department.id} value={department.id}>
    //             {department.name}
    //           </option>
    //         ))}
    //       </select>
    //     </div>
    //     <div className="form-group">
    //       <label className="col-form-label">Limite da primeira alçada:</label>

    //       <input
    //         value={
    //           this.state.model.app_vales_card_level1_limit
    //             ? this.state.model.app_vales_card_level1_limit
    //                 .toFixed(2)
    //                 .toString()
    //                 .split(".")
    //                 .join(",")
    //             : "0,00"
    //         }
    //         onChange={(event) => {
    //           const value = event.target.value.replace(/\D/g, "");
    //           const app_vales_card_level1_limit = value / 100;
    //           this.setState({
    //             model: {
    //               ...this.state.model,
    //               app_vales_card_level1_limit,
    //             },
    //           });
    //         }}
    //         type="text"
    //         className="form-control"
    //         id="recipient-app_vales_card_level1_limit"
    //       />

    //       <small className="form-text text-muted">
    //         Informe 0 para tornar a primeira alçada ilimitada
    //       </small>
    //     </div>
    //     {this.state.model.app_vales_card_level1_limit > 0 ? (
    //       <>
    //         <div className="form-group">
    //           <label className="col-form-label">Aprovador da segunda alçada:</label>
    //           <select
    //             className="form-control"
    //             value={this.state.model.app_vales_card_level2_staff_id}
    //             onChange={(event) =>
    //               this.setState({
    //                 model: {
    //                   ...this.state.model,
    //                   app_vales_card_level2_staff_id: event.target.value,
    //                 },
    //               })
    //             }
    //           >
    //             <option key={0} value={""}>
    //               Selecione
    //             </option>
    //             {this.state.staffs.map((department) => (
    //               <option key={department.id} value={department.id}>
    //                 {department.name}
    //               </option>
    //             ))}
    //           </select>
    //         </div>
    //       </>
    //     ) : null}
    //   </form>
    // );
  }

  renderModalCard() {
    return (
      <div
        className="modal fade"
        id="staticBackdropCard"
        data-backdrop="static"
        data-keyboard="false"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="staticBackdropCardLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="staticBackdropCardLabel">
                Editar alçada para pagamento via cartão
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => this.clear()}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">{this.renderFormCard()}</div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
                onClick={() => this.clear()}
              >
                Cancelar
              </button>
              {!this.state.model.app_vales_card_level1_staff_id ||
              (!this.state.model.app_vales_card_level2_staff_id &&
                this.state.model.app_vales_card_level1_limit > 0) ? (
                <button
                  onClick={() => {}}
                  type="button"
                  className="btn btn-primary"
                  data-dismiss="modal"
                  disabled
                >
                  Salvar
                </button>
              ) : (
                <button
                  onClick={() => this.save()}
                  type="button"
                  className="btn btn-primary"
                  data-dismiss="modal"
                >
                  Salvar
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
  // end render forms

  renderIframeContent() {
    return (
      <div>
        {/* <iframe
          ref={this.setIframeContentRef}
          src="http://dec.fazenda.df.gov.br/ConsultarNFCe.aspx?p=53200833200056021308650600000299071265825134%7C2%7C1%7C1%7C41EEC153AB176FED2539C05F0488D886821957BE"
          frameBorder="1"
          width="1000"
          height="500"
        ></iframe>
        <button onClick={() => this.getIframeContent()}>captura</button> */}
      </div>
    );
  }
  getIframeContent() {
    console.log(this.setIframeContentRef.current);
    console.log(this.setIframeContentRef.current.contentWindow);
    // const node =
    //   this.setIframeContentRef && this.setIframeContentRef.current.contentWindow.document.body
    //     ? this.setIframeContentRef.contentWindow
    //     : null;
    // console.log(node);
  }

  render() {
    return (
      <>
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-2">
              <Submenu path="jurisdictions" company={this.props.company} />
            </div>
            <div className="col-sm-10">
              <div>
                <Styles>
                  {this.renderTable()}
                  {this.state.data.length > 0 ? this.renderPagination() : null}
                </Styles>
              </div>

              {this.renderModalMoney()}
              {this.renderModalBank()}
              {this.renderModalCard()}
            </div>
          </div>
        </div>
      </>
      // <>
      //   <div>
      //     <Styles>
      //       {this.renderTable()}
      //       {this.state.data.length > 0 ? this.renderPagination() : null}
      //     </Styles>
      //   </div>
      //   {this.renderModalMoney()}
      //   {this.renderModalMoneyRemove()}
      // </>
    );
  }
}

export default Module;
