import React from "react";
import axios from "axios";

import { Styles, Th, ThInput } from "./styles";
import List from "./List";
import Loading from "../../../../components/Loading";
import Submenu from "../../components/Submenu";

const model = {
  id: null,
  name: "",
  email: "",
  cpf: "",
  tel: "",
  manager_id: "",
  department_id: "",
  role_id: "",
  cost_center_id: "",
};

let timerFilter = null;

class Module extends React.Component {
  // const data = React.useMemo(() => makeData(115), []);
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      data: [],
      departments: [],
      staffs: [],
      roles: [],
      costCenters: [],
      page: 1,
      qtd_per_page: 20,
      sorted: "",
      order: "asc",
      filtered: "W10=",
      filters: [
        {
          id: "name",
          value: "",
        },
      ],
      pages: 0,
      model,
    };
    // console.log(props);
  }

  componentDidMount() {
    this.get();
    this.getRoles();
    this.getDepartments();
    this.getStaffs();
    this.getCostCenters();
  }

  handleNameFilter(name) {
    clearTimeout(timerFilter);
    const that = this;
    timerFilter = setTimeout(() => {
      that.setState(
        {
          page: 1,
          filters: [
            ...this.state.filters.filter((filter) => filter.id !== "name"),
            {
              id: "name",
              value: name,
            },
          ],
        },
        () => setTimeout(() => that.get(), 10)
      );
    }, 500);
  }

  save() {
    const that = this;
    const data = this.state.model;
    if (data.id && data.id !== 0) {
      axios
        .put(
          `${process.env.REACT_APP_API_URL}/company/${that.props.company.id}/staffs/${data.id}`,
          data
        )
        .then((resp) => {
          // console.log(resp);
          that.get();
          const snack = {
            open: true,
            message: "Colaborador editado com sucesso",
            color: "success",
          };
          that.props.setSnack(snack);
          that.setState({
            loading: false,
            model,
          });
        })
        .catch((err) => {
          console.log(err);
          // loading: false,
          const snack = {
            ...that.state.snack,
            open: true,
            message:
              err.response &&
              err.response.data &&
              err.response.data.message &&
              err.response.data.message !== ""
                ? err.response.data.message
                : "Erro inesperado",
            color: "danger",
          };
          that.props.setSnack(snack);
        });
    } else {
      axios
        .post(`${process.env.REACT_APP_API_URL}/company/${that.props.company.id}/staffs`, data)
        .then((resp) => {
          // console.log(resp);
          that.get();
          const snack = {
            open: true,
            message: "Colaborador cadastrado com sucesso",
            color: "success",
          };
          that.props.setSnack(snack);
          that.setState({
            loading: false,
            model,
          });
        })
        .catch((err) => {
          console.log(err);
          // loading: false,
          const snack = {
            ...that.state.snack,
            open: true,
            message:
              err.response &&
              err.response.data &&
              err.response.data.message &&
              err.response.data.message !== ""
                ? err.response.data.message
                : "Erro inesperado",
            color: "danger",
          };
          that.props.setSnack(snack);
        });
    }
  }

  show(item) {
    const that = this;
    console.log("show");
    console.log(item);
    // const { id, manager_id, department_id } = item;
    that.setState({
      model: {
        ...item,
        name: item.User && item.User.name ? item.User.name : "",
        email: item.User && item.User.email ? item.User.email : "",
        cpf: item.User && item.User.cpf ? item.User.cpf : "",
        tel: item.User && item.User.tel ? item.User.tel : "",
        manager_id: item.manager_id ? item.manager_id : "",
        department_id: item.department_id ? item.department_id : "",
        role_id: item.role_id ? item.role_id : "",
        cost_center_id: item.cost_center_id ? item.cost_center_id : "",
      },
    });
  }

  clear() {
    setTimeout(() => {
      this.setState({
        model,
      });
    }, 500);
  }

  remove(item) {
    const { id } = item;
    // console.log(item);
    this.setState({
      model: {
        ...model,
        id,
        name: item.User && item.User.name ? item.User.name : "",
      },
    });
  }

  destroy() {
    const that = this;
    const data = this.state.model;
    if (data.id && data.id !== 0) {
      axios
        .delete(
          `${process.env.REACT_APP_API_URL}/company/${that.props.company.id}/staffs/${data.id}`
        )
        .then((resp) => {
          // console.log(resp);
          that.get();
          const snack = {
            open: true,
            message: "Colaborador removido com sucesso",
            color: "success",
          };
          that.props.setSnack(snack);
          that.setState({
            loading: false,
            model: {
              id: null,
              description: "",
              department_id: "",
              role_category_id: "",
            },
          });
        })
        .catch((err) => {
          console.log(err);
          // loading: false,
          const snack = {
            ...that.state.snack,
            open: true,
            message:
              err.response &&
              err.response.data &&
              err.response.data.message &&
              err.response.data.message !== ""
                ? err.response.data.message
                : "Erro inesperado",
            color: "danger",
          };
          that.props.setSnack(snack);
        });
    }
  }

  get() {
    const that = this;
    that.setState({
      loading: true,
    });
    let { page, qtd_per_page, sorted, order } = that.state;
    const filtered = btoa(JSON.stringify(this.state.filters));
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/company/${that.props.company.id}/staffs?page=${page}&qtd_per_page=${qtd_per_page}&sorted=${sorted}&order=${order}&filtered=${filtered}`
      )
      .then((resp) => {
        // console.log(resp);
        that.setState({
          loading: false,
          data: resp.data.result,
          qtd_per_page: resp.data.limit,
          pages: resp.data.pages,
        });
      })
      .catch((err) => {
        console.log(err);
        // loading: false,
        const snack = {
          ...that.state.snack,
          open: true,
          message:
            err.response &&
            err.response.data &&
            err.response.data.message &&
            err.response.data.message !== ""
              ? err.response.data.message
              : "Erro inesperado",
          color: "danger",
        };
        that.props.setSnack(snack);
      });
  }

  getStaffs() {
    const that = this;
    // that.setState({
    //   loading: true,
    // });
    axios
      .get(`${process.env.REACT_APP_API_URL}/company/${that.props.company.id}/staffs-select`)
      .then((resp) => {
        // console.log(resp);
        that.setState({
          // loading: false,
          staffs: resp.data.result,
        });
      })
      .catch((err) => {
        console.log(err);
        // loading: false,
        const snack = {
          ...that.state.snack,
          open: true,
          message:
            err.response &&
            err.response.data &&
            err.response.data.message &&
            err.response.data.message !== ""
              ? err.response.data.message
              : "Erro inesperado",
          color: "danger",
        };
        that.props.setSnack(snack);
      });
  }

  getDepartments() {
    const that = this;
    // that.setState({
    //   loading: true,
    // });
    axios
      .get(`${process.env.REACT_APP_API_URL}/company/${that.props.company.id}/departments-select`)
      .then((resp) => {
        // console.log(resp);
        that.setState({
          // loading: false,
          departments: resp.data.result,
        });
      })
      .catch((err) => {
        console.log(err);
        // loading: false,
        const snack = {
          ...that.state.snack,
          open: true,
          message:
            err.response &&
            err.response.data &&
            err.response.data.message &&
            err.response.data.message !== ""
              ? err.response.data.message
              : "Erro inesperado",
          color: "danger",
        };
        that.props.setSnack(snack);
      });
  }

  getRoles() {
    const that = this;
    // that.setState({
    //   loading: true,
    // });
    axios
      .get(`${process.env.REACT_APP_API_URL}/company/${that.props.company.id}/roles-select`)
      .then((resp) => {
        // console.log(resp);
        that.setState({
          // loading: false,
          roles: resp.data.result,
        });
      })
      .catch((err) => {
        console.log(err);
        // loading: false,
        const snack = {
          ...that.state.snack,
          open: true,
          message:
            err.response &&
            err.response.data &&
            err.response.data.message &&
            err.response.data.message !== ""
              ? err.response.data.message
              : "Erro inesperado",
          color: "danger",
        };
        that.props.setSnack(snack);
      });
  }

  getCostCenters() {
    const that = this;
    // that.setState({
    //   loading: true,
    // });
    axios
      .get(`${process.env.REACT_APP_API_URL}/company/${that.props.company.id}/cost-centers-select`)
      .then((resp) => {
        // console.log(resp);
        that.setState({
          // loading: false,
          costCenters: resp.data.result,
        });
      })
      .catch((err) => {
        console.log(err);
        // loading: false,
        const snack = {
          ...that.state.snack,
          open: true,
          message:
            err.response &&
            err.response.data &&
            err.response.data.message &&
            err.response.data.message !== ""
              ? err.response.data.message
              : "Erro inesperado",
          color: "danger",
        };
        that.props.setSnack(snack);
      });
  }

  nextPage() {
    const that = this;
    let page = that.state.page + 1;
    if (page > this.state.pages) {
      page = this.state.pages;
    }
    if (page !== this.state.page) {
      that.setState({ page }, () => {
        setTimeout(() => that.get(), 10);
      });
    }
  }

  previousPage() {
    const that = this;
    let page = that.state.page - 1;
    if (page < 1) {
      page = 1;
    }
    if (page !== this.state.page) {
      that.setState({ page }, () => {
        setTimeout(() => that.get(), 10);
      });
    }
  }

  renderTable() {
    return (
      <table className="table table-hover">
        <thead>
          <tr>
            {this.state.data.length > 0 ? (
              <>
                <Th scope="col">Nome</Th>
                <Th scope="col">Cargo</Th>
                <Th scope="col">Departamento</Th>
                <Th scope="col">Gerente</Th>
              </>
            ) : (
              <Th scope="col">Nenhum registro encontrado</Th>
            )}
            <Th className="actions" scope="col">
              <button
                // onClick={() => this.add()}
                className="btn btn-primary btn-sm"
                data-toggle="modal"
                data-target="#staticBackdrop"
              >
                Adicionar colaborador
              </button>
            </Th>
          </tr>
          {this.state.data.length > 0 ? (
            <tr>
              <ThInput scope="col">
                <input
                  onChange={(event) => this.handleNameFilter(event.target.value)}
                  type="text"
                  className="form-control"
                  style={{
                    marginBottom: 0,
                  }}
                  readOnly={this.state.model.id && this.state.model.id !== ""}
                />
              </ThInput>
              <ThInput scope="col"></ThInput>
              <ThInput scope="col"></ThInput>
              <ThInput scope="col"></ThInput>
              <ThInput className="actions" scope="col"></ThInput>
            </tr>
          ) : null}
        </thead>
        <tbody>
          {this.state.loading ? (
            <tr>
              <td colSpan={2}>
                <Loading />
              </td>
            </tr>
          ) : (
            <List
              data={this.state.data}
              show={this.show.bind(this)}
              remove={this.remove.bind(this)}
            />
          )}
        </tbody>
      </table>
    );
  }

  renderPagination() {
    const that = this;
    if (that.state.page === 1 && that.state.page === that.state.pages) {
      return null;
    }
    // console.log(this.state);
    let pages = [];
    for (let i = 1; i <= this.state.pages; i++) {
      pages.push(i);
    }
    return (
      <div className="container">
        <div className="row">
          <div className="col-sm">
            <button
              onClick={that.previousPage.bind(this)}
              className="btn btn-light btn-md btn-block"
              disabled={that.state.page === 1}
            >
              Anterior
            </button>
          </div>
          <div className="col-sm">
            <div className="form-group">
              <select
                className="form-control"
                value={that.state.page}
                onChange={(event) =>
                  that.setState({ page: event.target.value }, () => {
                    setTimeout(() => that.get(), 10);
                  })
                }
              >
                {pages.map((actualPage) => (
                  <option key={actualPage} value={actualPage}>
                    Página {actualPage}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="col-sm">
            <div className="form-group">
              <select
                className="form-control"
                value={that.state.qtd_per_page}
                onChange={(event) =>
                  that.setState({ qtd_per_page: event.target.value, page: 1 }, () => {
                    setTimeout(() => that.get(), 10);
                  })
                }
              >
                {[5, 10, 20, 30, 40, 50].map((pageSize) => (
                  <option key={pageSize} value={pageSize}>
                    Mostrar {pageSize} linhas
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="col-sm">
            <button
              onClick={that.nextPage.bind(this)}
              className="btn btn-light btn-md btn-block"
              disabled={that.state.page === that.state.pages}
            >
              Próxima
            </button>
          </div>
        </div>
      </div>
    );
  }

  renderForm() {
    return (
      <form>
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <label className="col-form-label">Nome:</label>
                <input
                  value={this.state.model.name}
                  onChange={(event) =>
                    this.setState({
                      model: {
                        ...this.state.model,
                        name: event.target.value,
                      },
                    })
                  }
                  type="text"
                  className="form-control"
                  readOnly={this.state.model.id && this.state.model.id !== ""}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label className="col-form-label">E-mail:</label>
                <input
                  value={this.state.model.email}
                  onChange={(event) =>
                    this.setState({
                      model: {
                        ...this.state.model,
                        email: event.target.value,
                      },
                    })
                  }
                  type="text"
                  className="form-control"
                  readOnly={this.state.model.id && this.state.model.id !== ""}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label className="col-form-label">CPF:</label>
                <input
                  value={this.state.model.cpf}
                  onChange={(event) =>
                    this.setState({
                      model: {
                        ...this.state.model,
                        cpf: event.target.value,
                      },
                    })
                  }
                  type="text"
                  className="form-control"
                  readOnly={this.state.model.id && this.state.model.id !== ""}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label className="col-form-label">Celular:</label>
                <input
                  value={this.state.model.tel}
                  onChange={(event) =>
                    this.setState({
                      model: {
                        ...this.state.model,
                        tel: event.target.value,
                      },
                    })
                  }
                  type="text"
                  className="form-control"
                  readOnly={this.state.model.id && this.state.model.id !== ""}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label className="col-form-label">Cargo:</label>
                <select
                  className="form-control"
                  value={this.state.model.role_id}
                  onChange={(event) =>
                    this.setState({
                      model: {
                        ...this.state.model,
                        role_id: event.target.value,
                      },
                    })
                  }
                >
                  <option key={0} value={""}>
                    Selecione
                  </option>
                  {this.state.roles.map((role) => (
                    <option key={role.id} value={role.id}>
                      {role.description}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label className="col-form-label">Gerente:</label>
                <select
                  className="form-control"
                  value={this.state.model.manager_id}
                  onChange={(event) =>
                    this.setState({
                      model: {
                        ...this.state.model,
                        manager_id: event.target.value,
                      },
                    })
                  }
                >
                  <option key={0} value={""}>
                    Selecione
                  </option>
                  {this.state.staffs.map((staff) => (
                    <option key={staff.id} value={staff.user_id}>
                      {staff.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label className="col-form-label">Departamento:</label>
                <select
                  className="form-control"
                  value={this.state.model.department_id}
                  onChange={(event) =>
                    this.setState({
                      model: {
                        ...this.state.model,
                        department_id: event.target.value,
                      },
                    })
                  }
                >
                  <option key={0} value={""}>
                    Selecione
                  </option>
                  {this.state.departments.map((department) => (
                    <option key={department.id} value={department.id}>
                      {department.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label className="col-form-label">Centro de custo:</label>
                <select
                  className="form-control"
                  value={this.state.model.cost_center_id}
                  onChange={(event) =>
                    this.setState({
                      model: {
                        ...this.state.model,
                        cost_center_id: event.target.value,
                      },
                    })
                  }
                >
                  <option key={0} value={""}>
                    Selecione
                  </option>
                  {this.state.costCenters.map((cost_center) => (
                    <option key={cost_center.id} value={cost_center.id}>
                      {cost_center.description}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        </div>
      </form>
    );
  }

  renderModal() {
    return (
      <div
        className="modal fade"
        id="staticBackdrop"
        data-backdrop="static"
        data-keyboard="false"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="staticBackdropLabel">
                {this.state.model.id && this.state.model.id !== 0
                  ? "Editar colaborador"
                  : "Adicionar novo colaborador"}
              </h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">{this.renderForm()}</div>
            <div className="modal-footer">
              <button
                onClick={() => this.clear()}
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                Cancelar
              </button>
              <button
                onClick={() => this.save()}
                type="button"
                className="btn btn-primary"
                data-dismiss="modal"
              >
                Salvar
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderModalRemove() {
    // console.log(this.state);
    return (
      <div
        className="modal fade"
        id="staticBackdropRemove"
        data-backdrop="static"
        data-keyboard="false"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="staticBackdropLabel">
                {` `}
              </h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              {`Tem certeza que deseja remover (${this.state.model.name})?`}
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-dismiss="modal">
                Cancelar
              </button>
              <button
                onClick={() => this.destroy()}
                type="button"
                className="btn btn-danger"
                data-dismiss="modal"
              >
                Confirmar
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <>
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-2">
              <Submenu path="staffs" company={this.props.company} />
            </div>
            <div className="col-sm-10">
              <div>
                <Styles>
                  {this.renderTable()}
                  {this.state.data.length > 0 ? this.renderPagination() : null}
                </Styles>
              </div>
              {this.renderModal()}
              {this.renderModalRemove()}
            </div>
          </div>
        </div>
      </>
    );
  }

  // render() {
  //   return (
  //     <>
  //       <div>
  //         <Styles>
  //           {this.renderTable()}
  //           {this.state.data.length > 0 ? this.renderPagination() : null}
  //         </Styles>
  //       </div>
  //       {this.renderModal()}
  //       {this.renderModalRemove()}
  //     </>
  //   );
  // }
}

export default Module;
