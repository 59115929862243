import React from "react";
import { withRouter, Redirect } from "react-router-dom";
import axios from "axios";
// import logo from "./logo.svg";

import GlobalStyle from "../../styles/global";
import Header from "../../components/Header";
import Nav from "./components/NavDetails";
import Menu from "./components/Menu";

import Snackbars from "../../components/Snackbars";
import AddAlert from "@material-ui/icons/AddAlert";

import CompanyOverview from "./pages/Overview";
import CompanyRoleCategories from "./pages/RoleCategories";
import CompanyRoles from "./pages/Roles";
import CompanyDepartments from "./pages/Departments";
import CompanyStaffs from "./pages/Staffs";
import CompanyAccess from "./pages/Access";
import CompanyApps from "./pages/Apps";
import CompanySettings from "./pages/Settings";
import CompanyCostCenters from "./pages/CostCenters";

const company = {
  id: 0,
  name: "",
  cnpj: "",
  frozen: false,
  maintenance: false,
};

class Module extends React.Component {
  constructor(props) {
    super(props);
    let { id } = this.props.match.params;
    id = parseInt(id);
    this.state = {
      loading: true,
      id,
      company,
      snack: {
        open: false,
        message: "",
        color: "success",
      },
    };

    // console.log(props);
  }

  setSnack(snack) {
    this.setState({ snack });
  }

  componentDidMount() {
    this.getCompany();
  }

  getCompany() {
    const that = this;
    that.setState({
      loading: true,
    });
    axios
      .get(`${process.env.REACT_APP_API_URL}/companies/${that.state.id}`)
      .then((resp) => {
        // console.log(resp);
        that.setState({
          loading: false,
          company: resp.data,
        });
      })
      .catch((err) => {
        console.log(err);
        that.setState({
          loading: false,
          snack: {
            ...that.state.snack,
            open: true,
            message:
              err.response &&
              err.response.data &&
              err.response.data.message &&
              err.response.data.message !== ""
                ? err.response.data.message
                : "Erro inesperado",
            color: "danger",
          },
        });
      });
  }

  renderContent() {
    if (
      !this.state.company ||
      !this.state.company.name ||
      this.state.company.name === ""
    ) {
      return null;
    }
    /*
    import CompanyOverview from "../modules/companies/pages/Overview";
    import CompanyRoleCategories from "../modules/companies/pages/RoleCategories";
    import CompanyRoles from "../modules/companies/pages/Roles";
    import CompanyDepartments from "../modules/companies/pages/Departments";
    import CompanyStaffs from "../modules/companies/pages/Staffs";
    import CompanyAccess from "../modules/companies/pages/Access";
    import CompanyResources from "../modules/companies/pages/Resources";
    import CompanySettings from "../modules/companies/pages/Settings";
    */
    const page = this.props.match.params.page;
    const props = {
      company: this.state.company,
      getCompany: this.getCompany.bind(this),
      setSnack: this.setSnack.bind(this),
    };
    return (
      <>
        <Menu company={this.state.company} path={page} />

        {page === "overview" ? <CompanyOverview {...props} /> : null}
        {page === "role-categories" ? (
          <CompanyRoleCategories {...props} />
        ) : null}
        {page === "roles" ? <CompanyRoles {...props} /> : null}
        {page === "departments" ? <CompanyDepartments {...props} /> : null}
        {page === "staffs" ? <CompanyStaffs {...props} /> : null}
        {page === "access" ? <CompanyAccess {...props} /> : null}
        {page === "apps" ? <CompanyApps {...props} /> : null}
        {page === "settings" ? <CompanySettings {...props} /> : null}
        {page === "cost-centers" ? <CompanyCostCenters {...props} /> : null}
      </>
    );
  }

  render() {
    if (isNaN(this.state.id) || this.state.id === 0) {
      return <Redirect to={"/"} />;
    }
    if (!this.props.match.params.page || this.props.match.params.page === "") {
      return <Redirect to={`/companies/${this.state.id}/apps`} />;
    }
    return (
      <>
        <Snackbars
          place="tr"
          color={this.state.snack.color}
          icon={AddAlert}
          message={this.state.snack.message}
          open={this.state.snack.open}
          closeNotification={() =>
            this.setState({
              snack: { ...this.state.snack, open: false },
            })
          }
          close
        />
        <Header />
        <Nav company={this.state.company} loading={this.state.loading} />
        {this.state.loading === false ? this.renderContent() : null}
        <GlobalStyle />
      </>
    );
  }
}

export default withRouter(Module);
