import React from "react";
import ReactDOM from "react-dom";
// import App from "./App";
import {
  // BrowserRouter as Router,
  Router,
  Switch,
} from "react-router-dom";

import { createBrowserHistory } from "history";
import * as serviceWorker from "./serviceWorker";

import Main from "./routes/index.js";

const hist = createBrowserHistory();

ReactDOM.render(
  <Router history={hist}>
    <Switch>
      <Main />
    </Switch>
  </Router>,
  document.getElementById("root")
);
// ReactDOM.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>,
//   document.getElementById("root")
// );

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
