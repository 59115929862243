import React from "react";

import { Container, Nav } from "./styles";

class Header extends React.Component {
  render() {
    return (
      <Container>
        <Nav>{this.props.children}</Nav>
      </Container>
    );
  }
}

export default Header;
