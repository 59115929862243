import React from "react";
import axios from "axios";
// import logo from "./logo.svg";
import Loading from "../../../../components/Loading";

import { MaintenanceIcon } from "./styles";

import { BsToggleOff, BsToggleOn } from "react-icons/bs";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
// import Submenu from "../../components/Submenu";

class Module extends React.Component {
  constructor(props) {
    super(props);
    const userData = JSON.parse(localStorage.getItem(process.env.REACT_APP_USER_KEY));
    const { user } = userData;
    // console.log(props);
    this.state = {
      loading: false,
      // open: false,
      name: user.name || "",
      email: user.email || "",
      tel: user.tel || "",
      cpf: user.cpf || "",
      email_notification: user.email_notification || "",
      app_push_notification: user.app_push_notification || "",
      actual_pass: "",
      new_pass: "",
      confirm_new_pass: "",
      show_actual_pass: false,
      show_new_pass: false,
      show_confirm_new_pass: false,
      // cnpj: props.company.cnpj || "",
      // maintenance: props.company.maintenance || "",
      // logo: props.company.logo || "",
      // app_bg: props.company.app_bg || "",
      // color_r: props.company.color_r || "",
      // color_g: props.company.color_g || "",
      // color_b: props.company.color_b || "",
      // file: null,
      // fileBgApp: null,
      // logoPreviewUrl: props.company.logo || "",
      // app_bgPreviewUrl: props.company.app_bg || "",
      // uploadingLogo: false,
      // uploadingAppBg: false,
      // uploadingLogoValuenow: 0,
    };
  }

  send() {
    const that = this;
    if (this.state.new_pass !== this.state.confirm_new_pass) {
      const snack = {
        open: true,
        message: "A senha está diferente da confirmação!",
        color: "danger",
      };
      that.props.setSnack(snack);
      return false;
    }
    this.setState({
      loading: true,
    });
    // console.log(that.state);
    // return;

    const { name, email, tel, cpf, actual_pass, new_pass, confirm_new_pass } = this.state;
    const data = {
      name,
      email,
      tel,
      cpf,
      actual_pass,
      new_pass,
      confirm_new_pass,
    };
    // console.log(data);
    // if (new_pass && new_pass !== "") {
    //   data.password = new_pass;
    // }
    axios
      .put(`${process.env.REACT_APP_API_URL}/users/${that.props.user.id}`, data)
      .then((resp) => {
        // console.log(resp.data);

        const userData = JSON.parse(localStorage.getItem(process.env.REACT_APP_USER_KEY));
        const { user } = userData;
        // console.log(user);
        // console.log(resp);

        var newData = {
          ...userData,
          user: {
            ...user,
            name: resp.data.name || user.name,
            email: resp.data.email || user.email,
            tel: resp.data.tel || user.tel,
            cpf: resp.data.cpf || user.cpf,
            email_notification:
              resp.data.email_notification !== undefined
                ? resp.data.email_notification
                : user.email_notification,
            app_push_notification:
              resp.data.app_push_notification !== undefined
                ? resp.data.app_push_notification
                : user.app_push_notification,
          },
        };
        // console.log(newData);
        // var newData = { ...resp.data, user: resp.data };

        // console.log(newData);
        localStorage.setItem(process.env.REACT_APP_USER_KEY, JSON.stringify(newData));

        that.setState({
          loading: false,
          name: resp.data.name || "",
          email: resp.data.email || "",
          tel: resp.data.tel || "",
          cpf: resp.data.cpf || "",
          actual_pass: "",
          new_pass: "",
          confirm_new_pass: "",
          show_actual_pass: false,
          show_new_pass: false,
          show_confirm_new_pass: false,
        });
        const snack = {
          open: true,
          message: "Dados atualizados com sucesso.",
          color: "success",
        };
        that.props.setSnack(snack);
        // that.props.getCompany();
      })
      .catch((err) => {
        // console.log(err);
        const snack = {
          open: true,
          message:
            err.response &&
            err.response.data &&
            err.response.data.message &&
            err.response.data.message !== ""
              ? err.response.data.message
              : "Erro inesperado",
          color: "danger",
        };
        that.props.setSnack(snack);
        that.setState({
          loading: false,
          // name: this.props.company.name,
          // cnpj: this.props.company.cnpj,
        });
      });
    // console.log(that.state);
  }

  // removeLogo() {
  //   const that = this;
  //   this.setState({
  //     loading: true,
  //   });
  //   // console.log(that.state);
  //   // return;
  //   axios
  //     .put(`${process.env.REACT_APP_API_URL}/companies/${that.props.company.id}/remove-logo`)
  //     .then((resp) => {
  //       // console.log(resp);
  //       const snack = {
  //         open: true,
  //         message: "Logo removida com sucesso.",
  //         color: "success",
  //       };
  //       that.setState({
  //         loading: false,
  //         logoPreviewUrl: null,
  //       });
  //       that.props.setSnack(snack);
  //       that.props.getCompany();
  //     })
  //     .catch((err) => {
  //       // console.log(err);
  //       const snack = {
  //         open: true,
  //         message:
  //           err.response &&
  //           err.response.data &&
  //           err.response.data.message &&
  //           err.response.data.message !== ""
  //             ? err.response.data.message
  //             : "Erro inesperado",
  //         color: "danger",
  //       };
  //       that.props.setSnack(snack);
  //       that.setState({
  //         loading: false,
  //         name: this.props.company.name,
  //         cnpj: this.props.company.cnpj,
  //       });
  //     });
  //   // console.log(that.state);
  // }

  // removeAppBg() {
  //   const that = this;
  //   this.setState({
  //     loading: true,
  //   });
  //   // console.log(that.state);
  //   // return;
  //   axios
  //     .put(`${process.env.REACT_APP_API_URL}/companies/${that.props.company.id}/remove-appbg`)
  //     .then((resp) => {
  //       // console.log(resp);
  //       const snack = {
  //         open: true,
  //         message: "Background do app removido com sucesso.",
  //         color: "success",
  //       };
  //       that.setState({
  //         loading: false,
  //         logoPreviewUrl: null,
  //       });
  //       that.props.setSnack(snack);
  //       that.props.getCompany();
  //     })
  //     .catch((err) => {
  //       // console.log(err);
  //       const snack = {
  //         open: true,
  //         message:
  //           err.response &&
  //           err.response.data &&
  //           err.response.data.message &&
  //           err.response.data.message !== ""
  //             ? err.response.data.message
  //             : "Erro inesperado",
  //         color: "danger",
  //       };
  //       that.props.setSnack(snack);
  //       that.setState({
  //         loading: false,
  //         name: this.props.company.name,
  //         cnpj: this.props.company.cnpj,
  //       });
  //     });
  //   // console.log(that.state);
  // }

  renderForm() {
    return (
      <form>
        <label>Nome</label>
        <input
          autoFocus={true}
          className="form-control"
          value={this.state.name}
          onChange={(event) => this.setState({ name: event.target.value })}
          placeholder="Nome"
          type="text"
        />
        <label>E-mail</label>
        <input
          className="form-control"
          value={this.state.email}
          onChange={(event) => this.setState({ email: event.target.value })}
          placeholder="E-mail"
          type="text"
        />
        <label>Telefone</label>
        <input
          className="form-control"
          value={this.state.tel}
          onChange={(event) => this.setState({ tel: event.target.value })}
          placeholder="Telefone"
          type="text"
        />
        <label>CPF</label>
        <input
          className="form-control"
          value={this.state.cpf}
          onChange={(event) => this.setState({ cpf: event.target.value })}
          placeholder="CPF"
          type="text"
        />
        <button onClick={this.send.bind(this)} type="button" className="btn btn-primary btn-sm">
          Salvar
        </button>
      </form>
    );
  }

  renderPassForm() {
    // show_actual_pass: false,
    // show_new_pass: false,
    // show_confirm_new_pass: false,
    // import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
    return (
      <form>
        <label>Senha atual</label>
        <div className="input-group mb-2 mr-sm-2">
          <input
            className="form-control"
            value={this.state.actual_pass}
            onChange={(event) => this.setState({ actual_pass: event.target.value })}
            placeholder=""
            type={this.state.show_actual_pass ? "text" : "password"}
          />
          <div className="input-group-append">
            <button
              type="button"
              onClick={() => this.setState({ show_actual_pass: !this.state.show_actual_pass })}
              className="input-group-text"
            >
              {this.state.show_actual_pass ? <AiOutlineEye /> : <AiOutlineEyeInvisible />}
            </button>
          </div>
        </div>
        <label>Nova senha</label>
        <div className="input-group mb-2 mr-sm-2">
          <input
            className="form-control"
            value={this.state.new_pass}
            onChange={(event) => this.setState({ new_pass: event.target.value })}
            placeholder=""
            type={this.state.show_new_pass ? "text" : "password"}
          />
          <div className="input-group-append">
            <button
              type="button"
              onClick={() => this.setState({ show_new_pass: !this.state.show_new_pass })}
              className="input-group-text"
            >
              {this.state.show_new_pass ? <AiOutlineEye /> : <AiOutlineEyeInvisible />}
            </button>
          </div>
        </div>
        <label>Confirmar nova senha</label>
        <div className="input-group mb-2 mr-sm-2">
          <input
            className="form-control"
            value={this.state.confirm_new_pass}
            onChange={(event) => this.setState({ confirm_new_pass: event.target.value })}
            placeholder=""
            type={this.state.show_confirm_new_pass ? "text" : "password"}
          />
          <div className="input-group-append">
            <button
              type="button"
              onClick={() =>
                this.setState({ show_confirm_new_pass: !this.state.show_confirm_new_pass })
              }
              className="input-group-text"
            >
              {this.state.show_confirm_new_pass ? <AiOutlineEye /> : <AiOutlineEyeInvisible />}
            </button>
          </div>
        </div>
        <button onClick={this.send.bind(this)} type="button" className="btn btn-primary btn-sm">
          Salvar
        </button>
      </form>
    );
  }

  // renderFormColor() {
  //   return (
  //     <form>
  //       <div className="form-row">
  //         <div className="form-group col-md-3">
  //           <label>R (Vermelho)</label>
  //           <input
  //             className="form-control"
  //             value={this.state.color_r}
  //             max="255"
  //             min="0"
  //             onChange={(event) => this.setState({ color_r: event.target.value })}
  //             placeholder="R"
  //             type="number"
  //           />
  //         </div>
  //         <div className="form-group col-md-3">
  //           <label>G (Verde)</label>
  //           <input
  //             className="form-control"
  //             value={this.state.color_g}
  //             max="255"
  //             min="0"
  //             onChange={(event) => this.setState({ color_g: event.target.value })}
  //             placeholder="G"
  //             type="number"
  //           />
  //         </div>
  //         <div className="form-group col-md-3">
  //           <label>B (Azul)</label>
  //           <input
  //             className="form-control"
  //             value={this.state.color_b}
  //             max="255"
  //             min="0"
  //             onChange={(event) => this.setState({ color_b: event.target.value })}
  //             placeholder="B"
  //             type="number"
  //           />
  //         </div>
  //         <div className="form-group col-md-3">
  //           <label>Cor</label>
  //           <input
  //             style={{
  //               background: `rgb(${this.state.color_r},${this.state.color_g},${this.state.color_b})`,
  //             }}
  //             className="form-control"
  //             value=""
  //             onChange={(event) => {}}
  //             placeholder=""
  //             type="button"
  //           />
  //         </div>
  //       </div>
  //       <button onClick={this.send.bind(this)} type="button" className="btn btn-primary btn-sm">
  //         Salvar
  //       </button>
  //     </form>
  //   );
  // }

  // renderColors() {
  //   return (
  //     <div className="row">
  //       <div className="col-sm-4">
  //         <div className="section-title">Cor padrão</div>
  //       </div>
  //       <div className="col-sm-8">{this.renderFormColor()}</div>
  //     </div>
  //   );
  // }

  renderInfos() {
    return (
      <div className="row">
        <div className="col-sm-4">
          <div className="section-title">Informações</div>
          Seu endereço de e-mail é sua identificação no Hubera e é utilizado para logar
        </div>
        <div className="col-sm-8">{this.renderForm()}</div>
      </div>
    );
  }

  renderPass() {
    return (
      <div className="row">
        <div className="col-sm-4">
          <div className="section-title">Senha</div>
        </div>
        <div className="col-sm-8">{this.renderPassForm()}</div>
      </div>
    );
  }

  // app_bgSelectedHandler(e) {
  //   e.preventDefault();

  //   // console.log(e.target.files[0]);

  //   let reader = new FileReader();
  //   let fileBgApp = e.target.files[0];

  //   if (fileBgApp.type !== "image/png" && fileBgApp.type !== "image/jpeg") {
  //     const snack = {
  //       open: true,
  //       message: "Imagem com formato inválido",
  //       color: "danger",
  //     };
  //     this.props.setSnack(snack);
  //   } else {
  //     reader.onloadend = () => {
  //       this.setState({
  //         fileBgApp: fileBgApp,
  //         app_bgPreviewUrl: reader.result,
  //       });
  //       // console.log(reader.result);
  //       // this.props.getPicture(reader.result);
  //     };
  //     reader.readAsDataURL(fileBgApp);

  //     // const i = new Image();
  //     // i.onload = function () {
  //     //   console.log(i.width, i.height);
  //     // };
  //     // i.src = reader.result;

  //     // console.log(file.offsetHeight);
  //     // console.log(reader);
  //   }
  // }

  // logoSelectedHandler(e) {
  //   e.preventDefault();

  //   // console.log(e.target.files[0]);

  //   let reader = new FileReader();
  //   let file = e.target.files[0];

  //   if (file.type !== "image/png" && file.type !== "image/jpeg") {
  //     const snack = {
  //       open: true,
  //       message: "Imagem com formato inválido",
  //       color: "danger",
  //     };
  //     this.props.setSnack(snack);
  //   } else {
  //     reader.onloadend = () => {
  //       this.setState({
  //         file: file,
  //         logoPreviewUrl: reader.result,
  //       });
  //       // console.log(reader.result);
  //       // this.props.getPicture(reader.result);
  //     };
  //     reader.readAsDataURL(file);

  //     // const i = new Image();
  //     // i.onload = function () {
  //     //   console.log(i.width, i.height);
  //     // };
  //     // i.src = reader.result;

  //     // console.log(file.offsetHeight);
  //     // console.log(reader);
  //   }
  // }

  // removeLogo() {
  //   const that = this;
  //   this.logoInput.value = "";
  //   this.setState({ logoPreviewUrl: "" }, () =>
  //     setTimeout(() => that.send(), 10)
  //   );
  // }

  // onLogoLoad({ target: img }) {
  //   // console.log(img.offsetWidth);
  //   // console.log(img.offsetHeight);
  //   // console.log(img.naturalWidth);
  //   // console.log(img.naturalHeight);

  //   // if (
  //   //   (img.naturalWidth < 180 && img.naturalWidth > 220) ||
  //   //   img.naturalHeight < 180 ||
  //   //   img.naturalHeight > 220
  //   // ) {
  //   // this.setState({ logoPreviewUrl: null });
  //   // const snack = {
  //   //   open: true,
  //   //   message: "Imagem com dimensões inválidas",
  //   //   color: "danger",
  //   // };
  //   // this.props.setSnack(snack);
  //   // } else {
  //   if (this.logoInput.value !== "") {
  //     this.setState({ uploadingLogo: true, uploadingLogoValuenow: 0 });
  //     console.log("upload");
  //     this.send();
  //   }
  //   // }
  // }

  // onBgAppLoad({ target: img }) {
  //   // console.log(img.offsetWidth);
  //   // console.log(img.offsetHeight);
  //   // console.log(img.naturalWidth);
  //   // console.log(img.naturalHeight);
  //   // console.log(this.app_bgInput.value);
  //   // if (
  //   //   (img.naturalWidth < 700 && img.naturalWidth > 800) ||
  //   //   img.naturalHeight < 1200 ||
  //   //   img.naturalHeight > 1450
  //   // ) {
  //   //   this.setState({ app_bgPreviewUrl: null });
  //   //   this.app_bgInput.value = "";
  //   //   const snack = {
  //   //     open: true,
  //   //     message: "Imagem com dimensões inválidas",
  //   //     color: "danger",
  //   //   };
  //   //   this.props.setSnack(snack);
  //   // } else {
  //   if (this.app_bgInput.value !== "") {
  //     this.setState({ uploadingAppBg: true, uploadingAppBgValuenow: 0 });
  //     // console.log("upload");
  //     this.send();
  //   }
  //   // }
  // }

  // renderLogo() {
  //   return (
  //     <div className="row">
  //       <div className="col-sm-4">
  //         <div className="section-title">Logo</div>A imagem para logo deve ser no formato PNG ou JPG
  //         com as dimensões de 200px X 200px
  //       </div>
  //       <div className="col-sm-8">
  //         <input
  //           style={{ display: "none" }}
  //           type="file"
  //           onChange={this.logoSelectedHandler.bind(this)}
  //           ref={(logoInput) => (this.logoInput = logoInput)}
  //         />
  //         {this.state.logoPreviewUrl === null || this.state.logoPreviewUrl === "" ? (
  //           <button className="btn btn-primary btn-sm" onClick={() => this.logoInput.click()}>
  //             Adicionar Logo
  //           </button>
  //         ) : (
  //           <>
  //             <img
  //               onLoad={this.onLogoLoad.bind(this)}
  //               width="200"
  //               src={this.state.logoPreviewUrl}
  //               alt=""
  //             />
  //             <br />
  //             <br />
  //             {this.state.uploadingLogo ? (
  //               <>
  //                 <div className="progress">
  //                   <div
  //                     className="progress-bar"
  //                     role="progressbar"
  //                     style={{ width: `${this.state.uploadingLogoValuenow}%` }}
  //                     aria-valuenow={this.state.uploadingLogoValuenow}
  //                     aria-valuemin="0"
  //                     aria-valuemax="100"
  //                   >
  //                     {`${this.state.uploadingLogoValuenow}%`}
  //                   </div>
  //                 </div>
  //                 <br />
  //                 <br />
  //               </>
  //             ) : (
  //               <button className="btn btn-danger btn-sm" onClick={this.removeLogo.bind(this)}>
  //                 Remover logo
  //               </button>
  //             )}
  //           </>
  //         )}
  //       </div>
  //     </div>
  //   );
  // }

  // renderAppBg() {
  //   return (
  //     <div className="row">
  //       <div className="col-sm-4">
  //         <div className="section-title">Background do aplicativo</div>A imagem para o background do
  //         aplicativo deve ser no formato PNG ou JPG com as dimensões de 770px X 1400px
  //       </div>
  //       <div className="col-sm-8">
  //         <input
  //           style={{ display: "none" }}
  //           type="file"
  //           onChange={this.app_bgSelectedHandler.bind(this)}
  //           ref={(app_bgInput) => (this.app_bgInput = app_bgInput)}
  //         />
  //         {this.state.app_bgPreviewUrl === null || this.state.app_bgPreviewUrl === "" ? (
  //           <button className="btn btn-primary btn-sm" onClick={() => this.app_bgInput.click()}>
  //             Adicionar Background do App
  //           </button>
  //         ) : (
  //           <>
  //             <img
  //               onLoad={this.onBgAppLoad.bind(this)}
  //               width="200"
  //               src={this.state.app_bgPreviewUrl}
  //               alt=""
  //             />
  //             <br />
  //             <br />
  //             {this.state.uploadingAppBg ? (
  //               <>
  //                 <div className="progress">
  //                   <div
  //                     className="progress-bar"
  //                     role="progressbar"
  //                     style={{ width: `${this.state.uploadingAppBgValuenow}%` }}
  //                     aria-valuenow={this.state.uploadingAppBgValuenow}
  //                     aria-valuemin="0"
  //                     aria-valuemax="100"
  //                   >
  //                     {`${this.state.uploadingAppBgValuenow}%`}
  //                   </div>
  //                 </div>
  //                 <br />
  //                 <br />
  //               </>
  //             ) : (
  //               <button className="btn btn-danger btn-sm" onClick={this.removeAppBg.bind(this)}>
  //                 Remover Background do App
  //               </button>
  //             )}
  //           </>
  //         )}
  //       </div>
  //     </div>
  //   );
  // }

  // renderAppBg() {
  //   return (
  //     <div className="row">
  //       <div className="col-sm-4">
  //         <div className="section-title">Background do aplicativo</div>A imagem
  //         para logo deve ser no formato PNG com as dimensões de 770px X 1400px
  //       </div>
  //       <div className="col-sm-8"></div>
  //     </div>
  //   );
  // }

  // renderTranfer() {
  //   return (
  //     <div className="row">
  //       <div className="col-sm-4">
  //         <div className="section-title">Transferir propriedade</div>
  //         Transferir essa empresa para outro administrador
  //       </div>
  //       <div className="col-sm-8"></div>
  //     </div>
  //   );
  // }

  setEmailNotificationOn() {
    const that = this;
    that.setState(
      {
        email_notification: true,
      },
      () => setTimeout(() => that.send(), 10)
    );
  }

  setEmailNotificationOff() {
    const that = this;
    that.setState(
      {
        email_notification: false,
      },
      () => setTimeout(() => that.send(), 10)
    );
  }

  setAppPushNotificationOn() {
    const that = this;
    that.setState(
      {
        app_push_notification: true,
      },
      () => setTimeout(() => that.send(), 10)
    );
  }
  setAppPushNotificationOff() {
    const that = this;
    that.setState(
      {
        app_push_notification: false,
      },
      () => setTimeout(() => that.send(), 10)
    );
  }
  renderNotifications() {
    // console.log(this.props);
    return (
      <div className="row">
        <div className="col-sm-4">
          <div className="section-title">Notificações</div>
        </div>
        <div className="col-sm-8">
          <div className="row">
            <table>
              <tbody>
                {this.state.email_notification === true ? (
                  <tr>
                    <td
                      style={{
                        width: "70px",
                      }}
                    >
                      <MaintenanceIcon onClick={this.setEmailNotificationOff.bind(this)}>
                        <BsToggleOn />
                      </MaintenanceIcon>
                    </td>
                    <td>Notificação por e-mail</td>
                  </tr>
                ) : (
                  <tr>
                    <td
                      style={{
                        width: "70px",
                      }}
                    >
                      <MaintenanceIcon onClick={this.setEmailNotificationOn.bind(this)}>
                        <BsToggleOff />
                      </MaintenanceIcon>
                    </td>
                    <td>Notificação por e-mail</td>
                  </tr>
                )}
                {this.state.app_push_notification === true ? (
                  <tr>
                    <td
                      style={{
                        width: "70px",
                      }}
                    >
                      <MaintenanceIcon onClick={this.setAppPushNotificationOff.bind(this)}>
                        <BsToggleOn />
                      </MaintenanceIcon>
                    </td>
                    <td>Notificação pelo Aplicativo (Push Notification)</td>
                  </tr>
                ) : (
                  <tr>
                    <td
                      style={{
                        width: "70px",
                      }}
                    >
                      <MaintenanceIcon onClick={this.setAppPushNotificationOn.bind(this)}>
                        <BsToggleOff />
                      </MaintenanceIcon>
                    </td>
                    <td>Notificação pelo Aplicativo (Push Notification)</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }

  // renderDelete() {
  //   return (
  //     <div className="row">
  //       <div className="col-sm-4">
  //         <div className="section-title danger">Excluir empresa</div>A exclusão da empresa é
  //         irreversível
  //       </div>
  //       <div className="col-sm-8">
  //         <button className="btn btn-outline-danger">Excluir empresa</button>
  //       </div>
  //     </div>
  //   );
  // }

  render() {
    // console.log(this.props);
    if (this.state.loading) {
      return <Loading />;
    }
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="list-group-item">{this.renderInfos()}</div>
            <div className="list-group-item">{this.renderPass()}</div>
            <div className="list-group-item">{this.renderNotifications()}</div>
            {/* <div className="list-group-item">{this.renderColors()}</div> */}
            {/* <div className="list-group-item">{this.renderLogo()}</div> */}
            {/* <div className="list-group-item">{this.renderAppBg()}</div> */}
            {/* <div className="list-group-item">{this.renderTranfer()}</div> */}
            {/* <div className="list-group-item">{this.renderMaintenance()}</div> */}
            {/* <div className="list-group-item">{this.renderDelete()}</div> */}
          </div>
        </div>
      </div>
    );
  }
}

export default Module;
