import styled from "styled-components";
import { Colors } from "../../../../themes";

export const LoadingContainer = styled.div`
  /* padding: 20px; */
  width: 100%;
  max-width: 35rem;
  margin: auto;
`;

export const Container = styled.div`
  width: 100%;
  display: flex;
  text-align: left;
  font-weight: 200;
  font-size: 15px;
  a {
    font-size: 25px;
    display: flex;
    align-items: center;
    text-decoration: none;
    color: ${Colors.primary};
    border-radius: 6px;
    padding: 3px 5px;
    line-height: 1.5;
    &:hover {
      background-color: ${Colors.primaryLigth};
    }
    svg {
      margin-right: 10px;
    }
    span {
      font-size: 15px;
    }
  }
`;

export const HCenter = styled.div`
  display: flex;
  align-items: center;
  svg {
    margin: 0 0 0 10px;
  }
`;

export const Left = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  svg {
    margin: 0px;
    height: 20px;
    width: 20px;
  }
`;

export const IconContainer = styled.div`
  color: #f00;
  font-size: 30px;
  display: flex;
  width: 40px;
  justify-content: center;
`;
