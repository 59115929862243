import React from "react";
import axios from "axios";
import { withRouter } from "react-router-dom";
import Datetime from "react-datetime";

import { Styles, Th } from "./styles";
import List from "./List";
import Loading from "../../../../components/Loading";
import Submenu from "../../components/RequestsSubmenu";

import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepButton from "@material-ui/core/StepButton";
import Expenses from "./Expenses";

const model = {
  id: null,
  payment_date: null,
};

class Module extends React.Component {
  // const data = React.useMemo(() => makeData(115), []);
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      data: [],
      statuses: [],
      showExpenses: false,
      fileUrl: "",
      // departments: [],
      // role_categories: [],
      page: 1,
      qtd_per_page: 30,
      sorted: "",
      order: "asc",
      filtered: "W10=",
      pages: 0,
      model,
      filters: {
        id: "",
        status: "",
        start_date: "",
        end_date: "",
      },
    };
    // console.log(props);
  }

  componentDidMount() {
    this.get();
    this.getStatuses();
  }

  get() {
    const that = this;
    that.setState({
      loading: true,
    });
    let { page, qtd_per_page, sorted, order } = that.state;

    // console.log(this.props.match.params);

    let filtered = [
      // {
      //   id: "tab",
      //   value: "not_done",
      // },
    ];

    if (this.props.match.params.page === "requests") {
      filtered = [
        ...filtered,
        {
          id: "done",
          value: false,
        },
      ];
    }

    if (this.props.match.params.page === "done") {
      filtered = [
        ...filtered,
        {
          id: "done",
          value: true,
        },
        {
          id: "done_and_validated",
          value: false,
        },
      ];
    }

    if (this.props.match.params.page === "done_and_validated") {
      filtered = [
        ...filtered,
        {
          id: "done",
          value: true,
        },
        {
          id: "done_and_validated",
          value: true,
        },
      ];
    }

    if (this.state.filters.status === "scheduled") {
      filtered = [
        ...filtered,
        {
          id: "scheduled",
          value: true,
        },
        {
          id: "paid",
          value: false,
        },
        {
          id: "done",
          value: false,
        },
      ];
    }

    if (this.state.filters.status === "paid") {
      filtered = [
        ...filtered,
        {
          id: "paid",
          value: true,
        },
        {
          id: "done",
          value: false,
        },
      ];
    }

    if (this.state.filters.status === "using") {
      filtered = [
        ...filtered,
        {
          id: "paid",
          value: true,
        },
        {
          id: "done",
          value: false,
        },
      ];
    }

    if (this.state.filters.status === "done") {
      filtered = [
        ...filtered,
        {
          id: "done",
          value: true,
        },
      ];
    }

    if (this.state.filters.start_date !== "") {
      filtered = [
        ...filtered,
        {
          id: "start_date",
          value: this.state.filters.start_date,
        },
      ];
    }

    if (this.state.filters.end_date !== "") {
      filtered = [
        ...filtered,
        {
          id: "end_date",
          value: this.state.filters.end_date,
        },
      ];
    }

    if (this.state.filters.id !== "") {
      filtered = [
        ...filtered,
        {
          id: "id",
          value: this.state.filters.id,
        },
      ];
    }

    // let filtered = [
    //   {
    //     id: "done",
    //     value: true,
    //   },
    // ];
    // console.log(this.state.filters);
    // console.log(filtered);
    filtered = btoa(JSON.stringify(filtered));
    // console.log(filtered);
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/company/${that.props.company.id}/app/vales/requests?page=${page}&qtd_per_page=${qtd_per_page}&sorted=${sorted}&order=${order}&filtered=${filtered}`
      )
      .then((resp) => {
        // console.log(resp);
        that.setState({
          loading: false,
          data: resp.data.result,
          qtd_per_page: resp.data.limit,
          pages: resp.data.pages,
          // qtd_per_page: resp.data.result.length,
          // pages: 1,
        });
      })
      .catch((err) => {
        console.log(err);
        // loading: false,
        that.setState({
          loading: false,
        });
        const snack = {
          ...that.state.snack,
          open: true,
          message:
            err.response &&
            err.response.data &&
            err.response.data.message &&
            err.response.data.message !== ""
              ? err.response.data.message
              : "Erro inesperado",
          color: "danger",
        };
        that.props.setSnack(snack);
      });
  }

  getReport() {
    const that = this;
    that.setState({
      loading: true,
    });
    // let { page, qtd_per_page, sorted, order } = that.state;

    let filtered = [
      // {
      //   id: "approval_finalized",
      //   value: true,
      // },
    ];

    if (this.props.match.params.page === "done") {
      filtered = [
        ...filtered,
        {
          id: "done",
          value: true,
        },
        {
          id: "done_and_validated",
          value: false,
        },
      ];
    }

    if (this.props.match.params.page === "done_and_validated") {
      filtered = [
        ...filtered,
        {
          id: "done",
          value: true,
        },
        {
          id: "done_and_validated",
          value: true,
        },
      ];
    }

    if (this.state.filters.status === "scheduled") {
      filtered = [
        ...filtered,
        {
          id: "scheduled",
          value: true,
        },
        {
          id: "paid",
          value: false,
        },
        {
          id: "done",
          value: false,
        },
      ];
    }

    if (this.state.filters.status === "paid") {
      filtered = [
        ...filtered,
        {
          id: "paid",
          value: true,
        },
        {
          id: "done",
          value: false,
        },
      ];
    }

    if (this.state.filters.status === "done") {
      filtered = [
        ...filtered,
        {
          id: "done",
          value: true,
        },
      ];
    }

    if (this.state.filters.start_date !== "") {
      filtered = [
        ...filtered,
        {
          id: "start_date",
          value: this.state.filters.start_date,
        },
      ];
    }

    if (this.state.filters.end_date !== "") {
      filtered = [
        ...filtered,
        {
          id: "end_date",
          value: this.state.filters.end_date,
        },
      ];
    }

    if (this.state.filters.id !== "") {
      filtered = [
        ...filtered,
        {
          id: "id",
          value: this.state.filters.id,
        },
      ];
    }

    // let filtered = [
    //   {
    //     id: "done",
    //     value: true,
    //   },
    // ];
    // console.log(this.state.filters);
    // console.log(filtered);
    filtered = btoa(JSON.stringify(filtered));
    // console.log(filtered);
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/company/${that.props.company.id}/app/vales/requests/report?filtered=${filtered}`
      )
      .then((resp) => {
        // console.log(resp);
        that.setState({
          loading: false,
        });
        const snack = {
          ...that.state.snack,
          open: true,
          message: resp.data.message,
          color: "success",
        };
        that.props.setSnack(snack);
      })
      .catch((err) => {
        console.log(err);
        // loading: false,
        that.setState({
          loading: false,
        });
        const snack = {
          ...that.state.snack,
          open: true,
          message:
            err.response &&
            err.response.data &&
            err.response.data.message &&
            err.response.data.message !== ""
              ? err.response.data.message
              : "Erro inesperado",
          color: "danger",
        };
        that.props.setSnack(snack);
      });
  }

  getFiles() {
    const that = this;
    that.setState({
      loading: true,
    });
    // let { page, qtd_per_page, sorted, order } = that.state;

    let filtered = [
      // {
      //   id: "approval_finalized",
      //   value: true,
      // },
    ];

    if (this.props.match.params.page === "done") {
      filtered = [
        ...filtered,
        {
          id: "done",
          value: true,
        },
        {
          id: "done_and_validated",
          value: false,
        },
      ];
    }

    if (this.props.match.params.page === "done_and_validated") {
      filtered = [
        ...filtered,
        {
          id: "done",
          value: true,
        },
        {
          id: "done_and_validated",
          value: true,
        },
      ];
    }

    if (this.state.filters.status === "scheduled") {
      filtered = [
        ...filtered,
        {
          id: "scheduled",
          value: true,
        },
        {
          id: "paid",
          value: false,
        },
        {
          id: "done",
          value: false,
        },
      ];
    }

    if (this.state.filters.status === "paid") {
      filtered = [
        ...filtered,
        {
          id: "paid",
          value: true,
        },
        {
          id: "done",
          value: false,
        },
      ];
    }

    if (this.state.filters.status === "done") {
      filtered = [
        ...filtered,
        {
          id: "done",
          value: true,
        },
      ];
    }

    if (this.state.filters.start_date !== "") {
      filtered = [
        ...filtered,
        {
          id: "start_date",
          value: this.state.filters.start_date,
        },
      ];
    }

    if (this.state.filters.end_date !== "") {
      filtered = [
        ...filtered,
        {
          id: "end_date",
          value: this.state.filters.end_date,
        },
      ];
    }

    if (this.state.filters.id !== "") {
      filtered = [
        ...filtered,
        {
          id: "id",
          value: this.state.filters.id,
        },
      ];
    }

    // let filtered = [
    //   {
    //     id: "done",
    //     value: true,
    //   },
    // ];
    // console.log(this.state.filters);
    // console.log(filtered);
    filtered = btoa(JSON.stringify(filtered));
    // console.log(filtered);
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/company/${that.props.company.id}/app/vales/requests/files?filtered=${filtered}`
      )
      .then((resp) => {
        console.log(resp);
        that.setState({
          loading: false,
          fileUrl: resp.data.file,
        });
        const snack = {
          ...that.state.snack,
          open: true,
          message: "Link para download criado com sucesso",
          color: "success",
        };
        that.props.setSnack(snack);
      })
      .catch((err) => {
        console.log(err);
        // loading: false,
        that.setState({
          loading: false,
        });
        const snack = {
          ...that.state.snack,
          open: true,
          message:
            err.response &&
            err.response.data &&
            err.response.data.message &&
            err.response.data.message !== ""
              ? err.response.data.message
              : "Erro inesperado",
          color: "danger",
        };
        that.props.setSnack(snack);
      });
  }

  destroy() {
    const that = this;
    const data = this.state.model;
    if (data.id && data.id !== 0) {
      axios
        .delete(
          `${process.env.REACT_APP_API_URL}/company/${that.props.company.id}/app/vales/requests/${data.id}`
          // `${process.env.REACT_APP_API_URL}/company/${that.props.company.id}/app/vales/requests/${data.id}`
        )
        .then((resp) => {
          // console.log(resp);
          that.get();
          const snack = {
            open: true,
            message: "Requisição removido com sucesso",
            color: "success",
          };
          that.props.setSnack(snack);
          that.setState({
            loading: false,
            model,
          });
        })
        .catch((err) => {
          console.log(err);
          // loading: false,
          const snack = {
            ...that.state.snack,
            open: true,
            message:
              err.response &&
              err.response.data &&
              err.response.data.message &&
              err.response.data.message !== ""
                ? err.response.data.message
                : "Erro inesperado",
            color: "danger",
          };
          that.props.setSnack(snack);
        });
    }
  }

  set(model) {
    this.setState({
      model,
    });
  }

  remove(model) {
    this.setState({
      model,
    });
  }

  getStatuses() {
    const that = this;

    axios
      .get(`${process.env.REACT_APP_API_URL}/company/${that.props.company.id}/app/vales/statuses`)
      .then((resp) => {
        // console.log(resp);
        that.setState({
          loading: false,
          statuses: resp.data.result,
        });
      })
      .catch((err) => {
        console.log(err);
        // loading: false,
        const snack = {
          ...that.state.snack,
          open: true,
          message:
            err.response &&
            err.response.data &&
            err.response.data.message &&
            err.response.data.message !== ""
              ? err.response.data.message
              : "Erro inesperado",
          color: "danger",
        };
        that.props.setSnack(snack);
      });
  }

  nextPage() {
    const that = this;
    let page = that.state.page + 1;
    if (page > this.state.pages) {
      page = this.state.pages;
    }
    if (page !== this.state.page) {
      that.setState({ page }, () => {
        setTimeout(() => that.get(), 10);
      });
    }
  }

  previousPage() {
    const that = this;
    let page = that.state.page - 1;
    if (page < 1) {
      page = 1;
    }
    if (page !== this.state.page) {
      that.setState({ page }, () => {
        setTimeout(() => that.get(), 10);
      });
    }
  }

  showExpenseModal(model) {
    // console.log("showExpenseModal", model);
    this.setState({
      model,
      showExpenses: true,
    });
  }

  renderTable() {
    return (
      <>
        {this.state.data.length === 0 && !this.state.loading ? (
          <table className="table table-hover">
            <thead>
              <tr>
                <Th scope="col">Nenhum registro encontrado</Th>
              </tr>
            </thead>
          </table>
        ) : null}

        {this.state.loading ? (
          <Loading />
        ) : (
          <div className="row">
            <List
              data={this.state.data}
              selectItem={this.selectItem.bind(this)}
              path={this.props.match.params.page}
              set={this.set.bind(this)}
              remove={this.remove.bind(this)}
              finish={this.finish.bind(this)}
              validateDone={this.validateDone.bind(this)}
              showExpenseModal={this.showExpenseModal.bind(this)}
            />
          </div>
        )}
      </>
    );
  }

  renderPagination() {
    // console.log(this.state);
    const that = this;
    if (this.state.data.length === 0) {
      return null;
    }
    if (that.state.page === 1 && that.state.page === that.state.pages) {
      return null;
    }
    let pages = [];
    for (let i = 1; i <= this.state.pages; i++) {
      pages.push(i);
    }
    return (
      <div className="container">
        <div className="row">
          <div className="col-sm">
            <button
              onClick={that.previousPage.bind(this)}
              className="btn btn-light btn-md btn-block"
              disabled={that.state.page === 1}
            >
              Anterior
            </button>
          </div>
          <div className="col-sm">
            <div className="form-group">
              <select
                className="form-control"
                value={that.state.page}
                onChange={(event) =>
                  that.setState({ page: event.target.value }, () => {
                    setTimeout(() => that.get(), 10);
                  })
                }
              >
                {pages.map((actualPage) => (
                  <option key={actualPage} value={actualPage}>
                    Página {actualPage}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="col-sm">
            <div className="form-group">
              <select
                className="form-control"
                value={that.state.qtd_per_page}
                onChange={(event) =>
                  that.setState({ qtd_per_page: event.target.value, page: 1 }, () => {
                    setTimeout(() => that.get(), 10);
                  })
                }
              >
                {[3, 6, 9, 30, 60, 90].map((pageSize) => (
                  <option key={pageSize} value={pageSize}>
                    Mostrar {pageSize} linhas
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="col-sm">
            <button
              onClick={that.nextPage.bind(this)}
              className="btn btn-light btn-md btn-block"
              disabled={that.state.page === that.state.pages}
            >
              Próxima
            </button>
          </div>
        </div>
      </div>
    );
  }

  selectItem(item) {
    // console.log("selectItem");
    // console.log(item);
    this.setState({
      model: item,
    });
  }

  saveSchedule() {
    // console.log("saveSchedule");
    // console.log(this.state);
    const that = this;
    let data = this.state.model;
    // data.payment_date = this.state.model.payment_date._d;
    // console.log(data);
    if (data.id && data.id !== 0) {
      axios
        .put(
          `${process.env.REACT_APP_API_URL}/company/${that.props.company.id}/app/vales/requests/${data.id}/schedule`,
          data
        )
        .then((resp) => {
          // console.log(resp);
          that.get();
          const snack = {
            open: true,
            message: "Pagamento agendado com sucesso",
            color: "success",
          };
          that.props.setSnack(snack);
          that.setState({
            loading: false,
            model: {
              id: null,
              description: "",
              department_id: "",
              role_category_id: "",
            },
          });
        })
        .catch((err) => {
          console.log(err);
          // loading: false,
          const snack = {
            ...that.state.snack,
            open: true,
            message:
              err.response &&
              err.response.data &&
              err.response.data.message &&
              err.response.data.message !== ""
                ? err.response.data.message
                : "Erro inesperado",
            color: "danger",
          };
          that.props.setSnack(snack);
        });
    }
  }

  finish(item) {
    // console.log("saveSchedule");
    // console.log(this.state);
    const that = this;
    let data = item;
    // data.payment_date = this.state.model.payment_date._d;
    // console.log(data);
    if (data.id && data.id !== 0) {
      axios
        .put(
          `${process.env.REACT_APP_API_URL}/company/${that.props.company.id}/app/vales/requests/${data.id}/finish`,
          {}
        )
        .then((resp) => {
          // console.log(resp);
          that.get();
          const snack = {
            open: true,
            message: "Solicitação finalizada com sucesso",
            color: "success",
          };
          that.props.setSnack(snack);
          that.setState({
            loading: false,
            model: {
              id: null,
              description: "",
              department_id: "",
              role_category_id: "",
            },
          });
        })
        .catch((err) => {
          console.log(err);
          // loading: false,
          const snack = {
            ...that.state.snack,
            open: true,
            message:
              err.response &&
              err.response.data &&
              err.response.data.message &&
              err.response.data.message !== ""
                ? err.response.data.message
                : "Erro inesperado",
            color: "danger",
          };
          that.props.setSnack(snack);
        });
    }
  }

  validateDone(item) {
    // console.log("saveSchedule");
    // console.log(this.state);
    const that = this;
    let data = item;
    // data.payment_date = this.state.model.payment_date._d;
    // console.log(data);
    if (data.id && data.id !== 0) {
      axios
        .put(
          `${process.env.REACT_APP_API_URL}/company/${that.props.company.id}/app/vales/requests/${data.id}/validate-done`,
          {}
        )
        .then((resp) => {
          // console.log(resp);
          that.get();
          const snack = {
            open: true,
            message: "Solicitação validada com sucesso",
            color: "success",
          };
          that.props.setSnack(snack);
          that.setState({
            loading: false,
            model: {
              id: null,
              description: "",
              department_id: "",
              role_category_id: "",
            },
          });
        })
        .catch((err) => {
          console.log(err);
          // loading: false,
          const snack = {
            ...that.state.snack,
            open: true,
            message:
              err.response &&
              err.response.data &&
              err.response.data.message &&
              err.response.data.message !== ""
                ? err.response.data.message
                : "Erro inesperado",
            color: "danger",
          };
          that.props.setSnack(snack);
        });
    }
  }

  renderFormSchedule() {
    const yesterday = Datetime.moment().subtract(1, "day");
    return (
      <form>
        <div className="form-group">
          <label className="col-form-label">Data:</label>
          <Datetime
            timeFormat={false}
            closeOnSelect={true}
            isValidDate={(current) => {
              return current.isAfter(yesterday);
            }}
            onChange={(event) => {
              // console.log(event);
              // console.log(
              //   event.locale("en").format("L").split("/").join("-")
              // );
              this.setState({
                model: {
                  ...this.state.model,
                  payment_date: event._d,
                },
              });
            }}
            value={this.state.payment_date}
          />
        </div>
      </form>
    );
  }

  renderModalSchedule() {
    return (
      <div
        className="modal fade"
        id="ModalSchedule"
        data-backdrop="static"
        data-keyboard="false"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="staticBackdropLabel">
                Agendar pagamento
              </h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">{this.renderFormSchedule()}</div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-dismiss="modal">
                Cancelar
              </button>
              <button
                onClick={() => this.saveSchedule()}
                type="button"
                className="btn btn-primary"
                data-dismiss="modal"
              >
                Salvar
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderStepsModal() {
    let steps = [];
    let activeStep = 0;
    if (this.state.model && this.state.model.state && this.state.model.state.steps) {
      steps = this.state.model.state.steps;
      activeStep = this.state.model.state.activeStep;
    }
    return (
      <div
        className="modal fade"
        id="stepModal"
        data-backdrop="static"
        data-keyboard="false"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="stepModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="stepModalLabel">
                {" "}
              </h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <Stepper nonLinear activeStep={activeStep} orientation="vertical">
                {steps.map((label, index) => (
                  <Step key={label}>
                    <StepButton>{label}</StepButton>
                  </Step>
                ))}
              </Stepper>
            </div>
          </div>
        </div>
      </div>
    );
  }

  savePayment() {
    // console.log("savePayment");
    // console.log(this.state);
    const that = this;
    let data = this.state.model;
    // data.payment_date = this.state.model.payment_date._d;
    // console.log(data);
    if (data.id && data.id !== 0) {
      axios
        .put(
          `${process.env.REACT_APP_API_URL}/company/${that.props.company.id}/app/vales/requests/${data.id}/paid`,
          data
        )
        .then((resp) => {
          // console.log(resp);
          that.get();
          const snack = {
            open: true,
            message: "Pagamento informado com sucesso",
            color: "success",
          };
          that.props.setSnack(snack);
          that.setState({
            loading: false,
            model: {
              id: null,
              description: "",
              department_id: "",
              role_category_id: "",
            },
          });
        })
        .catch((err) => {
          console.log(err);
          // loading: false,
          const snack = {
            ...that.state.snack,
            open: true,
            message:
              err.response &&
              err.response.data &&
              err.response.data.message &&
              err.response.data.message !== ""
                ? err.response.data.message
                : "Erro inesperado",
            color: "danger",
          };
          that.props.setSnack(snack);
        });
    }
  }

  renderFormPayment() {
    const today = Datetime.moment();
    return (
      <form>
        <div className="form-group">
          <label className="col-form-label">Data:</label>
          <Datetime
            timeFormat={false}
            closeOnSelect={true}
            isValidDate={(current) => {
              return current.isBefore(today);
            }}
            onChange={(event) => {
              // console.log(event);
              // console.log(
              //   event.locale("en").format("L").split("/").join("-")
              // );
              this.setState({
                model: {
                  ...this.state.model,
                  paid_at: event._d,
                },
              });
            }}
            value={this.state.paid_at}
          />
        </div>
      </form>
    );
  }

  renderModalPayment() {
    return (
      <div
        className="modal fade"
        id="ModalPayment"
        data-backdrop="static"
        data-keyboard="false"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="staticBackdropLabel">
                Informar pagamento
              </h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">{this.renderFormPayment()}</div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-dismiss="modal">
                Cancelar
              </button>
              <button
                onClick={() => this.savePayment()}
                type="button"
                className="btn btn-primary"
                data-dismiss="modal"
              >
                Salvar
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  saveStatus() {
    // console.log("saveStatus");
    // console.log(this.state);
    const that = this;
    let data = this.state.model;
    // data.payment_date = this.state.model.payment_date._d;
    // console.log(data);
    if (data.id && data.id !== 0) {
      axios
        .put(
          `${process.env.REACT_APP_API_URL}/company/${that.props.company.id}/app/vales/requests/${data.id}/status`,
          data
        )
        .then((resp) => {
          // console.log(resp);
          that.get();
          const snack = {
            open: true,
            message: "Status atualizado com sucesso",
            color: "success",
          };
          that.props.setSnack(snack);
          that.setState({
            loading: false,
            model: {
              id: null,
              description: "",
              department_id: "",
              role_category_id: "",
            },
          });
        })
        .catch((err) => {
          console.log(err);
          // loading: false,
          const snack = {
            ...that.state.snack,
            open: true,
            message:
              err.response &&
              err.response.data &&
              err.response.data.message &&
              err.response.data.message !== ""
                ? err.response.data.message
                : "Erro inesperado",
            color: "danger",
          };
          that.props.setSnack(snack);
        });
    }
  }

  renderFormStatus() {
    return (
      <form>
        <div className="form-group">
          <label className="col-form-label">Status:</label>
          <select
            onChange={(event) => {
              this.setState({
                model: {
                  ...this.state.model,
                  status: event.target.value,
                },
              });
            }}
            className="form-control"
          >
            <option value="">Selecione o status</option>
            {this.state.statuses.map((status) => (
              <option key={status.id}>{status.description}</option>
            ))}
          </select>
        </div>
      </form>
    );
  }

  renderModalStatus() {
    return (
      <div
        className="modal fade"
        id="ModalStatus"
        data-backdrop="static"
        data-keyboard="false"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="staticBackdropLabel">
                Mudar Status
              </h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">{this.renderFormStatus()}</div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-dismiss="modal">
                Cancelar
              </button>
              <button
                onClick={() => this.saveStatus()}
                type="button"
                className="btn btn-primary"
                data-dismiss="modal"
              >
                Salvar
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderModalRemove() {
    return (
      <div
        className="modal fade"
        id="removeModal"
        data-backdrop="static"
        data-keyboard="false"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="staticBackdropLabel">
                {` `}
              </h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              {`Tem certeza que deseja remover (${this.state.model.description})?`}
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-dismiss="modal">
                Cancelar
              </button>
              <button
                onClick={() => this.destroy()}
                type="button"
                className="btn btn-danger"
                data-dismiss="modal"
              >
                Confirmar
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  setFilterStatus(status) {
    const that = this;
    that.setState(
      {
        page: 1,
        filters: {
          ...that.state.filters,
          status,
        },
      },
      () => setTimeout(() => that.get(), 50)
    );
  }

  setFilterStartDate(start_date) {
    const that = this;
    // console.log(start_date);
    that.setState(
      {
        page: 1,
        filters: {
          ...that.state.filters,
          start_date,
        },
      },
      () => setTimeout(() => that.get(), 50)
    );
  }

  setFilterEndDate(end_date) {
    const that = this;
    // console.log(end_date);
    that.setState(
      {
        page: 1,
        filters: {
          ...that.state.filters,
          end_date,
        },
      },
      () => setTimeout(() => that.get(), 50)
    );
  }

  setFilterId(id) {
    const that = this;
    // console.log(id);
    that.setState(
      {
        page: 1,
        filters: {
          ...that.state.filters,
          id,
        },
      },
      () => setTimeout(() => that.get(), 50)
    );
  }

  renderFilters() {
    const today = Datetime.moment();
    return (
      <form>
        <div className="row">
          <div className="form-group col-sm-1">
            <label>ID</label>
            <input
              onChange={(event) => this.setFilterId(event.target.value)}
              className="form-control"
              type="text"
            />
          </div>
          {this.props.match.params.page != "done" &&
          this.props.match.params.page != "done_and_validated" ? (
            <div className="form-group col-sm-3">
              <label>Status</label>
              <select
                className="form-control"
                onChange={(event) => this.setFilterStatus(event.target.value)}
                value={this.state.filters.status}
              >
                <option value="">Todos</option>
                <option value="scheduled">Agendados</option>
                <option value="paid">Pagos</option>
                {/* <option value="using">Em uso</option> */}
                {/* <option value="done">Concluídos</option> */}
              </select>
            </div>
          ) : null}
          <div className="col-md-3">
            <label>Início</label>
            <Datetime
              timeFormat={false}
              closeOnSelect={true}
              isValidDate={(current) => {
                return current.isBefore(today);
              }}
              onChange={(event) => {
                this.setFilterStartDate(event._d);
              }}
              value={this.state.paid_at}
            />
          </div>
          <div className="col-md-3">
            <label>Fim</label>
            <Datetime
              timeFormat={false}
              closeOnSelect={true}
              isValidDate={(current) => {
                return current.isBefore(today);
              }}
              onChange={(event) => {
                this.setFilterEndDate(event._d);
              }}
              value={this.state.paid_at}
            />
          </div>
          <div className="col-md-2">
            <label>Relatório</label>
            <button
              onClick={() => this.getReport()}
              type="button"
              className="btn btn-primary"
              data-dismiss="modal"
            >
              Enviar
            </button>
          </div>
          <div className="col-md-2">
            <label>Arquivos</label>
            {this.state.fileUrl === "" ? (
              <button
                onClick={() => this.getFiles()}
                type="button"
                className="btn btn-primary"
                data-dismiss="modal"
              >
                Compactar
              </button>
            ) : (
              <a
                href={this.state.fileUrl}
                target="_blank"
                type="button"
                className="btn btn-primary"
              >
                Baixar arquivos
              </a>
            )}
          </div>
        </div>
      </form>
    );
  }

  render() {
    return (
      <>
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-2">
              <Submenu path={this.props.match.params.page} company={this.props.company} />
            </div>
            <div className="col-sm-10">
              {this.state.showExpenses ? (
                <Expenses
                  request={this.state.model}
                  company={this.props.company}
                  setSnack={this.props.setSnack}
                  back={() => {
                    this.setState({ showExpenses: false });
                    this.get();
                  }}
                />
              ) : (
                <>
                  {this.renderFilters()}
                  <div>
                    <Styles>
                      {this.renderTable()}
                      {this.renderPagination()}
                    </Styles>
                  </div>
                  {this.renderModalSchedule()}
                  {this.renderModalPayment()}
                  {this.renderModalStatus()}
                  {this.renderStepsModal()}
                  {this.renderModalRemove()}
                </>
              )}
            </div>
          </div>
        </div>
      </>
      // <>
      //   <div>
      //     <Styles>
      //       {this.renderTable()}
      //       {this.state.data.length > 0 ? this.renderPagination() : null}
      //     </Styles>
      //   </div>
      //   {this.renderModal()}
      //   {this.renderModalRemove()}
      // </>
    );
  }
}

export default withRouter(Module);
