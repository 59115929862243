import React from "react";
import { Link } from "react-router-dom";
import axios from "axios";

class index extends React.Component {
  constructor(props) {
    super(props);
    const userData = JSON.parse(localStorage.getItem(process.env.REACT_APP_USER_KEY));
    const { user } = userData;
    // console.log(user);
    // console.log(props);
    this.state = {
      approvals: [],
      user,
      owner:
        this.props &&
        this.props.company &&
        this.props.company.Owner &&
        this.props.company.owner_id === user.id
          ? true
          : false,
      admin: false,
    };
  }

  componentDidMount() {
    this.getApprovals();
    this.getAdmmins();
  }

  getApprovals() {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/company/${this.props.company.id}/app/vales/approve-requests`
      )
      .then((resp) =>
        this.setState({
          approvals: resp.data.result,
        })
      );
  }

  getAdmmins() {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/company/${this.props.company.id}/app/vales/users/${this.state.user.id}`
      )
      .then((resp) =>
        this.setState({
          admin: true,
        })
      );
  }

  // console.log(props);
  render() {
    console.log(this.state);
    return (
      <div className="nav flex-column nav-pills" id="v-pills-tab" aria-orientation="vertical">
        <Link
          to={`/company/${this.props.company.id}/app/vales/my-requests`}
          className={this.props.path === "my-requests" ? "nav-link active" : "nav-link"}
        >
          Minhas Requisições
        </Link>
        {this.state.approvals.length > 0 ? (
          <Link
            to={`/company/${this.props.company.id}/app/vales/approvals`}
            className={this.props.path === "approvals" ? "nav-link active" : "nav-link"}
          >
            Aprovações
          </Link>
        ) : null}
        {this.state.owner || this.state.admin ? (
          <>
            <Link
              to={`/company/${this.props.company.id}/app/vales/requests`}
              className={this.props.path === "requests" ? "nav-link active" : "nav-link"}
            >
              Movimentações
            </Link>
            <Link
              to={`/company/${this.props.company.id}/app/vales/done`}
              className={this.props.path === "done" ? "nav-link active" : "nav-link"}
            >
              Concluídos
            </Link>
            <Link
              to={`/company/${this.props.company.id}/app/vales/done_and_validated`}
              className={this.props.path === "done_and_validated" ? "nav-link active" : "nav-link"}
            >
              Validados
            </Link>
          </>
        ) : null}
      </div>
    );
  }
}

export default index;
