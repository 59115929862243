import React from "react";
import { Link } from "react-router-dom";
import axios from "axios";
// import logo from "./logo.svg";

import GlobalStyle from "../../../../styles/global";
import Header from "../../../../components/Header";
import Loading from "../../../../components/Loading";
import Nav from "../../components/Nav";
import {
  Container,
  IconContainer,
  IconStarContainer,
  Left,
  Right,
  NoCompaniesMessage,
} from "./styles";
import {
  // MdError,
  // MdCheckCircle,
  MdStar,
  MdStarBorder,
} from "react-icons/md";
import { FcOk, FcInfo, FcHighPriority } from "react-icons/fc";

class Module extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      companies: [],
    };
  }

  componentDidMount() {
    this.get();
  }

  get() {
    this.setState({ loading: true });
    axios
      .get(`${process.env.REACT_APP_API_URL}/companies`)
      .then((resp) => {
        this.setState({ loading: false, companies: resp.data.result });
        // console.log(resp);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  renderRow(company) {
    return (
      <li key={company.id}>
        <Link to={`/companies/${company.id}/apps`}>
          <Left>
            <IconContainer>
              {company.frozen === true ? (
                <FcHighPriority />
              ) : company.maintenance === true ? (
                <FcInfo />
              ) : (
                <FcOk />
              )}
            </IconContainer>
            {company.name}
          </Left>
          <Right>
            {company.cnpj}
            {/* <IconStarContainer>
              {company.favorite ? <MdStar /> : <MdStarBorder />}
            </IconStarContainer> */}
          </Right>
        </Link>
      </li>
    );
  }

  renderCompanies() {
    if (this.state.companies.length === 0) {
      return <NoCompaniesMessage>Nenhuma empresa cadastrada</NoCompaniesMessage>;
    }
    return <ul>{this.state.companies.map((company) => this.renderRow(company))}</ul>;
  }

  render() {
    return (
      <>
        <Header />
        <Nav />
        <Container>{this.state.loading ? <Loading /> : this.renderCompanies()}</Container>
        <GlobalStyle />
      </>
    );
  }
}

export default Module;
