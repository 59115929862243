import React from "react";
import { withRouter, Redirect } from "react-router-dom";
import axios from "axios";
// import logo from "./logo.svg";

import GlobalStyle from "../../styles/global";
import Header from "../../components/Header";
import Nav from "./components/NavDetails";
import Menu from "./components/Menu";

import Snackbars from "../../components/Snackbars";
import AddAlert from "@material-ui/icons/AddAlert";

import StatusesScreen from "./pages/Statuses";
import ExpenseCategoriesScreen from "./pages/ExpenseCategories";

import MyRequestsScreen from "./pages/MyRequests";
import ApprovalsScreen from "./pages/Approvals";
import RequestsScreen from "./pages/Requests";

import JurisdictionsScreen from "./pages/Jurisdictions";
import AccessScreen from "./pages/Access";

// import CompanyOverview from "./pages/Overview";
// import CompanyRoleCategories from "./pages/RoleCategories";
// import CompanyRoles from "./pages/Roles";
// import CompanyDepartments from "./pages/Departments";
// import CompanyStaffs from "./pages/Staffs";
// import CompanyAccess from "./pages/Access";
// import CompanyApps from "./pages/Apps";
// import CompanySettings from "./pages/Settings";
// import CompanyCostCenters from "./pages/CostCenters";

const company = {
  id: 0,
  name: "",
  cnpj: "",
  frozen: false,
  maintenance: false,
};

class Module extends React.Component {
  constructor(props) {
    super(props);
    // console.log(props);
    let { company_id } = props.match.params;
    company_id = parseInt(company_id);
    const base_url = `/company/${company_id}/app/vales`;
    this.state = {
      loading: true,
      company_id,
      base_url,
      company,
      snack: {
        open: false,
        message: "",
        color: "success",
      },
    };

    // console.log(props);
  }

  setSnack(snack) {
    this.setState({ snack });
  }

  componentDidMount() {
    this.getCompany();
  }

  getCompany() {
    const that = this;
    that.setState({
      loading: true,
    });
    axios
      .get(`${process.env.REACT_APP_API_URL}/companies/${that.state.company_id}`)
      .then((resp) => {
        // console.log(resp);
        that.setState({
          loading: false,
          company: resp.data,
        });
      })
      .catch((err) => {
        console.log(err);
        that.setState({
          loading: false,
          snack: {
            ...that.state.snack,
            open: true,
            message:
              err.response &&
              err.response.data &&
              err.response.data.message &&
              err.response.data.message !== ""
                ? err.response.data.message
                : "Erro inesperado",
            color: "danger",
          },
        });
      });
  }

  renderContent() {
    if (!this.state.company || !this.state.company.name || this.state.company.name === "") {
      return null;
    }
    /*
    import CompanyOverview from "../modules/companies/pages/Overview";
    import CompanyRoleCategories from "../modules/companies/pages/RoleCategories";
    import CompanyRoles from "../modules/companies/pages/Roles";
    import CompanyDepartments from "../modules/companies/pages/Departments";
    import CompanyStaffs from "../modules/companies/pages/Staffs";
    import CompanyAccess from "../modules/companies/pages/Access";
    import CompanyResources from "../modules/companies/pages/Resources";
    import CompanySettings from "../modules/companies/pages/Settings";
    */
    const page = this.props.match.params.page;
    const props = {
      company: this.state.company,
      getCompany: this.getCompany.bind(this),
      setSnack: this.setSnack.bind(this),
    };
    return (
      <>
        <Menu base_url={this.state.base_url} company={this.state.company} path={page} />

        {/* {page === "settings" ? <CompanySettings {...props} /> : null} */}
        {page === "statuses" ? <StatusesScreen {...props} /> : null}
        {page === "expense-categories" ? <ExpenseCategoriesScreen {...props} /> : null}

        {page === "jurisdictions" ? <JurisdictionsScreen {...props} /> : null}
        {page === "access" ? <AccessScreen {...props} /> : null}
        {page === "my-requests" ? <MyRequestsScreen {...props} /> : null}
        {page === "approvals" ? <ApprovalsScreen {...props} /> : null}
        {page === "requests" ? <RequestsScreen {...props} /> : null}
        {page === "scheduled" ? <RequestsScreen {...props} /> : null}
        {page === "paid" ? <RequestsScreen {...props} /> : null}
        {page === "done" ? <RequestsScreen {...props} /> : null}
        {page === "done_and_validated" ? <RequestsScreen {...props} /> : null}

        {/* {page === "overview" ? <CompanyOverview {...props} /> : null} */}
        {/* {page === "role-categories" ? (
          <CompanyRoleCategories {...props} />
        ) : null} */}
        {/* {page === "roles" ? <CompanyRoles {...props} /> : null} */}
        {/* {page === "departments" ? <CompanyDepartments {...props} /> : null} */}
        {/* {page === "staffs" ? <CompanyStaffs {...props} /> : null} */}
        {/* {page === "access" ? <CompanyAccess {...props} /> : null} */}
        {/* {page === "apps" ? <CompanyApps {...props} /> : null} */}
        {/* {page === "cost-centers" ? <CompanyCostCenters {...props} /> : null} */}
      </>
    );
  }

  render() {
    // console.log(this.props);
    if (isNaN(this.state.company_id) || this.state.company_id === 0) {
      return <Redirect to={"/"} />;
    }
    if (!this.props.match.params.page || this.props.match.params.page === "") {
      return <Redirect to={`${this.props.match.url}/my-requests`} />;
    }
    return (
      <>
        <Snackbars
          place="tr"
          color={this.state.snack.color}
          icon={AddAlert}
          message={this.state.snack.message}
          open={this.state.snack.open}
          closeNotification={() =>
            this.setState({
              snack: { ...this.state.snack, open: false },
            })
          }
          close
        />
        <Header />
        <Nav
          company={this.state.company}
          base_url={this.state.base_url}
          loading={this.state.loading}
        />
        {this.state.loading === false ? this.renderContent() : null}
        <GlobalStyle />
      </>
    );
  }
}

export default withRouter(Module);
