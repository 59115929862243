import { createGlobalStyle } from "styled-components";
import { Colors } from "../themes";
import "bootstrap/dist/css/bootstrap.min.css";
import "./react-datetime.css";

export default createGlobalStyle`

  *{
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
  }

  html, body, #root {
    height: 100%;
  }

  body {
    font: 14px 'Roboto', sans-serif;
    /* background: #ecf1f8; */
    background: #fff;
    color: #333;
    -webkit-font-smoothing: antaliesed !important;
    padding-top: 53px;
  }

  ul {
    list-style: none;
  }

  label{
    width: 100%;
    display: flex;
    margin-bottom: 10px;
    font-size: 13px;
    font-weight: 700;
  }

  input{
    width: 100%;
    display: flex;
    margin-bottom: 15px;
    outline: 0;
      border-radius: 4px;
      padding: 5px 10px;
      font-size: 13px;
      background: #fff;
      border: 1px solid ${Colors.primaryMedium};
      box-shadow: inset 0 1px 2px 0 rgba(210,219,231,.4);
    &:focus{
      outline: none;
      border-color: ${Colors.primaryMedium};
      box-shadow: 0 0 0 2px ${Colors.primary50};
    }
  }


  .primary{
    justify-content: center;
    text-align: center;
    padding: 4px 15px;
    font-weight: 600;
    white-space: nowrap;
    cursor: pointer;
    text-decoration: none;
    box-sizing: border-box;
    background-clip: border-box!important;
    background-origin: border-box!important;
    background: white;
    /* background: linear-gradient(135deg,${Colors.primary60},${Colors.primary}); */
    background-image: -webkit-linear-gradient(-45deg,${Colors.primary60} 0, ${Colors.primary} 100%);
    color: #fff;
    align-items: center;
    display: inline-flex;
    font-size: 13px;
    line-height: 22px;
    border-radius: 4px;
    border: none;
  }

  .btn-primary{
    background: white !important;
    border: none;
    background-image: -webkit-linear-gradient(-45deg,${Colors.primary60} 0, ${Colors.primary} 100%) !important;
  }

  .secoundary{
    justify-content: center;
    text-align: center;
    padding: 4px 15px;
    font-weight: 600;
    white-space: nowrap;
    cursor: pointer;
    text-decoration: none;
    box-sizing: border-box;
    background-clip: border-box!important;
    background-origin: border-box!important;
    color: ${Colors.primary};
    background: #fff;
    align-items: center;
    display: inline-flex;
    font-size: 13px;
    line-height: 22px;
    border-radius: 4px;
    border: 1px solid ${Colors.primary};
  }

  .btn-outline-primary{
    background: #fff !important;
    border: 1px solid ${Colors.primary};
    color: ${Colors.primary};
    &:hover{
      color: ${Colors.primary};
      border: 1px solid ${Colors.primary};
      background: ${Colors.primaryLigth} !important;
    }
    &:focus{
      background: #fff !important;
      border: 1px solid ${Colors.primary} !important;
      color: ${Colors.primary} !important;
    }
    /* border: none; */
    /* background-image: -webkit-linear-gradient(-45deg,${Colors.primary60} 0, ${Colors.primary} 100%) !important; */
  }

  .btn-link{
    color: ${Colors.primary};
    &:hover{
      color: ${Colors.primary};
      border: 1px solid ${Colors.primary};
      /* color: white; */
    }
  }

  .btn-link-danger{
    color: #dc3545;
    &:hover{
      color: #dc3545;
      border: 1px solid #dc3545;
      /* color: white; */
    }
  }

  .table-hover tbody tr:hover{
    background: ${Colors.primaryLigth} !important;
  }

  .btn-light{
    color: ${Colors.primary};
    background: ${Colors.primaryLigth} !important;
    &:hover{
      color: ${Colors.primary};

    }
  }

  .menu-dropdown-item{
    border-top: 1px solid ${Colors.primaryLigth};
    color: ${Colors.primary};
    font-size: 14px;
    line-height: 40px;
    font-weight: 400;
    margin-right: 50px;
  }

  .container-fluid{
    padding-right: 40px;
    padding-left: 40px;
  }

  .list-group-item{
    border-top: none;
    border-left: none;
    border-right: none;
    padding-top: 20px;
    padding-bottom: 40px;
  }

  .section-title{
    font-size: 15px;
    color: ${Colors.primary};
    margin-bottom: 15px;
  }

  .danger{
    color: #dc3545 !important;
  }

  .nav-pills .nav-link{
    color: ${Colors.primary};
    &.active{
    background: ${Colors.primary} !important;
    color: "#fff" !important;
    border-radius: 0 !important;
    }
  }

  .card{
    padding: 6px !important;
    margin: 6px !important;
    max-width: calc(33% - 12px) !important;
    .list-group-item{
      padding: 10px 5px !important;

    }
  }

  .card-100{
    padding: 6px !important;
    margin: 6px !important;
    max-width: 100% !important;
    width: 100% !important;
    .list-group-item{
      padding: 10px 5px !important;

    }

    .card-request{
      float: left !important;
      width: 33% !important;
    }
  }

  .card-body-actions{
    padding: 0
  }

  .MuiInputBase-input{
    box-shadow: none !important;
  }
  .MuiInput-underline:before{
    border-bottom: none !important;
  }

  .card-img-top{
    max-height: 300px
  }

`;
