import React, { Component } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import CreateGlobalStyle, {
  Container,
  Logo,
  Content,
  Panel,
  // Overlay,
} from "../styles";
import Snackbars from "../../../../components/SnackbarContent";
import AddAlert from "@material-ui/icons/AddAlert";

// import { FaUser, FaLock } from "react-icons/fa";
import { AiOutlineMail } from "react-icons/ai";

import logo from "../../../../assets/img/logo.png";

class index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      loading: false,
      snack: {
        open: false,
        message: "",
        color: "success",
      },
    };
  }

  recovery() {
    // console.log(this.state);
    this.setState({ loading: true });

    axios
      .post(`${process.env.REACT_APP_API_URL}/recovery`, this.state)
      .then((response) => {
        this.setState({
          loading: false,
          email: "",
          snack: {
            ...this.state.snack,
            open: true,
            message:
              response.data &&
              response.data.message &&
              response.data.message !== ""
                ? response.data.message
                : "Erro inesperado",
            color: "success",
          },
        });
        // console.log(response);
      })
      .catch((err) => {
        this.setState({
          loading: false,
          snack: {
            ...this.state.snack,
            open: true,
            message:
              err.response &&
              err.response.data &&
              err.response.data.message &&
              err.response.data.message !== ""
                ? err.response.data.message
                : "Erro inesperado",
            color: "danger",
          },
        });
        // console.log(err.response);
      });
  }

  renderForm() {
    return (
      <form>
        <div className="form-group">
          <label>E-mail</label>
          <div className="input-icon icon-email">
            <AiOutlineMail />
          </div>
          <input
            autoFocus={true}
            className="form-control"
            id="email"
            name="email"
            value={this.state.email}
            onChange={(event) => this.setState({ email: event.target.value })}
            placeholder="E-mail"
            type="email"
          />
        </div>
        {this.state.loading ? (
          <button
            className="btn btn-primary btn-lg btn-block"
            type="button"
            value="Log In"
            onClick={() => {}}
            disabled={true}
          >
            <div
              className="spinner-border spinner-border-sm text-light"
              role="status"
            >
              <span className="sr-only">Carregando...</span>
            </div>{" "}
            Enviar
          </button>
        ) : (
          <a
            className="btn btn-primary btn-lg btn-block"
            onClick={this.recovery.bind(this)}
            href="#this"
          >
            Enviar
          </a>
        )}
      </form>
    );
  }
  render() {
    // console.log(this.state);
    return (
      <>
        {/* <Overlay /> */}
        <Container>
          <Snackbars
            place="tr"
            color={this.state.snack.color}
            icon={AddAlert}
            message={this.state.snack.message}
            open={this.state.snack.open}
            closeNotification={() =>
              this.setState({ snack: { ...this.state.snack, open: false } })
            }
            close
          />
          <Logo>
            <a href="http://hubera.com.br">
              <img src={logo} alt="Hubera" />
            </a>
          </Logo>
          <Content>
            <Panel>
              <h2>Resetar senha</h2>
              {this.renderForm()}
              <div className="panel-footer">
                <Link to="/login">
                  <span>Logar</span>
                </Link>{" "}
                ou{" "}
                <Link to="/signup">
                  <span>Cadastrar</span>
                </Link>
              </div>
            </Panel>
          </Content>
        </Container>
        <CreateGlobalStyle />
      </>
    );
  }
}

export default index;
