import React from "react";
import { Link } from "react-router-dom";

import { Container, Icon, Content } from "./styles";

function App(props) {
  // console.log(props);
  return (
    <div className="col-4">
      <Container>
        <Link to={`/company/${props.company.id}/app/${props.path}`}>
          <div className="row">
            <div className="col-4">
              <Icon src={props.image} alt="" />
            </div>
            <Content className="col-8">
              <h3>{props.title}</h3>
            </Content>
          </div>
        </Link>
      </Container>
    </div>
  );
}

export default App;
