import React from "react";
import { Link, Redirect } from "react-router-dom";
// import { Nav, NavDropdown } from "react-bootstrap";
import { MdExitToApp, MdTune } from "react-icons/md";

import { Container, Avatar, BigAvatar, DropdownHeader } from "./styles";
import logo from "../../assets/img/logo-horizontal.png";
import avatar from "../../assets/img/ninja-avatar-96x96.png";

class Header extends React.Component {
  constructor(props) {
    super(props);

    const dataUser = JSON.parse(localStorage.getItem(process.env.REACT_APP_USER_KEY));

    this.state = {
      user: dataUser.user,
    };
  }

  logout() {
    // console.log("logout");
    localStorage.removeItem(process.env.REACT_APP_USER_KEY);
    this.setState({ user: null });
  }

  render() {
    // console.log("this.state: ", this.state);
    if (!this.state.user || !this.state.user.token) {
      return (
        <Redirect
          to={{
            pathname: "/login",
          }}
        />
      );
    }
    return (
      <Container>
        <Link to="/">
          <img src={logo} alt="Hubera" />
        </Link>
        <div className="dropdown">
          <Avatar
            src={avatar}
            alt={"Avatar"}
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          />
          <div className="dropdown-menu" aria-labelledby="dropdownMenu2">
            <DropdownHeader className="">
              <BigAvatar
                src={avatar}
                alt={"Avatar"}
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              />
              <span>{this.state.user.email}</span>
            </DropdownHeader>
            <Link to="/account" className="dropdown-item menu-dropdown-item">
              <MdTune /> Configurações
            </Link>
            <a
              onClick={() => this.logout()}
              href="#this"
              className="dropdown-item menu-dropdown-item"
            >
              <MdExitToApp /> Sair
            </a>
          </div>
        </div>
      </Container>
    );
  }
}

export default Header;
