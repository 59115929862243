import styled from "styled-components";
import { Colors } from "../../../../themes";

export const Container = styled.div`
  /* width: 100%; */
  text-align: left;
  font-size: 25px;
  font-weight: 200;
  a {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: ${Colors.primary};
    border-radius: 6px;
    padding: 3px 5px;
    line-height: 1.5;
    &:hover {
      background-color: ${Colors.primaryLigth};
    }
    svg {
      margin-right: 10px;
    }
    span {
      font-size: 15px;
    }
  }
`;
