import React from "react";
// import { useTable, usePagination } from "react-table";
// import BTable from "react-bootstrap/Table";
// import GlobalStyle from "../../../../../styles/global";
import { Td } from "../styles";
// import "bootstrap/dist/css/bootstrap.min.css";
import {
  // MdError,
  // MdCheckCircle,
  MdClose,
  MdModeEdit,
} from "react-icons/md";

export default function List(props) {
  // console.log(props);
  const { data, show, remove } = props;
  // Use the state and functions returned from useTable to build your UI

  // Render the UI for your table
  return (
    <>
      {data.map((item) => {
        // console.log(item);
        return (
          <tr key={item.id}>
            <Td>{item.User && item.User.name ? item.User.name : ""}</Td>
            <Td>
              {item.Role && item.Role.description ? item.Role.description : ""}
            </Td>
            <Td>
              {item.Department && item.Department.name
                ? item.Department.name
                : ""}
            </Td>
            <Td>
              {item.Manager && item.Manager.name ? item.Manager.name : ""}
            </Td>
            <Td className="actions">
              <button
                type="button"
                className="btn btn-link"
                onClick={() => show(item)}
                data-toggle="modal"
                data-target="#staticBackdrop"
              >
                <MdModeEdit />
              </button>{" "}
              <button
                type="button"
                className="btn btn-link btn-link-danger"
                onClick={() => remove(item)}
                data-toggle="modal"
                data-target="#staticBackdropRemove"
              >
                <MdClose />
              </button>
            </Td>
          </tr>
        );
      })}
    </>
  );
}
