import React from "react";
import moment from "moment";

import ListFile from "./ListFile";

// import { Container } from './styles';

require("moment/locale/pt-br");
function List(props) {
  // const { data, updateFile, removeFile } = props;
  const { data, show, imageSelectedHandler, updateFile, removeFile } = props;
  let app_bgInput;
  return (
    <>
      {data.map((item) => {
        // console.log(item);
        return (
          <div key={item.id} className="card card-100 col-sm-12">
            <div className="card-body">
              <h5 className="card-title">{moment(item.date).format("LL")}</h5>
              {item && item.note ? (
                <p className="card-text">
                  <strong>Observação: </strong>
                  {item.note}
                </p>
              ) : null}
            </div>
            <ul className="list-group list-group-flush">
              {item && item.AppValesExpenseCategory && item.AppValesExpenseCategory.description ? (
                <li className="list-group-item">
                  <p className="card-text">
                    <strong>Categoria: </strong>
                    {item.AppValesExpenseCategory.description}
                  </p>
                </li>
              ) : null}
              <li className="list-group-item">
                <p className="card-text">
                  <strong>Fornecedor: </strong>
                  {item.fornecedor}{" "}
                </p>
              </li>
              <li className="list-group-item">
                <p className="card-text">
                  <strong>CPF/CNPJ: </strong>
                  {item.cpf_cnpj}{" "}
                </p>
              </li>

              <li className="list-group-item">
                R${" "}
                {item && item.amount
                  ? item.amount.toFixed(2).toString().split(".").join(",")
                  : "0,00"}
              </li>
            </ul>
            <div className="card-body">
              <input
                style={{ display: "none" }}
                type="file"
                onChange={imageSelectedHandler.bind(this)}
                ref={(e_app_bgInput) => (app_bgInput = e_app_bgInput)}
              />
              <button
                onClick={() => {
                  app_bgInput.click();
                  show(item);
                }}
                type="button"
                className="btn btn-secondary"
              >
                Adicionar documento
              </button>
            </div>
            {item.AppValesExpenseFiles.length > 0 ? (
              <div>
                {item.AppValesExpenseFiles.map((file) => {
                  return (
                    <ListFile
                      key={file.id}
                      file={file}
                      item={item}
                      updateFile={updateFile}
                      removeFile={removeFile}
                    />
                  );
                })}
              </div>
            ) : null}
          </div>
        );
      })}
    </>
  );
}

export default List;
